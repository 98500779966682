export type FixaWindow = typeof window & {
  RDT_MODE?: boolean;
  isCN?: boolean;
  Cypress?: boolean;
  sprTestbuy?: boolean;
  hideForGermanyUsers?: boolean;
  finalInsightsFromTasksServiceFlag: boolean;
  acquireTokenPopup?: boolean;
  enableDiscoverNow?: boolean;
  enableNewTaskList?: boolean;
  enableNewMediaFilters?: boolean;
};

export const RDT_HIDDEN_INPUT_ID = "RDT_laser_hiddenInpup";

function sniff(substring: string) {
  return !!navigator?.userAgent?.includes(substring);
}

// This function (only) needs to be dynamic, as the value is injected via a script triggered from the wrapper.
export function isRDTDevice() {
  return !!(window as FixaWindow).RDT_MODE;
}

let isCT: boolean | undefined = undefined;
function handleModelResponse(data: { model: string }) {
  isCT = !!data?.model?.includes("CT"); // Overwrite with true or false
}

function isModernAnonymizedRDT() {
  if (isCT === undefined && "userAgentData" in navigator)
    (window.navigator as any).userAgentData
      .getHighEntropyValues(["model"])
      .then(handleModelResponse);

  return sniff("Android 10") && isCT !== false; // Better to allow sometime when we should not.
}

export function isRDTDevice11() {
  return (
    (sniff("Android 11") && (sniff("CT40") || sniff("CT30"))) ||
    isModernAnonymizedRDT()
  );
}

export const isAnyRDT = () => isRDTDevice() || isRDTDevice11();

export const isChineseEnvironment = () =>
  window.location.host.includes(".cn") || !!(window as FixaWindow).isCN; // To test, set window.isCN=1 before opening PQ form.

export const chinaProject = () => {
  if (isChineseEnvironment()) {
    const secondLevelDomain = window.location.hostname.split(".").at(-2);
    return secondLevelDomain === "ingka-dt" ? "dev" : "prod";
  } else if (window.location.host === "dev.coworker.ingka.com") {
    return "dev";
  }
  return;
};

export function hasVirtualKeyboard(
  navigator: Navigator
): navigator is Navigator & {
  virtualKeyboard: { show: () => void; hide: () => void };
} {
  return "virtualKeyboard" in navigator;
}
