import React from "react";
import { LargeSkeletonBox } from "../Molecules/skeletons/LargeSkeletonBox";
import { MediumSkeletonListItem } from "../Molecules/skeletons/MediumSkeletonListItem";
import { FixaSkeleton } from "@coworker/apprestructured/src/shared/wrappers/FixaSkeleton/FixaSkeleton";

export const MediaViewSkeleton = () => {
  return (
    <>
      <LargeSkeletonBox />

      {[...Array(3)].map((_, index) => (
        <MediumSkeletonListItem key={index} />
      ))}
      <FixaSkeleton height={"48px"} width={"90%"} />
    </>
  );
};
