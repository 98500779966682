import React from "react";
import {
  getCachedInternalApi,
  getLatestCacheValue,
} from "./API/useCallInternal";
import { useStoreId } from "../core/auth/useLoggedInUser";

const initial = {
  // First .data is from the fetch functionality
  data: {
    // Second .data is from how the internal dataOr404 wraps the object in .data
    data: { configuration: { locale: "" } },
  },
};

export type MyStoreResponse = {
  id: string;
  name: string;
  country_id: string;
  country_code: string;
  status: "ACTIVE" | "INACTIVE";
  configuration: {
    locale: {
      primary_locale: string;
      supported_locales: string[];
    };
    timezone: string;
    created_at: number;
    updated_at: number;
  };
};
export function useMyStore(): MyStoreResponse {
  const store_id = useStoreId();
  const [response, setResponse] = React.useState(
    getLatestCacheValue("my/store") || initial
  );
  React.useEffect(() => {
    getCachedInternalApi(
      `my/store?storeId=${store_id}`,
      setResponse,
      300_000,
      false,
      "CORE_SERVICE"
    );
  }, [store_id]);
  return response?.data?.data;
}
