import React from "react";
import { FixaSkeleton } from "@coworker/apprestructured/src/shared/wrappers/FixaSkeleton/FixaSkeleton";
import { MediaListItemCardDesktop } from "../styles/styles";

export function MediaListItemSkeletonDesktop() {
  return (
    <MediaListItemCardDesktop active={true}>
      <FixaSkeleton height={"100%"} width={"100%"} />
    </MediaListItemCardDesktop>
  );
}
