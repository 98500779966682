import React from "react";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import LocationPinIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/LocationPinIconPath";
import { useTranslation } from "@coworker/locales";
import { PlacementsText } from "./PlacementsText";
import { MediasAndArticleCountResponse } from "../types/media";

interface MediaPlacementProps {
  mediasArticleCount: MediasAndArticleCountResponse[];
  currentArticleId?: string | undefined;
}

export function MediaPlacement({
  currentArticleId,
  mediasArticleCount,
}: MediaPlacementProps) {
  const { t } = useTranslation();

  const addOnContent = (
    <PlacementsText
      currentArticleId={currentArticleId || ""}
      mediasArticleCount={mediasArticleCount}
    />
  );

  return (
    <FixaListViewItem
      title={t("roomSettingsPlacementString")}
      leadingIcon={LocationPinIconPath}
      addOn={addOnContent}
      emphasised
      inset
    />
  );
}
