import React, { useEffect } from "react";
import { QuickActionTypes } from "./QuickActionsTypes";
import AddOnIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/AddOnIconPath";
import SpeechBubbleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/SpeechBubbleIconPath";
import BoxCancelIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/BoxCancelIconPath";
import ChairIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChairIconPath";
import PencilIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PencilIconPath";
import { Trans } from "@coworker/locales";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { useInputPopup } from "@coworker/app/src/components/InputPopup";
import { useProductQualityTeamId } from "@coworker/app/src/hooks/useProductQualityTeamId";
import { TaskTypesRoutes } from "../../constants/taskTypesRoutes";
import trackerHlper from "../../../../../coworker-app/src/helpers/tracker";
import { QuickActionsTitle } from "./QuickActionsTitle";
import "./quick-actions.css";
import { FixaWindow } from "@coworker/reusable";
import FixaButton from "../../../shared/wrappers/FixaButton/FixaButton";
import { FixaOverflowCarousel } from "@coworker/apprestructured/src/shared/wrappers/FixaCarousel/FixaCarousel";

function buttonTypeTrimmer(buttonType: string) {
  return buttonType.substring(0, buttonType.length - 6);
}

interface QuickActionsProps {
  id: string;
}

export const QuickActions: React.FC<QuickActionsProps> = ({ id }) => {
  const { push, setNavigationState } = useWorkspacesAction();
  const { getInput } = useInputPopup();
  const qualityTeamId = useProductQualityTeamId();

  const hideForGermanyUsers = (window as FixaWindow).hideForGermanyUsers;

  const handleClick = async (type: string, updateLocationState: boolean) => {
    switch (type) {
      case TaskTypesRoutes.CUSTOM:
        push(`/task/new/custom`);
        break;
      case TaskTypesRoutes.ADDON:
      case TaskTypesRoutes.PRODUCT_QUALITY:
      case TaskTypesRoutes.PRODUCT_ISSUE:
        if (updateLocationState) {
          setNavigationState({
            search: true,
            type,
          });
        }
        const product = await getInput("scansearch", { start: new Date() });
        if (product) {
          push(`/task/new/${type}`, { product, qualityTeamId });
        }
        break;
    }
  };

  useEffect(() => {
    const openNewAddon = new URLSearchParams(window.location.search).get(
      "newTask"
    );
    if (openNewAddon) {
      handleClick(TaskTypesRoutes.ADDON, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const quickActions = [
    {
      text: <Trans>{QuickActionTypes.ADDON}</Trans>,
      icon: AddOnIconPath,
      onClick: async () => {
        trackerHlper.trackQuickActionsButton(
          buttonTypeTrimmer(QuickActionTypes.ADDON)
        );
        await handleClick(TaskTypesRoutes.ADDON, true);
      },
      testId: "quickActionAddon",
    },
    {
      text: <Trans>{QuickActionTypes.MFAQ_FOLLOW_UP}</Trans>,
      icon: SpeechBubbleIconPath,
      onClick: () => {
        trackerHlper.trackQuickActionsButton(
          buttonTypeTrimmer(QuickActionTypes.MFAQ_FOLLOW_UP)
        );
        push("/customerquestions");
      },
      testId: "quickActionMFAQ",
    },
    !hideForGermanyUsers && {
      text: <Trans>{QuickActionTypes.PRODUCT_QUALITY}</Trans>,
      icon: BoxCancelIconPath,
      onClick: async () => {
        trackerHlper.trackQuickActionsButton(
          buttonTypeTrimmer(QuickActionTypes.PRODUCT_QUALITY)
        );
        await handleClick(TaskTypesRoutes.PRODUCT_QUALITY, true);
      },
      testId: "quickActionProductQuality",
    },
    {
      text: <Trans>{QuickActionTypes.PRODUCT_ISSUE}</Trans>,
      icon: ChairIconPath,
      onClick: async () => {
        trackerHlper.trackQuickActionsButton(
          buttonTypeTrimmer(QuickActionTypes.PRODUCT_ISSUE)
        );
        await handleClick(TaskTypesRoutes.PRODUCT_ISSUE, true);
      },
      testId: "quickActionProductIssue",
    },
    {
      text: <Trans>{QuickActionTypes.CUSTOM}</Trans>,
      icon: PencilIconPath,
      onClick: async () => {
        trackerHlper.trackQuickActionsButton(
          buttonTypeTrimmer(QuickActionTypes.CUSTOM)
        );
        await handleClick(TaskTypesRoutes.CUSTOM, false);
      },
      testId: "quickActionCustom",
    },
  ].filter(Boolean);

  const quickActionItems: any = quickActions.map((quickaction: any) => {
    return (
      <FixaButton
        type="primary"
        key={quickaction.text}
        text={quickaction.text}
        onClick={quickaction.onClick}
        size="small"
        ssrIcon={quickaction.icon}
        className="quick-actions-button"
        data-testid={quickaction.testId}
      />
    );
  });

  return (
    <div>
      <QuickActionsTitle />
      <FixaOverflowCarousel id={id} controlBtnType="primary">
        {quickActionItems.map((item: any, i: any) => {
          return React.cloneElement(item, { key: i });
        })}
      </FixaOverflowCarousel>
    </div>
  );
};
