import { createNote } from "../../../services/note.service";
import { Reasons } from "../constants";
import { mediaCreateTaskFactory } from "../ObjectFactories/TaskFactory";
import { Article } from "../types/article";

export const createAutoGeneratedTaskAsCompleted = async (
  userId: string,
  reason: Reasons,
  mediaId: string,
  mediaName: string,
  storeId: string,
  article: Article,
  new_product_article_id: string,
  noteText: string,
  createTask: (task: any) => Promise<string> // NOSONAR
) => {
  const dummyTask = mediaCreateTaskFactory(
    userId,
    mediaId,
    article.id,
    article.itemName,
    article.supplierNumber,
    article.dateStamp,
    article.nbrArticles,
    false,
    false,
    false,
    false,
    mediaName,
    reason,
    article.productArticleId,
    storeId,
    "",
    article.itemType,
    article.itemColor,
    " ",
    article.imageSmall,
    false
  );

  if (reason === Reasons.REPLACE_PRODUCT) {
    dummyTask.new_product_article_id = new_product_article_id;
  }

  dummyTask.state = "COMPLETED";
  dummyTask.assigned_user_id = userId;
  dummyTask.task_finisher_id = userId;

  const taskId = await createTask(dummyTask);

  await createNote(
    taskId,
    {
      text: noteText,
      creator_id: userId,
    },
    [],
    storeId
  );
};
