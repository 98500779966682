import React from "react";
import styled from "styled-components";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import FixaStatus from "@coworker/apprestructured/src/shared/wrappers/FixaStatus/FixaStatus";
import { useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useImagesBySignedUrl } from "../hooks/useImagesBySignedUrl";
import {
  getEDSAlertCount,
  getOngoingTaskCount,
  getMediaMetaInfo,
  getSalesStopAlertCount,
} from "./MediaListItem.helper";
import { HeadLine } from "../Atoms/HeadLine";
import { TaskLinks } from "../types/taskLink";
import { AlertResponse, Media } from "../types/media";
import { TabIds, TabQueryKey } from "../Organisms/MediaTabs.helper";
import { useImagesByMediaId } from "../hooks/useImagesByMediaId";

const SolidRedStatus = styled(FixaStatus)`
  .status__dot {
    background-color: currentColor;
  }
`;

interface MediaListItemProps {
  media: Media;
  taskLinks: TaskLinks;
  alerts: AlertResponse[];
}

export function MediaListItemMobile({
  media,
  taskLinks,
  alerts,
}: MediaListItemProps) {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();

  const { data: images } = useImagesByMediaId(media.id);
  const signedUrlImages = useImagesBySignedUrl(images ?? []);

  const ongoingTaskCount = getOngoingTaskCount(taskLinks, media.id);

  const edsAlertCount = getEDSAlertCount(alerts, media.id);

  const salesStopAlertCount = getSalesStopAlertCount(alerts, media.id);

  const mainImageUrl = signedUrlImages[0]?.url || "";

  const extraProps: { addOn?: string | React.ReactElement } = {};
  if (media.active === "INACTIVE") {
    extraProps.addOn = (
      <SolidRedStatus label={t("inactiveString")} statusColor="red" />
    );
  }

  return (
    <FixaListViewItem
      title={
        <HeadLine
          main={media.name}
          sub1={media.mainArticleName1}
          sub2={media.mainArticleName2}
        />
      }
      image={mainImageUrl}
      description={getMediaMetaInfo(
        ongoingTaskCount,
        edsAlertCount,
        salesStopAlertCount,
        t
      )}
      control={"navigational"}
      controlIcon={"chevronRightSmall"}
      onClick={() => {
        push(
          `/mediamaintenance/tabs/${media.id}?${TabQueryKey}=${TabIds.LIST}`
        );
      }}
      emphasised
      inset
      {...extraProps}
    />
  );
}
