import React, { useState } from "react";
import { SelectFilter, SelectOption, StringFilterValue } from "../types";
import { FixaListView } from "../../wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "../../wrappers/FixaListView/FixaListViewItem";
import FixaSearch from "../../wrappers/FixaSearch/FixaSearch";
import { useTranslation } from "@coworker/locales";
import SelectListViewCss from "./SelectListView.module.css";

const localeIncludes = (str: string, searchStr: string) => {
  const stringLength = str.length;
  const searchStringLength = searchStr.length;
  const lengthDiff = stringLength - searchStringLength;

  if (searchStr.length === 0) {
    return true;
  }
  for (let idx = 0; idx <= lengthDiff; idx++) {
    if (
      str
        .substring(idx, idx + searchStringLength)
        .localeCompare(searchStr, undefined, { sensitivity: "accent" }) === 0
    ) {
      return true;
    }
  }
  return false;
};

interface SelectListViewProps {
  prefix: string;
  filter: SelectFilter;
  selected: StringFilterValue;
  onChange: (option: SelectOption) => void;
}

export const SelectListView = ({
  prefix,
  filter,
  onChange,
  selected,
}: SelectListViewProps) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const { title, options, showSearch } = filter;
  return (
    <div>
      {showSearch && (
        <FixaSearch
          className={SelectListViewCss["search-input"] ?? ""}
          id={`listview-search-${prefix}-${title}`}
          value={searchInput}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchInput(e.target.value);
          }}
          onClear={() => setSearchInput("")}
          placeholder={t("searchFilterOptionsString", { title })}
          data-testid={`listview-search-${prefix}-${title}`}
        />
      )}
      <FixaListView
        id={`listview-${prefix}-${title}`}
        size="small"
        showDivider={false}
      >
        {options
          .filter(
            (option) =>
              selected.value === option.value ||
              localeIncludes(option.title, searchInput)
          )
          .map((option) => {
            return (
              <FixaListViewItem
                key={option.value}
                inset
                onChange={() => {
                  onChange(option);
                }}
                control="radiobutton"
                controlProps={{
                  value: selected.value === option.value ? "ON" : "OFF",
                  checked: selected.value === option.value,
                }}
                showDivider={false}
                title={option.title}
                data-testid={option.value}
              />
            );
          })}
      </FixaListView>
    </div>
  );
};
