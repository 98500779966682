import React from "react";
import { SelectFilter, SelectOption, StringFilterValue } from "../types";
import { SelectListView } from "./SelectListView";
import { FixaAccordionItem } from "@coworker/apprestructured/src/shared/wrappers/FixaAccordion/FixaAccordion";

interface AccordionSelectProps {
  filter: SelectFilter;
  selected: StringFilterValue;
  onChange: (option: SelectOption) => void;
}

export const AccordionSelect = ({
  filter,
  selected,
  onChange,
}: AccordionSelectProps) => {
  const { title } = filter;
  return (
    <FixaAccordionItem id={`filter-accordion-${title}`} title={title}>
      <SelectListView
        prefix={`accordion`}
        filter={filter}
        selected={selected}
        onChange={onChange}
      />
    </FixaAccordionItem>
  );
};
