import React from "react";
import styles from "./task-card.module.css";
import { useTranslation } from "@coworker/locales";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { getDateActionFromState } from "../../helpers/getDateActionFromState";

interface TimestampProps {
  dateTime: string;
  type: string;
}

dayjs.extend(utc);
dayjs.extend(timezone);

export const Timestamp = ({ dateTime, type }: TimestampProps) => {
  const { t } = useTranslation();

  const dateAction = getDateActionFromState(type);

  const localDate = new Date(dateTime);
  const currentTime = dayjs();

  const timeDifferenceMinutes = currentTime.diff(localDate, "minutes");
  const timeDifferenceHours = currentTime.diff(localDate, "hours");
  const timeDifferenceDays = currentTime.diff(localDate, "days");

  const getTimeString = (
    difference: number,
    singularKey: string,
    pluralKey: string
  ) =>
    `${difference} ${difference === 1 ? t(singularKey) : t(pluralKey)} ${t(
      "agoString"
    )}`;

  const date = () => {
    if (!dateTime) return "";
    if (timeDifferenceMinutes === 0) return t("justNowString");
    if (timeDifferenceMinutes < 60)
      return getTimeString(
        timeDifferenceMinutes,
        "minuteString",
        "minutesString"
      );
    if (timeDifferenceHours < 24)
      return getTimeString(timeDifferenceHours, "hourString", "hoursString");
    if (timeDifferenceDays <= 5)
      return getTimeString(timeDifferenceDays, "dayString", "daysString");

    return localDate.toISOString().split("T")[0];
  };

  return (
    <div className={styles["timestamp-wrapper"]}>
      <div className={styles["timestamp"]}>
        {t(dateAction)} {date()}
      </div>
    </div>
  );
};
