import { Articles } from "../types/article";
import { Media, Medias } from "../types/media";

export const EmptyMedia: Media = {
  createdAt: "",
  updatedAt: "",
  id: "",
  storeId: "",
  name: "",
  styleGroup: "",
  hfLtp: "",
  pricing: "",
  active: "ACTIVE",
  nbrArticles: "",
  mainArticleId1: "",
  mainArticleId2: "",
  mainArticleName1: "",
  mainArticleName2: "",
  livingSituation: "",
  laha: "",
  livingConditions: "",
  hfb: "",
  backupArticleId1: "",
  backupArticleId2: "",
  backupArticleName1: "",
  backupArticleName2: "",
  mediaStory: "",
};

export const checkMediaName = (media: Media, medias: Medias) => {
  const found = medias.find(
    (mediaFromList) =>
      media.name === mediaFromList.name && media.id !== mediaFromList.id
  );

  if (found) return true;

  return false;
};

export const checkEmptyMediaName = (media: Media) => {
  const isMediaNameEmpty = !media.name;
  if (isMediaNameEmpty) return true;

  return false;
};

export const validationMessage = (
  media: Media,
  medias: Medias,
  t: (key: string, options?: any) => string
) => {
  if (checkMediaName(media, medias)) {
    return t(`roomWithNameAlreadyExistsString`, {
      roomName: media.name,
    });
  } else if (checkEmptyMediaName(media)) {
    return t("roomNameCannotBeEmptyString");
  }
  return "";
};

export const getArticleName = (articles: Articles, articleId: string) => {
  const article = articles.find((article) => article.id === articleId);
  return article ? article.itemName : "";
};

export const getFullArticleName = (articles: Articles, articleId: string) => {
  const article = articles.find((article) => article.id === articleId);
  return article
    ? `${article.productArticleId}: ${article.itemName}, ${article.itemType}`
    : "";
};
