import React from "react";
import { ArticleIdNbrArticles, Articles } from "../../types/article";
import { Media } from "../../types/media";
import { ArticleListItemMobile, ListWrapper } from "../../styles/styles";
import { Groups } from "../../types/groups";
import {
  AggregatedArticle,
  useAggregatedArticles,
} from "../../hooks/useAggregatedArticles";
import {
  hasOngoingTask,
  isBackupForProduct,
  isMainArticle,
  selectArticleIdNbrArticles,
} from "../../Molecules/MediaArticles.helper";
import { useTasksByMedia } from "../../hooks/useTasksByMedia";
import { useCountryId } from "../../../../core/auth/useLoggedInUser";
import { joinProductArticleIds, sortAndFilterArticles } from "../../tools";
import { useEDSData } from "../../hooks/useEDSData";
import { useSalesStopData } from "../../hooks/useSalesStopData";
import { AggregatedArticleListItem } from "../../Molecules/AggregatedArticleListItem";
import { SearchBar } from "../../Molecules/SearchBar";
import { useTranslation } from "@coworker/locales";
import { SelectArticles } from "../../Molecules/SelectArticles";
import { ArticleFilters } from "../../Molecules/ArticleFilters";
import { ArticleFilterTypes, ArticleSortingTypes } from "../../constants";
import { useUserPreference } from "../../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/enums/profilePreferences";
import styled from "styled-components";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { FixaWindow } from "@coworker/reusable";
import { FilterPanel } from "@coworker/apprestructured/src/shared/filters/components";
import { useNewFilters } from "./useNewFilters";

const ArticleFiltersContainer = styled.div`
  padding: 16px 0 16px 16px;
  border-bottom: 1px solid var(--grey200);
`;

const ArticleFiltersContainerNew = styled.div`
  padding: 0 0 16px 16px;
  border-bottom: 1px solid var(--grey200);
`;

const SearchBarContainer = styled.div`
  padding: 16px;
`;

const SelectButtonContainer = styled.div`
  padding: 16px;
  text-align: center;
  position: flex;
  top: 56px;
  background-color: white;
  z-index: 1;
`;

interface AggregatedArticleListingProps {
  articles: Articles;
  media: Media;
  groups?: Groups | undefined;
  isSelectArticlesMode: boolean;
  getSelectedArticleIds: (
    fetchSelectedArticleIdsFunction: () => ArticleIdNbrArticles[]
  ) => void;
  setSelectArticleMode: () => void;
}
export const AggregatedArticleListing = ({
  articles,
  media,
  groups,
  isSelectArticlesMode,
  getSelectedArticleIds,
  setSelectArticleMode,
}: AggregatedArticleListingProps) => {
  const enableNewMediaFilters = (window as FixaWindow).enableNewMediaFilters;
  const { t } = useTranslation();

  const [filter] = useUserPreference(
    profilePreferences.RS_ARTICLE_FILTER,
    ArticleFilterTypes.ALL
  );
  const [sort] = useUserPreference(
    profilePreferences.RS_ARTICLE_SORT_FILTER,
    ArticleSortingTypes.PRIORITY
  );
  const [hfb] = useUserPreference(
    profilePreferences.RS_ARTICLE_HFB_FILTER,
    "all"
  );

  const { taskLinks } = useTasksByMedia(media.id);
  const countryId = useCountryId();
  const articleNumbers = joinProductArticleIds(articles);
  const { data: edsData } = useEDSData(countryId, articleNumbers);
  const { data: salesStopData } = useSalesStopData(
    media.storeId,
    articleNumbers
  );
  const uniqueArticles = useAggregatedArticles(articles);

  const [selectedArticleIdsNbrArticles, setSelectedArticleIdsNbrArticles] =
    React.useState<ArticleIdNbrArticles[]>([]);
  const [articleSearchResult, setArticleSearchResult] =
    React.useState(uniqueArticles);
  const [sortedAndFilteredArticles, setSortedAndFilteredArticles] =
    React.useState(uniqueArticles);
  const [isSearchedResult, setIsSearchedResult] = React.useState(false);

  React.useEffect(() => {
    if (!isSelectArticlesMode) setSelectedArticleIdsNbrArticles([]);
  }, [isSelectArticlesMode]);

  React.useEffect(() => {
    if (enableNewMediaFilters) {
      return;
    }
    const sortArticlesBase = isSearchedResult
      ? articleSearchResult
      : uniqueArticles;
    const sortedArticles = sortAndFilterArticles(
      sortArticlesBase,
      filter ?? ArticleFilterTypes.ALL,
      sort ?? ArticleSortingTypes.PRIORITY,
      hfb ?? "all",
      taskLinks,
      edsData ?? [],
      salesStopData ?? [],
      media
    );

    // Prevent infinite loop, if the articles are the same, do not update the state
    if (
      JSON.stringify(sortedArticles) ===
      JSON.stringify(sortedAndFilteredArticles)
    ) {
      return;
    }

    setSortedAndFilteredArticles(sortedArticles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edsData, salesStopData, taskLinks, filter, sort, hfb]);

  getSelectedArticleIds(() => {
    return selectedArticleIdsNbrArticles.filter((a) =>
      sortedAndFilteredArticles.some((b) => a.articleId === b.id)
    );
  });

  const handleSortedArticles = (
    selectedFilter: string,
    selectedSort: string,
    selectedHFB: string
  ) => {
    const initialArticles = [...uniqueArticles];
    const sortedArticles = sortAndFilterArticles(
      initialArticles,
      selectedFilter as ArticleFilterTypes,
      selectedSort as ArticleSortingTypes,
      selectedHFB,
      taskLinks,
      edsData ?? [],
      salesStopData ?? [],
      media
    );

    setSortedAndFilteredArticles(sortedArticles);
  };

  // New filtering. Should do nothing if not enabled.
  const { filters, filterValues, setFilterValues, useFilteredArticleData } =
    useNewFilters();
  const newSortedAndFilteredArticles = useFilteredArticleData(
    isSearchedResult ? articleSearchResult : uniqueArticles
  );
  const articlesToShow = enableNewMediaFilters
    ? newSortedAndFilteredArticles
    : sortedAndFilteredArticles;

  return (
    <>
      {enableNewMediaFilters ? (
        <ArticleFiltersContainerNew>
          <FilterPanel
            filters={filters}
            filterValues={filterValues}
            onChange={setFilterValues}
          />
        </ArticleFiltersContainerNew>
      ) : (
        <ArticleFiltersContainer>
          <ArticleFilters onArticlesChange={handleSortedArticles} />
        </ArticleFiltersContainer>
      )}

      <SearchBarContainer>
        <SearchBar
          articles={uniqueArticles}
          onSearchResults={(result: AggregatedArticle[]) => {
            setIsSearchedResult(result.length !== uniqueArticles.length);
            setArticleSearchResult(result);
          }}
        />
      </SearchBarContainer>
      {uniqueArticles.length > 0 && (
        <SelectButtonContainer>
          <FixaButton
            type={"secondary"}
            text={
              isSelectArticlesMode
                ? t("cancelString")
                : t("selectArticlesString")
            }
            onClick={setSelectArticleMode}
            fluid
          />
        </SelectButtonContainer>
      )}
      <SelectArticles
        selectedArticles={selectedArticleIdsNbrArticles}
        uniqueArticles={uniqueArticles}
        filteredArticles={articlesToShow}
        isSelected={isSelectArticlesMode}
        onSelectAll={(selectAllChecked: boolean) => {
          if (selectAllChecked) {
            setSelectedArticleIdsNbrArticles(
              uniqueArticles.map((article) => ({
                articleId: article.id,
                nbrArticles: article.nbrArticles,
              }))
            );
          } else {
            setSelectedArticleIdsNbrArticles([]);
          }
        }}
      />
      <ListWrapper>
        {articlesToShow.map((uniqueArticle) => (
          <ArticleListItemMobile key={uniqueArticle.id}>
            <AggregatedArticleListItem
              article={uniqueArticle}
              isMainProduct={() =>
                isMainArticle(
                  uniqueArticle.id,
                  media.mainArticleId1,
                  media.mainArticleId2
                )
              }
              isBackupForProduct={() =>
                isBackupForProduct(
                  uniqueArticle.id,
                  media.backupArticleId1 ?? "",
                  media.backupArticleId2 ?? ""
                )
              }
              hasOngoingTask={() => hasOngoingTask(uniqueArticle.id, taskLinks)}
              edsData={
                edsData?.find(
                  (eds: { productArticleId: string }) =>
                    eds.productArticleId === uniqueArticle.productArticleId
                ) ?? null
              }
              salesStopData={
                salesStopData?.find(
                  (salesStop) =>
                    salesStop.productArticleId ===
                    uniqueArticle.productArticleId
                ) ?? null
              }
              selectArticleIdsMode={isSelectArticlesMode}
              onSelectArticleIdNbrArticles={(articleIdNbrArticles) =>
                selectArticleIdNbrArticles(
                  articleIdNbrArticles,
                  selectedArticleIdsNbrArticles,
                  setSelectedArticleIdsNbrArticles
                )
              }
              isSelected={
                !!selectedArticleIdsNbrArticles.find(
                  (item) => item.articleId === uniqueArticle.id
                )
              }
              showActionRow={true}
              groups={groups}
            />
          </ArticleListItemMobile>
        ))}
      </ListWrapper>
    </>
  );
};
