import React from "react";
import { useTranslation } from "@coworker/locales";
import { ArticleListItem } from "../../RoomSettings/Molecules/ArticleListItem";
import { Text } from "../../RoomSettings/Atoms/Text";
import { Reasons } from "../../RoomSettings/constants";
import { mapArticleInfoToArticle } from "../../RoomSettings/types/mappers";
import { Article } from "../../RoomSettings/types/article";
import {
  ArticleListItemMobile,
  SimpleContainer,
} from "../../RoomSettings/styles/styles";
import { Item } from "@coworker/apprestructured/src/shared/types/item";
import { useItemsInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemsInfo";

interface PreviousProductProps {
  value: {
    new_product_article_id: string;
    state: string;
    reason: string;
  };
}

export default function PreviousProduct({ value }: PreviousProductProps) {
  const { t } = useTranslation();

  const { data: itemsInfo } = useItemsInfo([value.new_product_article_id]);

  if (!itemsInfo || itemsInfo.length === 0) return <></>;

  const article: Article = mapArticleInfoToArticle(itemsInfo[0] as Item);

  return (
    <>
      {value.reason === Reasons.REPLACE_PRODUCT && (
        <>
          <SimpleContainer $direction="row">
            <Text text={t("newProductString")} bold />
          </SimpleContainer>
          <ArticleListItemMobile>
            <ArticleListItem article={article} hideChevron={true} />
          </ArticleListItemMobile>
        </>
      )}
    </>
  );
}
