import { FixaWindow } from "@coworker/reusable";
import { AggregatedArticle } from "../../hooks/useAggregatedArticles";
import { useMemoryFilteredData } from "@coworker/apprestructured/src/shared/filters/utils/apply/useMemoryFilteredData";
import {
  useHfbSelectArticleFilter,
  useAlertSelectArticleFilter,
  useTasksSelectArticleFilter,
  useSortSelectArticleFilter,
} from "../../filters";
import preferences from "@coworker/enums/profilePreferences";
import {} from "../../filters/useTasksSelectArticleFilter";
import { useUserPreferenceStoreFilterValues } from "@coworker/apprestructured/src/shared/filters/utils";

// This code will probably go into AggregatedArticleListing when old filter solution is removed
export function useNewFilters() {
  const enableNewMediaFilters = (window as FixaWindow).enableNewMediaFilters;
  const { filterValues, setFilterValues } = useUserPreferenceStoreFilterValues(
    preferences.MEDIA_FILTERS_ARTICLES
  );
  const dataFilters = [
    useHfbSelectArticleFilter(),
    useAlertSelectArticleFilter(),
    useTasksSelectArticleFilter(),
    useSortSelectArticleFilter(filterValues),
  ];
  const filters = dataFilters.map(({ filter }) => filter);

  function useFilteredArticleData(articles: AggregatedArticle[]) {
    return useMemoryFilteredData<AggregatedArticle>(
      articles,
      dataFilters,
      filterValues
    );
  }

  if (enableNewMediaFilters) {
    return {
      filters,
      filterValues,
      setFilterValues,
      useFilteredArticleData,
    };
  } else {
    return {
      filters: [],
      filterValues: [],
      setFilterValues: () => {},
      useFilteredArticleData: (article: AggregatedArticle[]) => article,
    };
  }
}
