import React, { useEffect, useState } from "react";
import {
  Filter,
  InputType,
  FilterValue,
  StringFilterValue,
  SelectFilter,
} from "../types";
import { AccordionSelect } from "./AccordionSelect";
import { useTranslation } from "@coworker/locales";
import { FixaModal } from "../../wrappers/FixaModal/FixaModal";
import { FixaSheets } from "../../wrappers/FixaModal/FixaSheets";
import { FixaModalFooter } from "../../wrappers/FixaModal/FixaModalFooter";
import FixaButton from "../../wrappers/FixaButton/FixaButton";
import { FixaModalHeader } from "../../wrappers/FixaModal/FixaModalHeader";
import { FixaModalBody } from "../../wrappers/FixaModal/FixaModalBody";
import { FixaAccordion } from "../../wrappers/FixaAccordion/FixaAccordion";
import { getSelectedFilterValue } from "../utils/filter";

interface FilterModalProps {
  visible: boolean;
  filters: Filter[];
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  onChange: (filterValues: FilterValue[]) => void;
  onClose: () => void;
}

export const FilterModal = ({
  visible,
  filters,
  filterValues,
  defaultValues,
  onChange,
  onClose,
}: FilterModalProps) => {
  const { t } = useTranslation();
  const [savedValues, setSavedValues] = useState<FilterValue[]>(filterValues);

  useEffect(() => {
    setSavedValues(filterValues);
  }, [filterValues]);

  const inputSelectOption = (filter: SelectFilter) => {
    // Not possible to use selectedValue stored in filter. Maybe this should be
    // separated again.
    const selected = getSelectedFilterValue(
      filter.id,
      savedValues,
      defaultValues
    )! as StringFilterValue;
    return (
      <AccordionSelect
        key={filter.id}
        filter={filter}
        selected={selected}
        onChange={(option) => {
          setSavedValues([
            ...savedValues.filter(
              (filterValue) => filter.id !== filterValue.filterId
            ),
            { filterId: filter.id, value: option.value },
          ]);
        }}
      />
    );
  };

  return (
    <FixaModal
      visible={visible}
      handleCloseBtn={() => {
        onClose();
      }}
      className="filter-modal"
    >
      <FixaSheets
        alignment={"right"}
        preserveAlignment={false}
        size="small"
        fullSize={true}
        header={<FixaModalHeader title="Filters" floating={false} />}
        footer={
          <FixaModalFooter>
            <FixaButton
              text={t("resetString")}
              type="secondary"
              onClick={() => {
                onChange([]);
                onClose();
              }}
            />
            <FixaButton
              text={t("applyString")}
              type="primary"
              disabled={savedValues.length === 0}
              onClick={() => {
                onChange(savedValues);
                setSavedValues([]);
                onClose();
              }}
            />
          </FixaModalFooter>
        }
      >
        <FixaModalBody>
          <FixaAccordion collapsible={false} padded={false}>
            {filters.map((filter) => {
              switch (filter.inputType) {
                case InputType.Select: {
                  return inputSelectOption(filter);
                }
                default:
                  return null;
              }
            })}
          </FixaAccordion>
        </FixaModalBody>
      </FixaSheets>
    </FixaModal>
  );
};
