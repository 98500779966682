import { Filter, FilterValue } from "../../types";

export function getSelectedFilterValue(
  filterId: string,
  filterValues: FilterValue[],
  defaultValues: FilterValue[]
) {
  return (
    filterValues.find((filterValue) => filterId === filterValue.filterId) ??
    defaultValues.find((filterValue) => filterId === filterValue.filterId) ?? {
      filterId: filterId.indexOf,
      value: null,
    }
  );
}

export function getDefaultFilterValue(filters: Filter[]) {
  return filters.map(({ defaultValue }) => defaultValue);
}
