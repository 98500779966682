import React from "react";
//import profilePreferences from "@coworker/enums/profilePreferences";
//import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import Toast from "./Toast";
import { Trans } from "@coworker/locales";
//import { useInputPopup } from "../InputPopup";
import { isUnsupportedBrowser } from "../../helpers/browserChecks";
//import { isRDTDevice } from "@coworker/reusable";

const BrowserSupportSuggestion = () => {
  const [isToastVisible, setIsToastVisible] = React.useState(true);

  const handleToastClick = () => {
    setIsToastVisible(false);
  };
  // First nudge user to "Please use Chrome"
  // Later, we'll use this to ask to install the PWA, but ONLY after sending users to new url https://Fixa.ingka.com/
  //  -> "Please install this app as an icon on your home screen to get the supported user experience."

 // const { getInput } = useInputPopup();
  // const [, setLastClose] = useUserPreference(
  //   profilePreferences.UNSUPPORTED_BROWSER_NOTICE_CLOSED,
  //   null
  // );
  // const [lastApprove, setLastApprove] = useUserPreference(
  //   profilePreferences.UNSUPPORTED_BROWSER_NOTICE_APPROVED,
  //   null
  // );

  // const isRDT = isRDTDevice();

  // const showBrowserSettingsHelpPage = () => {
  //   window.open(
  //     "https://support.google.com/chrome/answer/95417?co=GENIE.Platform%3DAndroid",
  //     "_blank"
  //   ) || // fallback for browsers (like Safari) that block window.open from happening
  //     (window.location =
  //       "https://support.google.com/chrome/answer/95417?co=GENIE.Platform%3DAndroid");
  // };

  // const askBrowser = async () => {
  //   const confirmed = await getInput("confirmation", {
  //     question: <Trans>unsupportedBrowserTitleString</Trans>,
  //     description: <Trans>unsupportedBrowserMessageString</Trans>,
  //     positiveText: <Trans>unsupportedBrowserYesButtonString</Trans>,
  //   });
  //   if (confirmed) {
  //     setLastApprove(new Date());
  //     showBrowserSettingsHelpPage();
  //   } else {
  //     setLastClose(new Date());
  //   }
  // };

  return isUnsupportedBrowser && isToastVisible ? (
    <Toast onClick={handleToastClick}>
      <Trans>coworkerHubUnSupportedString</Trans>
    </Toast>
  ) : null;
};

export default BrowserSupportSuggestion;
