import React from "react";
import styled from "styled-components";
import BasicInfo from "./basicinfo";
import { FixaOverflowCarousel } from "@coworker/apprestructured/src/shared/wrappers/FixaCarousel/FixaCarousel";
import { usePIPHome } from "@coworker/apprestructured/src/shared/hooks/item/usePIPHome";
import FixaImage from "@coworker/apprestructured/src/shared/wrappers/FixaImage/FixaImage";
import { useOwnImages } from "../../../core/hooks/useImages";
import { useRemoteImages } from "../../../hooks/useRemoteImages";

export const CarouselImage = styled(FixaImage)`
  height: 250px;
  object-fit: contain;
`;

const ImageWrapper = styled.div`
  padding: 16px 16px 0px 16px;
`;

export default function TaskHeaderWithImages({ task }) {
  const { data: pipHomeData, isLoading } = usePIPHome(task.product_article_id);

  const ownImages = useOwnImages(task.id);
  const showOwnImages = !!ownImages?.length;
  const scrollables = useRemoteImages(ownImages);

  const { images, bigImage } = pipHomeData?.productImages || {};
  const chosenImages = React.useMemo(
    () => (images?.length ? images : [bigImage]),
    [images, bigImage]
  );

  return (
    <>
      {showOwnImages && (
        <ImageWrapper>
          <FixaOverflowCarousel id="own-images">
            {scrollables.map((image, index) => (
              <CarouselImage key={`image${index}`} src={image ?? ""} />
            ))}
          </FixaOverflowCarousel>
        </ImageWrapper>
      )}
      {!showOwnImages && !isLoading && chosenImages.length > 0 && (
        <ImageWrapper>
          <FixaOverflowCarousel id="product-images">
            {chosenImages.map((image, index) => (
              <CarouselImage key={`image${index}`} src={image ?? ""} />
            ))}
          </FixaOverflowCarousel>
        </ImageWrapper>
      )}

      <BasicInfo task={task} />
    </>
  );
}
