import { getTokenAsync } from "../../../hooks/API/getAuthToken";
import { makeHeaders } from "../tools";
import { Articles } from "../types/article";
import {
  AlertResponse,
  PartialMedia,
  MediaResponse,
  MediasAndArticleCountResponse,
  MediasResponse,
  MediaId,
} from "../types/media";
import { MEDIAS_SERVICE_URL } from "./service.helper";
import {
  transferMainAndAlternativeProduct,
  transferMainAndBackupArticle,
} from "./temporary.media.helper";

const BASE_URL = MEDIAS_SERVICE_URL + "/medias";

export const getMediasByStoreId = async (storeId: string) => {
  try {
    const token = await getTokenAsync();
    if (!token || typeof token !== "string") {
      return [];
    }
    const response = await fetch(
      `${BASE_URL}/stores/${encodeURIComponent(storeId)}`,
      {
        method: "GET",
        headers: makeHeaders(token),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = (await response.json()) as MediasResponse;
    if (!data.medias) return [];
    data.medias.forEach((media) => transferMainAndAlternativeProduct(media));
    return data.medias;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return [];
};

export const getArticlesByMediaId = async (mediaId?: string | undefined) => {
  try {
    const token = await getTokenAsync();
    if (!mediaId || !token || typeof token !== "string") {
      return [];
    }
    const response = await fetch(`${BASE_URL}/${mediaId}/articles`, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (response.status === 404) return [];

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    return (await response.json()).articles as Articles;
  } catch (error) {
    console.error("Fetch error:", error);
    return [];
  }
};

export const getArticlesByProductArticleId = async (
  mediaId: string,
  productArticleId: string
) => {
  try {
    const token = await getTokenAsync();
    if (!token || typeof token !== "string") {
      return [];
    }
    const response = await fetch(
      `${BASE_URL}/${mediaId}/articles/product_article_id/${productArticleId}`,
      {
        method: "GET",
        headers: makeHeaders(token),
      }
    );

    if (response.status === 404) return [];

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const articles = (await response.json()) as Articles;
    return articles;
  } catch (error) {
    console.error("Fetch error:", error);
    return [];
  }
};

export const getMediaById = async (mediaId: string) => {
  try {
    const token = await getTokenAsync();
    if (!mediaId || !token || typeof token !== "string") {
      return null;
    }
    const response = await fetch(`${BASE_URL}/${mediaId}`, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const media = (await response.json()) as MediaResponse;
    transferMainAndAlternativeProduct(media.media);
    return media.media;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return null;
};

export const getMediasAndArticleCount = async (
  productArticleId: string,
  storeId: string
) => {
  try {
    const token = await getTokenAsync();
    if (!token || typeof token !== "string") {
      return null;
    }
    const response = await fetch(
      `${BASE_URL}/${encodeURIComponent(storeId)}/articles/${productArticleId}`,
      {
        method: "GET",
        headers: makeHeaders(token),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    const data = (await response.json())
      .mediasAndArticleCount as MediasAndArticleCountResponse[];
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return null;
};

export const createMedia = async (mediaData: PartialMedia) => {
  const token = await getTokenAsync();
  if (!token || typeof token !== "string") {
    return null;
  }

  // Make sure id is not set. Should be generated in service.
  delete mediaData.id;

  transferMainAndBackupArticle(mediaData);

  const response = await fetch(`${BASE_URL}`, {
    method: "POST",
    headers: makeHeaders(token),
    body: JSON.stringify(mediaData),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const data = (await response.json()) as MediaId;
  return data;
};

export const updateMedia = async (mediaId: string, mediaData: PartialMedia) => {
  try {
    const token = await getTokenAsync();
    if (!token || typeof token !== "string") {
      return null;
    }

    transferMainAndBackupArticle(mediaData);

    const response = await fetch(`${BASE_URL}/${mediaId}`, {
      method: "PUT",
      headers: makeHeaders(token),
      body: JSON.stringify(mediaData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = (await response.json()) as string;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }

  return null;
};

export const deleteMedia = async (mediaId: string) => {
  try {
    const token = await getTokenAsync();
    if (!token || typeof token !== "string") {
      return null;
    }
    const response = await fetch(`${BASE_URL}/${mediaId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = (await response.json()) as string;
    return data;
  } catch (error) {
    console.error("Delete error:", error);
  }

  return null;
};

export const getAlerts = async (storeId: string) => {
  try {
    const token = await getTokenAsync();
    if (!token || typeof token !== "string") {
      return;
    }
    const response = await fetch(
      `${BASE_URL}/${encodeURIComponent(storeId)}/articles/alerts`,
      {
        method: "GET",
        headers: makeHeaders(token),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    const data = (await response.json()).alerts as AlertResponse[];
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return [] as AlertResponse[];
};
