import React from "react";
import ReactDOM from "react-dom";
import { useWorkspacesState } from "./useWorkspaces";
import versionJson from "@coworker/app/src/core/version.json";
import { useDeployedVersion } from "@coworker/apprestructured/src/shared/hooks/useDeployedVersion";

const LS_SHOULD_RELOAD_KEY = "_fixa_new_version_reload";
// These are the different routes that are alowed to be reloaded.
const RELOADABLE_ROUTES_RE = [
  /^\/tasks/,
  /^\/testbuy/,
  /^\/task/,
  /^\/shoppingtools/,
  /^\/roomsettings/,
  /^\/$/,
  /^\/release$/,
  /^\/home/,
  /^\/activity/,
];

async function prefetchNewVersionSources() {
  const promises = [];

  const res = await fetch(window.location.origin).catch(() => null);
  if (!res) return console.log("Failed to fetch(window.location.origin)");
  const html = await res.text();

  const matches = getScriptTags(html);

  const preloadLinks = (
    <React.Fragment>
      {matches.map(([_tag, source, _maybeJS]) => {
        let resolver;
        const onLoadPromise = new Promise((resolve) => (resolver = resolve));
        promises.push(onLoadPromise);
        return (
          <link
            key={source}
            rel="preload"
            href={source}
            type="text/javascript"
            as="script"
            onLoad={() => resolver()}
          />
        );
      })}
    </React.Fragment>
  );

  ReactDOM.render(preloadLinks, document.getElementById("prefetch"));

  await Promise.all(promises);
}

/**
 * Finds all script tags and returns array of matches
 * @param {string} html
 * @returns {[string, string, string][]} [scriptTag, scriptSource, childJS]
 */
function getScriptTags(html) {
  const matches = [];
  const regex = /<script.*?src="(.*?)".*?>(.*?)<\/script>/gm;
  let match;
  while ((match = regex.exec(html)) !== null) {
    matches.push(match);
  }
  return matches;
}

export function useAutoReloadOnNewDeploy() {
  const { navigationPath } = useWorkspacesState();

  const { data: deployedVersion, isLoading } = useDeployedVersion();
  const currentRoute = React.useRef(navigationPath);

  const [shouldReload, setShouldReload] = React.useState(
    () => localStorage?.getItem(LS_SHOULD_RELOAD_KEY) === "1"
  );

  React.useEffect(() => {
    if (
      isLoading ||
      (!isLoading && !deployedVersion) ||
      deployedVersion === versionJson.version
    ) {
      return;
    }
    // get new html and figure out what chunks we need from that.
    prefetchNewVersionSources().then(() => {
      setShouldReload(true);
      localStorage?.setItem(LS_SHOULD_RELOAD_KEY, "1");
    });
  }, [deployedVersion, setShouldReload, isLoading]);

  React.useEffect(() => {
    if (
      shouldReload &&
      currentRoute.current !== navigationPath &&
      RELOADABLE_ROUTES_RE.some((routeRegex) => routeRegex.test(navigationPath))
    ) {
      localStorage?.setItem(LS_SHOULD_RELOAD_KEY, "0");
      if (!window.location.host.startsWith("localhost")) {
        window.location.reload();
      }
    }
    currentRoute.current = navigationPath;
  }, [shouldReload, navigationPath]);
}
