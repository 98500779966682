import { useTranslation } from "@coworker/locales";
import { FilterType, InputType } from "../types";
import { useAllSelectOption } from "../utils";
import hfbNumbers from "@coworker/app/src/components/InputPopup/UserFiltersRow/hfbNumbers.json";

const HFB_ID = "hfb";

export function useHfbSelectFilter() {
  const { t } = useTranslation();
  const defaultValue = { filterId: HFB_ID, value: "" };

  function getOptions() {
    const options = hfbNumbers.map((hfb) => ({
      value: hfb,
      title: t(`hfbFilterOptionString`, { hfb }) + " - " + t(`hfb${hfb}String`),
    }));

    return options;
  }

  return useAllSelectOption({
    inputType: InputType.Select,
    filterType: FilterType.Filter,
    id: HFB_ID,
    title: t("hfbFilterTitleString"),
    options: getOptions(),
    enabled: true,
    showSearch: true,
    defaultValue,
  });
}
