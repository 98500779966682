import React, { useMemo } from "react";
import { ArticleListItem } from "../Molecules/ArticleListItem";
import { useArticleById } from "../hooks/useArticleById";
import { useMediaById } from "../hooks/useMediaById";
import { useParams } from "react-router";
import { useTranslation } from "@coworker/locales";
import { MediaPlacement } from "../Molecules/MediaPlacement";
import { SupplierNumber } from "../Molecules/SupplierNumber";
import { DateStamp } from "../Molecules/DateStamp";
import { GreyStrip } from "../Atoms/GreyStrip";
import { CreateTasks } from "../Organisms/CreateTasks";
import { useTasksByArticle } from "../hooks/useTasksByArticle";
import { useMediasAndArticleCount } from "../hooks/useMediasAndArticleCount";
import {
  getPrimeTasks,
  getSortedMediasArticleCount,
} from "./ArticleView.helper";
import { ArticleViewSkeleton } from "../Skeletons/ArticleViewSkeleton";
import {
  isBackupForProduct,
  isMainArticle,
} from "../Molecules/MediaArticles.helper";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { ArticleStock } from "../Molecules/ArticleStock";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { HFBLocation } from "../Molecules/HFBLocation";
import { ArticleListItemMobile } from "../styles/styles";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import LinkbarLoading from "@coworker/apprestructured/src/shared/components/Linkbar/LinkbarLoading";

export function ArticleView() {
  const { articleId } = useParams();
  const { t } = useTranslation();

  const { data: article, isLoading: articleLoading } =
    useArticleById(articleId);
  const { data: media, isLoading: mediaLoading } = useMediaById(
    article?.mediaId
  );
  const { tasks } = useTasksByArticle(
    media?.storeId,
    article?.productArticleId
  );
  const { data: mediasArticleCount, isLoading: racLoading } =
    useMediasAndArticleCount(article?.productArticleId, media?.storeId);

  const sortedMediasArticleCount = getSortedMediasArticleCount(
    mediasArticleCount || [],
    media?.name || ""
  );
  const primeTasks = getPrimeTasks(mediasArticleCount || [], tasks);

  const linkbar = useMemo(() => {
    if (!article?.itemName || !media?.name || !media?.id) {
      return <LinkbarLoading />;
    }

    if (!article?.groupId || !article?.groupName) {
      return (
        <Linkbar
          currentLabel={article.itemName}
          items={[
            {
              label: t("roomSettingsString"),
              to: "/mediamaintenance",
            },
            {
              label: media.name,
              to: `/mediamaintenance/tabs/${media.id}?tab=article-list-tab`,
            },
          ]}
        />
      );
    }

    return (
      <Linkbar
        currentLabel={article?.itemName}
        items={[
          {
            label: t("roomSettingsString"),
            to: "/mediamaintenance",
          },
          {
            label: media.name,
            to: `/mediamaintenance/tabs/${media.id}?tab=groups-tab`,
          },
          {
            label: article.groupName,
            to: `/mediamaintenance/groups/${article.groupId}?mediaId=${media.id}`,
          },
        ]}
      />
    );
  }, [
    article?.groupId,
    article?.groupName,
    article?.itemName,
    media?.id,
    media?.name,
    t,
  ]);

  if (articleLoading || mediaLoading || racLoading)
    return (
      <FullScreenPopup noPadding linkbar={linkbar}>
        <ArticleViewSkeleton />
      </FullScreenPopup>
    );

  return (
    <>
      {article && media && tasks && (
        <FullScreenPopup noPadding linkbar={linkbar}>
          <ArticleListItemMobile>
            <ArticleListItem
              article={{ ...article, isFromArticleView: true }}
              isMainProduct={() =>
                isMainArticle(
                  article.id,
                  media.mainArticleId1,
                  media.mainArticleId2
                )
              }
              isBackupForProduct={() =>
                isBackupForProduct(
                  article.id,
                  media.backupArticleId1 ?? "",
                  media.backupArticleId2 ?? ""
                )
              }
              showActionRow={true}
              hideChevron={true}
            />
          </ArticleListItemMobile>
          <FixaListView id="article-view-list" size={"small"}>
            <MediaPlacement
              mediasArticleCount={sortedMediasArticleCount || []}
              currentArticleId={article.id}
            />

            <HFBLocation hfb={article.hfb} />

            <ArticleStock
              productArticleId={article.productArticleId}
              productArticleType={article.productArticleType}
            />

            <SupplierNumber
              article={article}
              value={article.supplierNumber ?? ""}
            />

            <DateStamp article={article} value={article.dateStamp ?? ""} />
          </FixaListView>

          <GreyStrip text={t("foundInAllRoomSettingsString")} />

          <CreateTasks
            mediasArticleCount={sortedMediasArticleCount || []}
            tasks={primeTasks}
            currentArticleId={article.id}
          />
        </FullScreenPopup>
      )}
    </>
  );
}
