import React from "react";
import FixaTabs, {
  FixaTab,
  FixaTabPanel,
} from "@coworker/apprestructured/src/shared/wrappers/FixaTabs/FixaTabs";

function SkapaTabs({ tabs, active, onTabChanged, noTabPanelPadding = false }) {
  const skapatabs = tabs.map(({ key, title, testId = "testId1" }) => (
    <FixaTab
      key={key}
      text={title}
      onClick={() => onTabChanged(key)}
      tabPanelId={key}
      data-testid={testId}
      className={key === active ? "active" : ""}
    />
  ));

  const skapatabPanels = tabs.map(({ key, content }) => (
    <FixaTabPanel
      key={key}
      tabPanelId={key}
      style={{ padding: noTabPanelPadding && 0 }}
    >
      {key === active ? content : null}
    </FixaTabPanel>
  ));

  return (
    <FixaTabs tabs={skapatabs} tabPanels={skapatabPanels} activeTab={active} />
  );
}

export default SkapaTabs;
