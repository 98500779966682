import { FilterValue } from "../../types";
import { useUserPreference } from "@coworker/app/src/hooks/useProfilePreferencesQuery";

export function useUserPreferenceStoreFilterValues(featureName: string) {
  const [filterValues, setFilterValues] = useUserPreference<FilterValue[]>(
    featureName,
    []
  );

  const storeFilterValues = (values: FilterValue[]) => {
    const valuesToSet = values.length ? values : [];
    setFilterValues(valuesToSet);
  };

  return { filterValues, setFilterValues: storeFilterValues };
}
