import { formatProductIdWithDots } from "@coworker/reusable";
import { TaskType } from "../../tasks/enums/taskTypes";
import { Task } from "@coworker/types/lib/tasks/base";
import { TaskPreviewItem } from "../types/taskPreviewType";
import { MFAQFollowUp } from "@coworker/types/lib/tasks/mfaq_followup";
import SpeechBubbleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/SpeechBubbleIconPath";
import PencilIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PencilIconPath";
import { TasksWithArticle } from "@coworker/types/lib/tasks";
import { CustomTask } from "@coworker/types/lib/tasks/custom";
import { AddonTask } from "@coworker/types/lib/tasks/addon";
import { TaskState } from "../../tasks/enums/taskState";
import { ProductIssueTask } from "@coworker/types/lib/tasks/product_issue";
import issueTypeOptions from "@coworker/enums/issueTypes";
import { issueTypeName } from "@coworker/app/src/constants/issueTypeName";
import { MediaTask } from "@coworker/types/lib/tasks/media";
import { getTranslationKeyFromFlags } from "@coworker/app/src/components/RoomSettings/tools";
import { ProductQualityTask } from "@coworker/types/lib/tasks/product_quality";
import { getTypeListEntry } from "@coworker/app/src/helpers/describeTheProblem";
import { Item } from "../../shared/types/item";

export const getFormattedArticleIdAndName = (
  articleId: string,
  articleName: string
) => {
  return `${articleName} ${formatProductIdWithDots(articleId)}`;
};

export const getIndicatorText = (
  isProductQualityReport: boolean,
  isPriority: boolean,
  t: any
) => {
  if (isPriority) {
    return isProductQualityReport
      ? t("safetyAlarmString")
      : t("priorityString");
  } else {
    return "";
  }
};

export const getTitle = (task: Task, t: any) => {
  switch (task.task_type) {
    case TaskType.ADDON:
      const addonTask = task as AddonTask;
      return addonTask.picked ||
        task.state === TaskState.COMPLETED ||
        task.state === TaskState.CLOSED
        ? t("refillString")
        : t("addonTitleString");

    case TaskType.PRODUCT_QUALITY:
      const productQualityTask = task as ProductQualityTask;
      const translationKey = getTypeListEntry(
        productQualityTask.pq_describe_the_problem_type
      )?.translationKey;
      return productQualityTask.pq_describe_the_problem_type === "OTHER" ||
        productQualityTask?.pq_describe_the_problem_type === undefined
        ? t("productQualityReportString")
        : t(translationKey);

    case TaskType.PRODUCT_ISSUE:
      const productIssueTask = task as ProductIssueTask;
      return productIssueTask.issue_type === issueTypeOptions.OTHER
        ? t("issueTitleDamagedString")
        : issueTypeName[productIssueTask.issue_type];
    case TaskType.MFAQ_FOLLOW_UP:
      return t("mfaqFollowUpTitle");
    case TaskType.TESTBUY_FOLLOW_UP:
      return t("testBuyFollowUpString");
    case TaskType.ROOM_SETTINGS:
      const mediaTask = task as MediaTask;
      return t(getTranslationKeyFromFlags(mediaTask), {
        room_name: mediaTask.media_name,
      });
    default:
      const customTask = task as CustomTask;
      return customTask.custom_title || t("unknownString");
  }
};

export const mapTaskToTaskPreview = (
  item: Item | undefined,
  task: Task,
  t: any
) => {
  const title = getTitle(task, t);
  switch (task.task_type) {
    case TaskType.ADDON:
    case TaskType.PRODUCT_QUALITY:
    case TaskType.PRODUCT_ISSUE:
    case TaskType.TESTBUY_FOLLOW_UP:
    case TaskType.ROOM_SETTINGS:
      const fullTask = task as TasksWithArticle;
      const description = getFormattedArticleIdAndName(
        fullTask.product_article_id,
        fullTask?.item_name && fullTask?.item_name !== ""
          ? fullTask?.item_name
          : item?.name ?? ""
      );
      return {
        id: task.id,
        articleId: fullTask.product_article_id,
        title: title,
        description: description,
        indicatorText: getIndicatorText(
          task.task_type === TaskType.PRODUCT_QUALITY,
          task.priority_flag,
          t
        ),
        imageUrl:
          fullTask?.item?.image_small && fullTask?.item?.image_small?.length > 0
            ? fullTask?.item?.image_small
            : item?.smallImageUrl ?? item?.imageUrl,
        showIndicator: task.priority_flag,
      } as TaskPreviewItem;
    case TaskType.MFAQ_FOLLOW_UP:
      const mfaqFollowUpTask = task as MFAQFollowUp;
      return {
        id: task.id,
        title: title,
        description: mfaqFollowUpTask.question_text,
        icon: SpeechBubbleIconPath,
      } as TaskPreviewItem;

    case TaskType.CUSTOM:
      const customTask = task as CustomTask;
      return {
        id: task.id,
        title: title,
        description: customTask.description,
        icon: PencilIconPath,
      } as TaskPreviewItem;
    default:
      return {};
  }
};
