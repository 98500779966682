import React from "react";
import {
  Filter,
  InputType,
  FilterValue,
  StringFilterValue,
  SelectFilter,
} from "../types";
import { PillSelect } from "./PillSelect";
import FilterRowCss from "./FilterRow.module.css";
import { useTranslation } from "@coworker/locales";
import FixaButton from "../../wrappers/FixaButton/FixaButton";
import FixaFiltersIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/FiltersIconPath";
import { getSelectedFilterValue, useChangedFiltersCount } from "../utils";

interface FilterRowProps {
  filters: Filter[];
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  onChange: (filterValues: FilterValue[]) => void;
  setFilterModalIsOpen: (isOpen: boolean) => void;
}

export const FilterRow = ({
  filters,
  filterValues,
  defaultValues,
  onChange,
  setFilterModalIsOpen,
}: FilterRowProps) => {
  const { t } = useTranslation();
  const changedFilterCount = useChangedFiltersCount(filters, filterValues);

  const inputSelectPill = (filter: SelectFilter) => {
    return (
      <PillSelect
        key={filter.id}
        filter={filter}
        selected={
          getSelectedFilterValue(
            filter.id,
            filterValues,
            defaultValues
          ) as StringFilterValue
        }
        onChange={(option) => {
          onChange([
            ...filterValues.filter(
              (filterValue) => filter.id !== filterValue.filterId
            ),
            { filterId: filter.id, value: option.value },
          ]);
        }}
      />
    );
  };

  return (
    <div className={FilterRowCss["filters-container"]}>
      <FixaButton
        type="primary"
        ssrIcon={FixaFiltersIconPath}
        text={t("filterString", { count: changedFilterCount })}
        size="small"
        onClick={() => {
          setFilterModalIsOpen(true);
        }}
      />
      {filters.map((filter) => {
        switch (filter.inputType) {
          case InputType.Select: {
            return inputSelectPill(filter);
          }
          default:
            return null;
        }
      })}
    </div>
  );
};
