import { useState, useEffect } from "react";
import { Filter, FilterType, FilterValue } from "../types";

export function useChangedFiltersCount(
  filters: Filter[],
  filterValues: FilterValue[]
) {
  const [changedFilterCount, setChangedFilterCount] = useState(0);

  useEffect(() => {
    const count = filters.reduce((accumulator, currentFilter) => {
      const filterValue = filterValues.find(
        (filterValue) => currentFilter.id === filterValue.filterId
      );
      if (
        currentFilter.filterType !== FilterType.Sort &&
        filterValue &&
        filterValue.value !== currentFilter.defaultValue.value
      ) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);
    setChangedFilterCount(count);
  }, [filterValues, filters]);

  return changedFilterCount;
}
