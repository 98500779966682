import React, { useState } from "react";
import { FilterRow } from "./FilterRow";
import { AppliedFilterRow } from "./AppliedFilterRow";
import { FilterModal } from "./FilterModal";
import { Filter, FilterValue } from "../types";
import { getDefaultFilterValue } from "../utils/filter";

interface FilterPanelProps {
  filters: Filter[];
  filterValues: FilterValue[];
  onChange: (filterValues: FilterValue[]) => void;
}

// Main component to view filters.
export const FilterPanel = (filterPanelProps: FilterPanelProps) => {
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
  const defaultValues = getDefaultFilterValue(filterPanelProps.filters);

  return (
    <>
      <FilterRow
        {...filterPanelProps}
        defaultValues={defaultValues}
        setFilterModalIsOpen={setFilterModalIsOpen}
      />
      <AppliedFilterRow {...filterPanelProps} />
      <FilterModal
        {...filterPanelProps}
        defaultValues={defaultValues}
        visible={filterModalIsOpen}
        onClose={() => setFilterModalIsOpen(false)}
      />
    </>
  );
};
