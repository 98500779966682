import React from "react";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaModalBody } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalBody";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { Trans, useTranslation } from "@coworker/locales";
import { FilterListView } from "./FilterListView";
import { useStoreTeams } from "@coworker/app/src/core/hooks/useStoreTeams";
import { ActivityFilterType } from "../../../../types/filterOptionsTypes";
import { DEFAULT_FILTER_VALUES } from "../../../../constants/defaultFilterValues";
import {
  getGrouppedFilterOptions,
  GroupedFilterOptionsType,
} from "../../../../constants/filterOptions";
import styles from "./activity-filters-panel.module.css";
import trackerHelper from "@coworker/app/src/helpers/tracker";
import { LocationFilter } from "./LocationFilter";
import { useFixaUID } from "@coworker/app/src/core/auth/useLoggedInUser";
import { getLocationFilterSet } from "../../../../helpers/getFilterLocationSet";
import { updateLocationsPanelFilters } from "../../../../helpers/updateLocationsPanelFilters";
import { FullTask } from "../../../../types/FullTask";

interface ActivityFiltersPanelProps {
  isFilterPanelOpen: boolean;
  setIsFilterPanelOpen: (value: boolean) => void;
  filters: ActivityFilterType;
  setFilters: (value: ActivityFilterType) => void;
  allTasks: FullTask[];
}

export const ActivityFiltersPanel = ({
  isFilterPanelOpen,
  setIsFilterPanelOpen,
  filters,
  setFilters,
  allTasks,
}: ActivityFiltersPanelProps) => {
  const { t } = useTranslation();
  const teams = useStoreTeams();
  const fixaUID = useFixaUID();
  const [panelFilters, setPanelFilters] = React.useState<ActivityFilterType>(
    DEFAULT_FILTER_VALUES
  );
  const [addonLocationType, setAddonLocationType] = React.useState("pickup");

  const isAddonTask =
    panelFilters.taskType === "refill" ||
    panelFilters.taskType === "pickAndRefill";

  const shouldHideLocations =
    panelFilters.taskType === "roomSettings" ||
    panelFilters.taskType === "main" ||
    panelFilters.taskType === "planned";

  React.useEffect(() => {
    setPanelFilters(filters);
  }, [filters]);

  const handleGenericFilterChange = (filterId: string, value: string) => {
    trackerHelper.trackActivityFilterPanelClick(filterId, value);

    setPanelFilters({
      ...panelFilters,
      [filterId]: value,
    });
  };

  const handleLocationFilterChange = (value: string) => {
    let newLocationsFilterSet = getLocationFilterSet(
      panelFilters,
      addonLocationType,
      isAddonTask
    );

    if (newLocationsFilterSet.includes(value)) {
      newLocationsFilterSet = newLocationsFilterSet.filter(
        (item: string) => item !== value
      );
    } else {
      newLocationsFilterSet = [...newLocationsFilterSet, value];
    }

    trackerHelper.trackActivityFilterPanelClick(
      "locations",
      newLocationsFilterSet
    );

    updateLocationsPanelFilters(
      panelFilters,
      newLocationsFilterSet,
      addonLocationType,
      isAddonTask,
      setPanelFilters
    );
  };

  const getFilterListComponent = (
    titleKey: string,
    filterName: keyof GroupedFilterOptionsType
  ) => {
    return (
      <div id={`filter-section-${filterName}`}>
        <FilterListView
          open
          title={titleKey}
          list={getGrouppedFilterOptions(teams)[filterName].map((item) => ({
            id: item.id,
            title: "name" in item ? item.name : t(item.transKey || ""),
            value: item.id,
            control: "radiobutton",
            checked:
              item.id === panelFilters[filterName] ||
              (item.id === "me" && panelFilters[filterName] === fixaUID),
          }))}
          handleChange={(newValue) => {
            handleGenericFilterChange(filterName, newValue);
          }}
        />
      </div>
    );
  };

  return (
    <FixaModal
      visible={isFilterPanelOpen}
      handleCloseBtn={() => {
        setPanelFilters(filters);
        setIsFilterPanelOpen(false);
      }}
      className={styles["filters-panel"] as string}
    >
      <FixaSheets
        alignment={"right"}
        preserveAlignment={false}
        size="small"
        fullSize={true}
        header={<FixaModalHeader title={t("refineString")} floating={false} />}
        footer={
          <FixaModalFooter>
            <FixaButton
              text={<Trans>resetString</Trans>}
              type="secondary"
              onClick={() => {
                trackerHelper.trackActivityResetFilterButtonClick();
                setIsFilterPanelOpen(false);
                setPanelFilters({ ...DEFAULT_FILTER_VALUES, locations: [] });
                setFilters({ ...DEFAULT_FILTER_VALUES, locations: [] });
                localStorage.setItem(
                  "activityFilters",
                  JSON.stringify({ ...DEFAULT_FILTER_VALUES, locations: [] })
                );
              }}
            />
            <FixaButton
              text={<Trans>applyString</Trans>}
              type="primary"
              onClick={() => {
                trackerHelper.trackActivityApplyFilterButtonClick();
                setIsFilterPanelOpen(false);
                setFilters(panelFilters);
                localStorage.setItem(
                  "activityFilters",
                  JSON.stringify(panelFilters)
                );
              }}
            />
          </FixaModalFooter>
        }
      >
        <FixaModalBody>
          {getFilterListComponent("assignedToTaskFilterString", "assignedTo")}
          {getFilterListComponent("createdByString", "createdBy")}
          {getFilterListComponent("task_typeString", "taskType")}
          {getFilterListComponent("periodString", "period")}
          {getFilterListComponent("sortByTitleString", "sortedBy")}

          {shouldHideLocations ? null : (
            <LocationFilter
              panelFilters={panelFilters}
              allTasks={allTasks}
              handleChange={handleLocationFilterChange}
              addonLocationType={addonLocationType}
              setAddonLocationType={setAddonLocationType}
            />
          )}
        </FixaModalBody>
      </FixaSheets>
    </FixaModal>
  );
};
