import React from "react";
import { Articles } from "../types/article";
import { Media } from "../types/media";
import { ArticleGroupItem } from "../Molecules/ArticleGroupItem";
import { CenterContainer, ListWrapper, SizedText } from "../styles/styles";
import { Groups } from "../types/groups";
import { GroupsText } from "../Atoms/GroupsText";
import styled from "styled-components";
import { Trans } from "@coworker/locales";

const Margin = styled.div`
  margin-left: 16px;
  margin-right: 16px;
`;
const Gap = styled.div`
  margin: 12px;
`;

interface GroupedArticleListingProps {
  articles: Articles;
  media: Media;
  groups?: Groups | undefined;
}

export const GroupedArticleListing = ({
  articles,
  media,
  groups,
}: GroupedArticleListingProps) => {
  if (!groups || groups.length === 0) {
    return (
      <CenterContainer
        $centerText={true}
        style={{ margin: "48px 16px", gap: "12px" }}
      >
        <SizedText $fontSize={24} $bold={true}>
          <Trans>noGroupsCreatedYetString</Trans>
        </SizedText>
        <SizedText $fontSize={16} $bold={false}>
          <Trans>noGroupsCreatedYetClarificationString</Trans>
        </SizedText>
      </CenterContainer>
    );
  }

  return (
    <Margin>
      <GroupsText groupsCount={groups.length} />
      <Gap />
      <ListWrapper>
        {groups.map((group, index) => (
          <ArticleGroupItem
            key={`${media.id}-${group.id}-${index}`}
            group={group}
            articles={articles}
            mediaId={media.id}
          />
        ))}
      </ListWrapper>
    </Margin>
  );
};
