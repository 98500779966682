import styled from "styled-components";
import PropTypes from 'prop-types';

const ToastContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: var(--white);
  background: var(--black);
  height: 50px;
  width: 100%;
  cursor: pointer;
  z-index: var(--z-toast-2);
  flex-shrink: 0;
`;
const CloseButton = styled.div`
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
  `
const Toast = ({ children, onClose }) => {
  return (
    <ToastContainer>
      <div>{children}</div>
      <CloseButton onClick={onClose}>&times;</CloseButton>
    </ToastContainer>
  );
};

Toast.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Toast;
