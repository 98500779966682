import { useTranslation } from "@coworker/locales";
import { FilterType, InputType } from "../types";
import { useAllSelectOption } from "../utils";

const ALERT_ID = "alert";

export function useAlertSelectFilter() {
  const { t } = useTranslation();
  const defaultValue = { filterId: ALERT_ID, value: "" };

  return useAllSelectOption({
    inputType: InputType.Select,
    filterType: FilterType.Filter,
    id: ALERT_ID,
    title: t("alertsFilterTitleString"),
    options: [
      { value: "eds", title: t("edsString") },
      { value: "pastEds", title: t("pastEdsString") },
      { value: "salesStop", title: t("salesStopString") },
    ],
    enabled: true,
    defaultValue,
  });
}
