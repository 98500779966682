import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useTasksByStore } from "../hooks/useTasksByStore";
import useFlag, { FLAGS } from "../../../hooks/useFlag";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { useAlertsByStore } from "../hooks/useAlertsByStore";
import { useMediasByStoreId } from "../hooks/useMediasByStoreId";
import { MediasViewSearchListDesktop } from "../Organisms/MediasViewSearchListDesktop";
import { MessageAndButton } from "../Molecules/MessageAndButton";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { CreateSettingsModal } from "../Organisms/Modals/CreateSettingsModal";
import { Media } from "../types/media";
import { MessageModal } from "../Organisms/Modals/MessageModal";
import { useUpdateMediaWithId } from "../hooks/useUpdateMedia";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";
import { MediasViewListDesktop } from "../Organisms/MediasViewListDesktop";
import { useSearchArticlesInMedias } from "../hooks/useSearchArticlesInMedias";
import { useMatchingMedias } from "../../../hooks/useMatchingMedias";

const ScrollContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 0px 64px 64px 64px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
`;

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const HeaderTitleText = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const SearchResultCountText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding-top: 8px;
`;

const HorizontalLine = styled.hr`
  height: 1px;
  background-color: #dfdfdf;
`;

export function MediasViewDesktop() {
  const [showModalMessage, setShowModalMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [displayedQuery, setDisplayedQuery] = useState("");
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const storeId = useStoreId();
  const { data: medias } = useMediasByStoreId(storeId);
  const { data: alerts } = useAlertsByStore(storeId);
  const updateMediaMutation = useUpdateMediaWithId();
  const { data: articles } = useSearchArticlesInMedias(storeId, searchQuery);
  const matchingMedias = useMatchingMedias(medias, searchQuery);
  const tasks = useTasksByStore(storeId);
  const hideForGermanyUsers = useFlag(FLAGS.GERMANY_ROLLOUT);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const sanitizedInput = inputValue.replace(/[.,]/g, "");
    setDisplayedQuery(inputValue);
    setSearchQuery(sanitizedInput);
  };

  const onClear = () => {
    setDisplayedQuery("");
    setSearchQuery("");
  };

  const onSaveMedia = async (media: Media) => {
    try {
      const { nbrArticles: nbr_articles, pieces, ...saveMedia } = media;
      await updateMediaMutation.mutateAsync(saveMedia);
    } catch (error) {
      console.log(error);
      setShowModalMessage(t("couldNotUpdateRoomTryAgainString"));
    }
  };

  if (hideForGermanyUsers) {
    return (
      <>
        <ToolbarContainer>
          <HeaderTitleText>{t("roomSettingsString")}</HeaderTitleText>
        </ToolbarContainer>
        <HorizontalLine />
        <ToolbarContainer>{t("roomSettingsDisabledString")}</ToolbarContainer>
      </>
    );
  }

  const onOngoingTasksClick = () => {
    push(`/mediamaintenance/tasks`);
  };

  // No medias
  if (medias && medias.length === 0) {
    return (
      <>
        <MessageAndButton
          keyForMessageText={"noRoomSettingsCreatedString"}
          keyForButtonText={"addRoomSettingsString"}
          buttonAction={async () => {
            setShowModal(true);
          }}
        />
        <CreateSettingsModal
          storeId={storeId}
          isVisible={showModal}
          onClose={(mediaId) => {
            setShowModal(false);
            if (mediaId) {
              push(`/mediamaintenance/tabs/${mediaId}?tab=article-list-tab`);
            }
          }}
        />
      </>
    );
  }

  const doSearch = searchQuery.length > 1;

  return (
    <>
      <ScrollContainer>
        <ToolbarContainer>
          <HeaderTitleText>{t("roomSettingsString")}</HeaderTitleText>
          <FixaButton
            aria-label={t("addRoomSettingsString")}
            text={t("addRoomSettingsString")}
            type="primary"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </ToolbarContainer>

        <HorizontalLine />

        <ToolbarContainer>
          <div></div>
          <div>
            <FixaSearch
              id="media-search"
              value={displayedQuery}
              onChange={onChange}
              onClear={onClear}
              placeholder={t("roomsViewSearch")}
              ariaLabel={t("roomsViewSearch")}
              style={{ width: "310px" }}
            />
            <SearchResultCountText>
              {doSearch
                ? t("searchResultString", {
                    count:
                      (matchingMedias?.length ?? 0) + (articles?.length ?? 0),
                  })
                : "\xa0"}
            </SearchResultCountText>
          </div>
        </ToolbarContainer>

        {doSearch ? (
          <MediasViewSearchListDesktop
            medias={matchingMedias}
            alerts={alerts}
            taskLinks={tasks.taskLinks}
            onSaveMedia={onSaveMedia}
            onOngoingTasksClick={onOngoingTasksClick}
            query={searchQuery}
            storeId={storeId}
            articles={articles ?? undefined}
          />
        ) : (
          <MediasViewListDesktop
            medias={medias}
            alerts={alerts}
            taskLinks={tasks.taskLinks}
            onSaveMedia={onSaveMedia}
            onOngoingTasksClick={onOngoingTasksClick}
          />
        )}
      </ScrollContainer>

      {!!showModalMessage && (
        <MessageModal
          message={showModalMessage}
          closeModal={() => {
            setShowModalMessage("");
          }}
        />
      )}
      <CreateSettingsModal
        storeId={storeId}
        isVisible={showModal}
        onClose={(mediaId) => {
          setShowModal(false);
          if (mediaId) {
            push(`/mediamaintenance/tabs/${mediaId}?tab=article-list-tab`);
          }
        }}
      />
    </>
  );
}
