import React from "react";

// Skapa components
import { FixaChoiceItem } from "@coworker/apprestructured/src/shared/wrappers/FixaChoice/FixaChoiceItem";
import { FixaChoice } from "@coworker/apprestructured/src/shared/wrappers/FixaChoice/FixaChoice";
import { Trans } from "@coworker/locales";
import styled from "styled-components";

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

interface ChosenTeamBoxProps {
  text: string;
  onClick: () => void;
}

export function ChosenTeamBox({ text, onClick }: ChosenTeamBoxProps) {
  return (
    <>
      <Title>
        <Trans>selectedTeamString</Trans>
      </Title>
      <FixaChoice preselectedIndex={0} subtle>
        <FixaChoiceItem id="0" title={text} onClick={onClick} />
      </FixaChoice>
    </>
  );
}
