import React from "react";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { MediaListItemMobile } from "../Molecules/MediaListItemMobile";
import { AlertResponse, Media } from "../types/media";
import { TaskLinks } from "../types/taskLink";
import { MediumSkeletonListItem } from "../Molecules/skeletons/MediumSkeletonListItem";
import { useSortedMedias } from "../../../hooks/useSortedMedias";

interface MediasViewListMobileProps {
  medias: Media[] | undefined;
  taskLinks: TaskLinks;
  alerts: AlertResponse[] | undefined;
}

export function MediasViewListMobile({
  medias,
  taskLinks,
  alerts,
}: MediasViewListMobileProps) {
  const sortedMedias = useSortedMedias(medias);

  if (!sortedMedias) {
    return (
      <FixaListView id="medias-list">
        <MediumSkeletonListItem key="medias-view-list-0" />
        <MediumSkeletonListItem key="medias-view-list-1" />
        <MediumSkeletonListItem key="medias-view-list-2" />
        <MediumSkeletonListItem key="medias-view-list-3" />
      </FixaListView>
    );
  } else {
    return (
      <FixaListView id="medias-list">
        {sortedMedias &&
          sortedMedias.map((media) => (
            <MediaListItemMobile
              key={media.id}
              media={media}
              taskLinks={taskLinks}
              alerts={alerts || []}
            />
          ))}
      </FixaListView>
    );
  }
}
