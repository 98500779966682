import React from "react";
import { useTranslation } from "@coworker/locales";
import { useUpdateArticle } from "../hooks/useUpdateArticle";

import styled from "styled-components";
import { SimpleContainer } from "../styles/styles";
import { Text } from "../Atoms/Text";
import { FixaQuantityStepper } from "@coworker/apprestructured/src/shared/wrappers/FixaQuantityStepper/FixaQuantityStepper";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";

import { Article } from "../types/article";
import { Media } from "../types/media";
import { useToastNotification } from "@coworker/components";

const RowContainerBorderBottom = styled(SimpleContainer)`
  border-bottom: 1px solid var(--grey200);
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

interface EditArticleRowProps {
  index: number;
  media: Media;
  article: Article;
  addArticleWithNoGroup: (article: Article) => void;
}

export function EditArticleRow({
  index,
  media,
  article,
  addArticleWithNoGroup,
}: EditArticleRowProps) {
  const { t } = useTranslation();
  const updateArticleMutation = useUpdateArticle(article.id, false);
  const { showToast } = useToastNotification();

  const changeQuantity = async (newNbrArticles: number) => {
    if (article.nbrArticles === 0) return;
    try {
      await updateArticleMutation.mutateAsync({
        ...article,
        nbrArticles: newNbrArticles,
      });
    } catch (error) {
      showToast(t("backendOperationFailedString"));
    }
  };

  return (
    <>
      <RowContainerBorderBottom key={index} $direction={"row"}>
        <LeftContainer>
          <Text
            text={article.groupName ? t("Group") : t("roomSettingsString")}
            bold
          />
          <Text text={article.groupName ? article.groupName : media.name} />
        </LeftContainer>
        {article.nbrArticles === 0 ? (
          <FixaButton
            type="secondary"
            text={t("addString")}
            size="small"
            style={{ width: "120px" }}
            onClick={() => addArticleWithNoGroup(article)}
          />
        ) : (
          <FixaQuantityStepper
            small={true}
            ariaDescribedBy="Type in a number or use arrow up or arrow down to change the quantity"
            ariaDescribedById="helper"
            ariaLabelDecrease="Decrease value"
            ariaLabelIncrease="Increase value"
            ariaLabelInput="Enter quantity"
            defaultValue={article.nbrArticles}
            maxVal={99}
            minVal={1}
            onQuantityChange={(changedNbrArticles) =>
              changedNbrArticles !== article.nbrArticles &&
              changeQuantity(Number(changedNbrArticles))
            }
          />
        )}
      </RowContainerBorderBottom>
    </>
  );
}
