import React from "react";
import { MediaTask } from "@coworker/types/lib/tasks/media";
import { TaskCard } from "./TaskCard";
import { FullWidthButton } from "../../SkapaButton";
import { useTranslation } from "@coworker/locales";
import {
  getPassedTimeAndTranslationKey,
  getPiecesTranslation,
  isOngoing,
} from "../tools";
import { Column, ListItemWrapper } from "../styles/styles";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { ModalContext } from "@coworker/apprestructured/src/layout/context/ModalContextProvider";
import { ModalType } from "@coworker/apprestructured/src/layout/context/modalType";
import { Text } from "../Atoms/Text";
import { LocationPin } from "../Atoms/Icons/LocationPin";

interface CreateTaskCardProps {
  task: MediaTask | undefined;
  mediaName: string;
  pieces: number;
  isCurrentArticle: boolean;
  articleId: string;
  mediaId: string;
  groupName: string;
  groupId: string;
}

export function CreateTaskCard({
  task,
  mediaName,
  pieces,
  isCurrentArticle,
  articleId,
  mediaId,
  groupName,
  groupId,
}: CreateTaskCardProps) {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const { toggleModal } = React.useContext(ModalContext);

  const headLine = `${mediaName}: ${pieces} ${t(getPiecesTranslation(pieces))}${
    isCurrentArticle ? ` ${t("selectedString")}` : ""
  }${groupName ? ` ${t("inGroupString")}` : ""}`;

  let subText = groupName ? <Text text={groupName} /> : <></>;

  let buttonText = t("createString");

  let isOngoingTask = false;
  if (task) {
    const createdAt = new Date(task.created_at).toISOString();
    const timePassedAndKey = getPassedTimeAndTranslationKey(createdAt);
    isOngoingTask = isOngoing(task);

    let timePassed = `${timePassedAndKey.passedTime} ${t(
      timePassedAndKey.translationKey
    )}`;
    if (timePassedAndKey.translationKey === "lessThanDayString") {
      timePassed = t(timePassedAndKey.translationKey);
    }

    subText = (
      <Column>
        {subText}
        <Text
          text={`${t("lastCreatedString")}: ${
            isOngoingTask ? t("ongoingString") : timePassed
          }`}
        />
      </Column>
    );
    buttonText = isOngoingTask ? t("showTaskString") : t("createString");
  }

  const onClick = () => {
    if (isOngoingTask) {
      toggleModal({
        type: ModalType.TaskDetail,
        payload: { taskId: task?.id },
      });
    } else {
      push(`/mediamaintenance/${mediaId}/articles/${articleId}/createtask`);
    }
  };

  const mediaChevronOnClick = () => {
    if (groupId) {
      push(`/mediamaintenance/groups/${groupId}?mediaId=${mediaId}`);
    } else {
      push(`/mediamaintenance/tabs/${mediaId}?tab=article-list-tab`);
    }
  };

  return (
    <>
      <TaskCard
        leftIcon={<LocationPin />}
        headLine={headLine}
        subText={subText}
        control="navigational"
        rightIcon="chevronRightSmall"
        onClick={mediaChevronOnClick}
      />
      <ListItemWrapper $hasBanner={false}>
        <FullWidthButton
          text={buttonText}
          onClick={onClick}
          type={isOngoingTask ? "secondary" : "primary"}
        />
      </ListItemWrapper>
    </>
  );
}
