import React from "react";
import { useTranslation } from "@coworker/locales";
import { Text } from "../Atoms/Text";
import { MediasAndArticleCountResponse } from "../types/media";
import { getPiecesTranslation } from "../tools";

interface PlacementsTextProps {
  currentArticleId: string;
  mediasArticleCount: MediasAndArticleCountResponse[];
}

export function PlacementsText({
  currentArticleId,
  mediasArticleCount,
}: PlacementsTextProps) {
  const { t } = useTranslation();

  return (
    <>
      {mediasArticleCount &&
        mediasArticleCount.map((media) => {
          const piecesOrPiece = t(getPiecesTranslation(media.nbrArticles));
          return media.articleId === currentArticleId ? (
            <Text
              key={`selected-${media.articleId}`}
              text={`${media.name}: ${media.nbrArticles} ${piecesOrPiece} ${t(
                "selectedString"
              )}`}
              bold
              grey
            />
          ) : (
            <Text
              key={media.articleId}
              text={`${media.name}: ${media.nbrArticles} ${piecesOrPiece}`}
              grey
            />
          );
        })}
    </>
  );
}
