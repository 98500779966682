import React, { useState } from "react";
import {
  FilterType,
  SelectFilter,
  SelectOption,
  StringFilterValue,
} from "../types";
import { SelectListView } from "./SelectListView";
import FixaChevronUpIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronUpIconPath";
import FixaChevronDownIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronDownIconPath";
import FixaPill from "../../wrappers/FixaPill/FixaPill";
import { FixaListBox } from "../../wrappers/FixaListBox/FixaListBox";
import PillSelectCss from "./PillSelect.module.css";

interface PillSelectProps {
  filter: SelectFilter;
  selected: StringFilterValue;
  onChange: (option: SelectOption) => void;
}

export const PillSelect = ({ filter, selected, onChange }: PillSelectProps) => {
  const [listBoxIsOpen, setListBoxIsOpen] = useState(false);

  return (
    <div className={PillSelectCss["dropdown-pill"]}>
      <FixaPill
        iconPosition="leading"
        ssrIcon={
          listBoxIsOpen ? FixaChevronUpIconPath : FixaChevronDownIconPath
        }
        selected={filter.filterType !== FilterType.Sort}
        label={filter.title}
        onClick={() => {
          setListBoxIsOpen(!listBoxIsOpen);
        }}
        size="small"
      />

      {listBoxIsOpen && (
        <div
          className={PillSelectCss["dropdown-backdrop"]}
          onClick={() => {
            setListBoxIsOpen(!listBoxIsOpen);
          }}
        />
      )}

      <FixaListBox
        className={PillSelectCss["dropdown-list"] ?? ""}
        multiple
        open={listBoxIsOpen}
        position="left"
      >
        <SelectListView
          prefix={`pill`}
          filter={filter}
          selected={selected}
          onChange={(option) => {
            onChange(option);
            setListBoxIsOpen(!listBoxIsOpen);
          }}
        />
      </FixaListBox>
    </div>
  );
};
