import React from "react";
import styled from "styled-components";
import { HeaderSearchInput } from "../../features/searching/HeaderSearchInput";
import { ReactComponent as CloseIcon } from "../../assets/svg/close-large.svg";
import { Trans, useTranslation } from "@coworker/locales";
import { useRecentCustomLocations } from "../../services/locations.service";
import { Spacer } from "@coworker/reusable/components/Widgets";
import { useFocus } from "../../hooks/useFocus";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  min-height: 100%;
  height: 100%;
  width: 100%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

const CloseButton = styled(CloseIcon)`
  cursor: pointer;
`;

const CustomLocation = styled.div`
  width: 100%;
  height: 90px;
  padding: 34px 28px;
  display: flex;
  box-sizing: border-box;
  border-top: 1px solid var(--grey150);
  border-bottom: 1px solid var(--grey150);
`;

const CustomLocationContent = styled.div`
  line-height: 21px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--grey900);
  flex-grow: 1;
  ${(props) => props.clickable && `cursor:pointer;`}
`;

const CustomLocationHeader = styled.div`
  line-height: 50px;
  width: 100%;
  background-color: var(--grey100);
  padding: 0px 28px;
  font-size: 14px;
  font-weight: bold;
  color: var(--grey900);
`;

const OptionalLocation = ({ locationCustom, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const { setInputFocus, inputRef } = useFocus();
  const [query, setQuery] = React.useState("");

  const update = React.useCallback(
    (string) => {
      setQuery(string);
      setInputFocus();
    },
    [setInputFocus]
  );

  const updateCustomLocation = React.useCallback(
    (query) => {
      onSubmit({
        locationCode: query,
        locationSection: "",
        locationDepartment: "",
        locationCustom: query,
      });
    },
    [onSubmit]
  );

  const { customLocations } = useRecentCustomLocations(query);

  return (
    <Container>
      <HeaderSearchInput
        inputRef={inputRef}
        onFocus={() => setQuery("")}
        onChange={(e) => update(e.target.value.toUpperCase())}
        onClose={() => update("")}
        onCancel={onClose}
        placeholder={t("enterLocationString")}
        query={query}
        showDone={query.length > 0}
        onDone={() => updateCustomLocation(query)}
        maxLength={40}
      />
      {locationCustom && query === "" && (
        <>
          <CustomLocationHeader>
            <Trans>selectedLocationString</Trans>
          </CustomLocationHeader>
          <CustomLocation data-testid="customLocationSelected">
            <CustomLocationContent>{locationCustom}</CustomLocationContent>
            <CloseButton
              onClick={() => updateCustomLocation("")}
              data-testid="location-X"
            />
          </CustomLocation>
        </>
      )}
      {query ? (
        <CustomLocation>
          <CustomLocationContent
            data-testid="customLocationResult"
            clickable
            onClick={() => updateCustomLocation(query)}
          >
            {query}
          </CustomLocationContent>
        </CustomLocation>
      ) : null}
      {query ? (
        customLocations.map((code) => (
          <CustomLocation key={code}>
            <CustomLocationContent
              data-testid="customLocationResult"
              clickable
              onClick={() => updateCustomLocation(code)}
            >
              {code}
            </CustomLocationContent>
          </CustomLocation>
        ))
      ) : (
        <Spacer height={27} />
      )}
    </Container>
  );
};

export default OptionalLocation;
