import { useQuery } from "@tanstack/react-query";
import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import {
  toHalfWidth,
  containsFullWidthCharacters,
} from "../../../tasks/helpers/toHalfWidth";

export async function fetchItemPriceAndCurrency(itemNo: string) {
  const halfWidthItemNo = containsFullWidthCharacters(itemNo)
    ? toHalfWidth(itemNo)
    : itemNo;
  const url = `${TASKS_SERVICE_URL}/items/item-price?itemNo=${halfWidthItemNo}`;
  const { result } = await fetchAPI("GET", url);
  return result?.[halfWidthItemNo]?.[0] ?? {};
}

// fetching price from SR
export function useItemPriceAndCurrency(itemNo: string) {
  return useQuery({
    queryKey: ["item", "price", itemNo],
    queryFn: async () => await fetchItemPriceAndCurrency(itemNo),
    staleTime: 7200000, // 1 day, considered fresh for a day.
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!itemNo,
  });
}
