import React, { useMemo } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import FixaInlineMessage from "@coworker/apprestructured/src/shared/wrappers/FixaInlineMessage/FixaInlineMessage";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import CheckmarkIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CheckmarkIconPath"; // NOSONAR
import InformationCircleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/InformationCircleIconPath"; // NOSONAR
import { Trans, useTranslation } from "@coworker/locales";
import { MediaTask } from "@coworker/types/lib/tasks/media";
import { WidgetDivider } from "@coworker/reusable/components/Widgets";
import { useArticleById } from "../hooks/useArticleById";
import { useArticlesByMediaId } from "../hooks/useArticlesByMediaId";
import { useCreateTask } from "../../../hooks/TaskActions/useCreateTask";
import { useInputPopup } from "../../InputPopup";
import { useMediaById } from "../hooks/useMediaById";
import { useStoreId, useUserId } from "../../../core/auth/useLoggedInUser";
import { useTaskStateUpdates } from "../hooks/useTaskStateUpdates";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { Reasons } from "../constants";
import { AssigneeSelection } from "../../../core/components/widgets/AssigneeSelection";
import { getTranslationKeyFromReason } from "../tools";
import { insertTaskLink } from "../services/tasks.service";
import {
  isMainArticle,
  isBackupForProduct,
} from "../Molecules/MediaArticles.helper";
import { todosAggregation, getHeader } from "./CreateTaskView.helper";
import { ToggleField } from "../../../core/components/widgets/ToggleField";
import { mediaCreateTaskFactory } from "../ObjectFactories/TaskFactory";
import { Article } from "../types/article";
import { TaskLink } from "../types/taskLink";
import { TaskTodos, TaskTodosSummaries } from "../types/taskCreation";
import { Flag } from "../Atoms/Icons/Flag";
import { HeadLine } from "../Atoms/HeadLine";
import { Person } from "../Atoms/Icons/Person";
import { Priority } from "../Atoms/Icons/Priority";
import { Sofa } from "../Atoms/Icons/Sofa";
import { Text } from "../Atoms/Text";
import AddDescription from "../Molecules/AddDescription";
import { ArticleListItem } from "../Molecules/ArticleListItem";
import { TaskCard } from "../Molecules/TaskCard";
import { CreateTaskModal } from "../Organisms/Modals/CreateTaskModal";
import { FullWidthButton } from "../../SkapaButton";
import { DeleteArticleConfirmModal } from "../Organisms/Modals/DeleteArticleConfirmModal";
import { ReplaceProduct } from "../Organisms/ReplaceProduct";
import { TodoListCard } from "../Organisms/TodoListCard";
import { CreateTaskViewSkeleton } from "../Skeletons/CreateTaskViewSkeleton";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import {
  ArticleListItemMobile,
  ListWrapper,
  PaddedContainer,
} from "../styles/styles";
import AttachPhoto from "../../AttachPhoto";
import { Item } from "@coworker/apprestructured/src/shared/types/item";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import LinkbarLoading from "@coworker/apprestructured/src/shared/components/Linkbar/LinkbarLoading";

const WidthFix = styled.div`
  .list-view-item__action {
    width: auto;
  }
`;
const Container = styled.div`
  border-bottom: 1px solid var(--grey150);
`;

export function CreateTaskView() {
  const { mediaId, articleId } = useParams();
  const { t } = useTranslation();
  const { pop, push } = useWorkspacesAction();
  const { getInput } = useInputPopup();
  const uid = useUserId();
  const storeId = useStoreId();
  const { call: createTask } = useCreateTask();

  const { data: article, isLoading: articleLoading } =
    useArticleById(articleId);
  const { data: existingArticles } = useArticlesByMediaId(mediaId || "");
  const { data: media, isLoading: mediaLoading } = useMediaById(mediaId);
  const [newArticle, setNewArticle] = React.useState<Item | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const [todoSummary, setTodoSummary] = React.useState<TaskTodosSummaries>([
    { pieces: 1, is_new_article: true, is_ptag: false },
  ]);
  const [removeArticleChecked, setRemoveArticleChecked] = React.useState(false);

  const [onClick, setOnClick] = React.useState(false);

  const [localImages, setLocalImages] = React.useState<string[]>([]);

  const defaultTask = mediaCreateTaskFactory(
    uid,
    mediaId || "",
    articleId || "",
    article?.itemName || "",
    article?.supplierNumber || "",
    article?.dateStamp || "",
    article?.nbrArticles || 0,
    false,
    false,
    false,
    false,
    media?.name || "",
    Reasons.DAILY_MAINTENANCE,
    article?.productArticleId || "",
    storeId,
    "",
    article?.itemType || "",
    article?.itemColor || "",
    article?.groupName
      ? t("thisArticleCanBeFoundInGroupString", {
          groupName: article.groupName,
        })
      : " ",
    article?.imageSmall || "",
    false,
    ""
  );

  const {
    task,
    setTask,
    togglePriority,
    updateAssignedTo,
    setDescription,
    updateImages,
  } = useTaskStateUpdates(defaultTask);

  // For now the default setup of useState does not work. Therefor this line
  // is needed to make sure that the task is initiated with the default task.
  if (
    defaultTask.item_name.length > 0 &&
    defaultTask.item_name !== task.item_name
  ) {
    setTask(defaultTask);
  }

  const existingArticleIds =
    existingArticles?.map(
      (existingArticle) => existingArticle.productArticleId
    ) || [];

  const handleTypeOfTask = async () => {
    const response = await getInput("typeOfRoomSettingTask", {
      selectedReason: task.reason,
    });
    if (response) {
      setTask({ ...task, reason: response });
      if (
        (response !== Reasons.DAILY_MAINTENANCE &&
          isMainArticle(
            article?.id ?? "",
            media?.mainArticleId1 ?? "",
            media?.mainArticleId2 ?? ""
          )) ||
        isBackupForProduct(
          article?.id ?? "",
          media?.backupArticleId1 ?? "",
          media?.backupArticleId2 ?? ""
        )
      ) {
        setOnClick(true);
      }
    }
  };

  async function createTaskByReason(art: Article | null = null) {
    if (!art) {
      return;
    }

    const createTaskWithTaskLink = async (
      task: MediaTask,
      taskLink: TaskLink
    ) => {
      const taskId = await createTask(task, task.images);
      taskLink.taskId = taskId;
      await insertTaskLink(taskLink);
    };

    if (task.reason === Reasons.REPLACE_PRODUCT) {
      await createTaskWithTaskLink(
        {
          ...task,
          new_product_article_id: newArticle?.no,
          product_article_id: art.productArticleId,
        } as MediaTask,
        {
          articleId,
          mediaId: mediaId,
          storeId,
          productArticleId: art?.productArticleId,
        } as TaskLink
      );
    } else if (task.reason === Reasons.REMOVE_PRODUCT) {
      await createTaskWithTaskLink(task, {
        articleId,
        mediaId: mediaId,
        storeId,
        productArticleId: art.productArticleId,
      } as TaskLink);
    } else {
      for (const summary of todoSummary) {
        await createTaskWithTaskLink(
          {
            ...task,
            ...summary,
          } as MediaTask,
          {
            articleId,
            mediaId: mediaId,
            storeId,
            productArticleId: art.productArticleId,
          } as TaskLink
        );
      }
    }
  }

  const linkbar = useMemo(() => {
    if (!media || !article) {
      return <LinkbarLoading />;
    }

    const currentLabel = getHeader(task, t);

    if (article.groupId && article.groupName) {
      return (
        <Linkbar
          items={[
            {
              label: t("roomSettingsString"),
              to: "/mediamaintenance",
            },
            {
              label: media.name,
              to: `/mediamaintenance/tabs/${media.id}?tab=groups-tab`,
            },
            {
              label: article.groupName,
              to: `/mediamaintenance/groups/${article.groupId}?mediaId=${media.id}`,
            },
            {
              label: article.itemName,
              to: `/mediamaintenance/articles/${article.id}`,
            },
          ]}
          currentLabel={currentLabel}
          currentAsH1
        />
      );
    } else {
      return (
        <Linkbar
          items={[
            {
              label: t("roomSettingsString"),
              to: "/mediamaintenance",
            },
            {
              label: media.name,
              to: `/mediamaintenance/tabs/${media.id}?tab=article-list-tab`,
            },
            {
              label: article.itemName,
              to: `/mediamaintenance/medias/${media.id}/articles/product_article_id/${article.productArticleId}`,
            },
          ]}
          currentLabel={currentLabel}
          currentAsH1
        />
      );
    }
  }, [article, media, t, task]);

  if (articleLoading || mediaLoading)
    return (
      <FullScreenPopup noPadding linkbar={linkbar}>
        <CreateTaskViewSkeleton />
      </FullScreenPopup>
    );

  return (
    <FullScreenPopup
      noPadding
      actionBarContent={
        <>
          {article && !removeArticleChecked && (
            <FullWidthButton
              text={t("createString")}
              disabled={!task.assigned_team_id}
              onClick={async () => {
                await createTaskByReason(article);
                pop();
              }}
            />
          )}
          {article && removeArticleChecked && (
            <FullWidthButton
              type={"danger"}
              text={`${t("deleteArticleString")}`}
              onClick={() => setDeleteModalOpen(true)}
              fluid
            />
          )}
          {article && deleteModalOpen && (
            <DeleteArticleConfirmModal
              article={article}
              onModalOpenChange={(modalOpen) => {
                setDeleteModalOpen(modalOpen);
              }}
              postDeleteFunction={async () => {
                push(`/mediamaintenance/tabs/${mediaId}`);
              }}
            />
          )}
        </>
      }
      linkbar={linkbar}
    >
      <CreateTaskModal
        modalVisibility={onClick}
        onModalChange={() => setOnClick(!onClick)}
        onCancel={() => {
          setTask({ ...task, reason: Reasons.DAILY_MAINTENANCE });
          setOnClick(false);
        }}
      />
      {media && article && (
        <>
          <TaskCard
            leftIcon={<Flag />}
            headLine={<Text text={t("typeOfTaskString")} bold />}
            subText={
              <Text
                text={t(getTranslationKeyFromReason(task.reason))}
                italic
                grey
              />
            }
            control={"navigational"}
            showDivider={false}
            onClick={handleTypeOfTask}
          />
          <ListWrapper>
            <ArticleListItemMobile>
              <ArticleListItem
                article={article}
                isMainProduct={() =>
                  isMainArticle(
                    article.id,
                    media.mainArticleId1,
                    media.mainArticleId2
                  )
                }
                isBackupForProduct={() =>
                  isBackupForProduct(
                    article.id,
                    media.backupArticleId1 ?? "",
                    media.backupArticleId2 ?? ""
                  )
                }
              />
            </ArticleListItemMobile>
          </ListWrapper>
          <WidgetDivider />
          {task.reason === Reasons.REPLACE_PRODUCT && (
            <ReplaceProduct
              oldProductArticleIds={existingArticleIds}
              setNewArticle={setNewArticle}
            />
          )}
          {task.reason === Reasons.DAILY_MAINTENANCE && (
            <TodoListCard
              maxPieces={article.nbrArticles}
              todosChanged={(todos: TaskTodos) => {
                setTodoSummary(todosAggregation(todos));
              }}
            />
          )}
          {task.reason === Reasons.REMOVE_PRODUCT && (
            <WidthFix>
              <FixaListView id="article-removed-list" size={"small"}>
                <FixaListViewItem
                  title={t("markAsRemovedString")}
                  leadingIcon={CheckmarkIconPath}
                  control={"switch"}
                  controlProps={{
                    value: "",
                    checked: removeArticleChecked,
                    subtle: true,
                  }}
                  onChange={() =>
                    setRemoveArticleChecked(!removeArticleChecked)
                  }
                  emphasised
                  inset
                />
              </FixaListView>
            </WidthFix>
          )}
          <WidgetDivider />
          <Container>
            <TaskCard
              leftIcon={<Sofa />}
              headLine={<Text text={t("roomSettingNameString")} bold />}
              subText={
                <>
                  <HeadLine
                    main={media.name}
                    sub1={media.mainArticleName1}
                    sub2={media.mainArticleName2}
                    bold={false}
                  />
                  {article.groupName && (
                    <Text
                      text={`${t("inGroupFirstUppercaseString")}: ${
                        article.groupName
                      }`}
                      italic
                    />
                  )}
                </>
              }
            />
          </Container>
          <AssigneeSelection
            selectedTeamId={task.assigned_team_id}
            selectedUserId={task.assigned_user_id}
            onConfirm={updateAssignedTo}
            image={<Person />}
          />
          <ToggleField
            title={
              <b>
                <Trans>priorityString</Trans>
              </b>
            }
            image={<Priority />}
            onChange={togglePriority}
            value={task.priority_flag}
          />
          <AddDescription
            editDescription={setDescription}
            value={task.description}
          />
          <AttachPhoto
            images={task.images}
            onImageListChange={updateImages}
            folder={"task_images"}
            localImages={localImages}
            setLocalImages={setLocalImages}
            removeBottomBorder={false}
            lblColor="var(--grey900)"
            overridePadding="32px 16px 32px 24px"
          />
          {task.reason === Reasons.DAILY_MAINTENANCE && (
            <PaddedContainer $py={"8px"} $px={"16px"}>
              <FixaInlineMessage
                title={t("whatsNextString")}
                body={t("whatsNextAfterCreatingTaskString")}
                variant={"informative"}
                ssrIcon={InformationCircleIconPath}
                dismissable
              />
            </PaddedContainer>
          )}
        </>
      )}
    </FullScreenPopup>
  );
}
