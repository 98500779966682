import { useMemo } from "react";
import { Media } from "../components/RoomSettings/types/media";

export const useMatchingMedias = (
  medias: Media[] | undefined,
  query: string | undefined
) => {
  const matchingMedias = useMemo(() => {
    if (medias && medias.length && query) {
      return medias.filter(
        (media) =>
          media.name.toLowerCase().includes(query.toLowerCase()) ||
          media.mainArticleName1.toLowerCase().includes(query.toLowerCase()) ||
          media.mainArticleName2.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      return undefined;
    }
  }, [medias, query]);

  return matchingMedias;
};
