import React from "react";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { Trans, useTranslation } from "@coworker/locales";
import { ModalTypes } from "../../types/views";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import { SizedText } from "../../styles/styles";
import { useInputPopup } from "../../../InputPopup";
import { logEvent } from "../../../../helpers/tracker";

interface FooterButtonActionsModalProps {
  modalVisibility: boolean;
  mediaId: string;
  onTriggerNewModal: (modalType: ModalTypes) => void;
}

export const FooterButtonActionsModal = ({
  modalVisibility,
  mediaId,
  onTriggerNewModal,
}: FooterButtonActionsModalProps) => {
  const { t } = useTranslation();
  const { getInput } = useInputPopup();

  const actionMenuChoice = (
    text: string,
    modalType: ModalTypes,
    disabled: boolean,
    onClickPreMethod: () => Promise<void> = async () => {}
  ) => {
    return (
      <FixaListViewItem
        onClick={() => {
          if (!disabled) onTriggerNewModal(modalType);
        }}
        title={
          <SizedText
            style={{ margin: "0px 24px" }}
            $fontSize={16}
            $bold={!disabled}
            onClick={async () => {
              onTriggerNewModal(modalType);
              await onClickPreMethod();
            }}
          >
            <Trans>{text}</Trans>
          </SizedText>
        }
        emphasised
        size="small"
      />
    );
  };
  return (
    <FixaModal
      visible={modalVisibility}
      handleCloseBtn={() => {
        onTriggerNewModal(ModalTypes.DEFAULT_VIEW);
      }}
    >
      <FixaSheets
        labelledById="groupActions"
        size={"small"}
        header={
          <FixaModalHeader ariaCloseTxt="Close" title={`${t("addString")}`} />
        }
        footer={
          <FixaModalFooter>
            <></>
          </FixaModalFooter>
        }
      >
        <FixaListView id="mediaActionsId" size="small">
          {actionMenuChoice(
            "addArticlesString",
            ModalTypes.DEFAULT_VIEW,
            false,
            async () => {
              if (!mediaId) return;
              await getInput("addMultipleArticles", {
                mediaId,
                groupId: "",
              });
              logEvent("ce:media_addarticles");
            }
          )}
          {actionMenuChoice(
            "createGroupString",
            ModalTypes.CREATE_GROUP,
            false,
            async () => logEvent("ce:media_creategroup")
          )}
        </FixaListView>
      </FixaSheets>
    </FixaModal>
  );
};
