import React, { useEffect, useState } from "react";
import { Media, Medias } from "../types/media";
import { useMediasByStoreId } from "../hooks/useMediasByStoreId";
import styled from "styled-components";
import { MediaImagesListItem } from "../Molecules/MediaImagesListItem";
import { FixaFormField } from "@coworker/apprestructured/src/shared/wrappers/FixaFormField/FixaFormField";
import { FixaInputField } from "@coworker/apprestructured/src/shared/wrappers/FixaInputField/FixaInputField";
import {
  FixaSelect,
  FixaOption,
} from "@coworker/apprestructured/src/shared/wrappers/FixaSelect/FixaSelect";
import { FixaSwitch } from "@coworker/apprestructured/src/shared/wrappers/FixaSwitch/FixaSwitch";
import { FixaTextArea } from "@coworker/apprestructured/src/shared/wrappers/FixaTextArea/FixaTextArea";
import TagIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/TagIconPath";
import StarFilledIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/StarFilledIconPath";
import StarIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/StarIconPath";
import PaintbrushIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PaintbrushIconPath";
import FamilyGenderMixedIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/FamilyGenderMixedIconPath";
import StairsIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/StairsIconPath";
import FlagIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/FlagIconPath";
import LocationPinSmallIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/LocationPinSmallIconPath";
import { useTranslation } from "@coworker/locales";
import { getDepartmentsOptions } from "../../../helpers/departmentsOptions";
import { getStyleGroupOptions } from "../../../helpers/styleGroupOptions";
import { getLivingSituationOptions } from "../../../helpers/livingSituationOptions";
import { getLongTermPriorityOptions } from "../../../helpers/longTermPriorityOptions";
import { getPricingDataOptions } from "../../../helpers/pricingDataOptions";
import {
  checkEmptyMediaName,
  checkMediaName,
  getArticleName,
  getFullArticleName,
  validationMessage,
} from "./SettingsForm.helper";
import { LabelText } from "../Atoms/LabelText";
import { WarningTextWithIcon } from "../Atoms/WarningTextWithIcon";
import { Articles } from "../types/article";
import { Image } from "../types/images";

const PaddedHorizontalContainer = styled.div`
  padding: 0 1rem;
`;

const ModalItemWrapper = styled.div`
  > * + * {
    margin-top: 20px;
  }
  padding: 20px 0;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PaddedFixaFormField = styled(FixaFormField)`
  padding-right: 12px;
`;

interface SettingsFormProps {
  media: Media;
  articles: Articles;
  onSave: (media: Media) => void;
  showUpdateFields?: boolean;
  images: Image[];
  onImageAddStart: (fileList: FileList) => void;
  onImageAddEnd: (images: Image[]) => void;
  onImageRemove: (images: Image[]) => void;
  onImageUpdate: (image: Image) => void;
}

export const SettingsForm = function ({
  media,
  articles,
  onSave,
  showUpdateFields = true,
  images,
  onImageAddStart,
  onImageAddEnd,
  onImageRemove,
  onImageUpdate,
}: SettingsFormProps) {
  const { t } = useTranslation();
  const [medias, setMedias] = useState<Medias>([]);
  const { data } = useMediasByStoreId(media.storeId);

  // Only load media-list once
  useEffect(() => {
    if (data) {
      setMedias(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaddedHorizontalContainer>
      <ModalItemWrapper>
        <PaddedFixaFormField
          shouldValidate={
            !medias ||
            checkEmptyMediaName(media) ||
            checkMediaName(media, medias)
          }
          validation={{ msg: validationMessage(media, medias ?? [], t) }}
        >
          <FixaInputField
            id={"media_name"}
            type={"text"}
            label={<LabelText text={t("mediaNameString")} withAsterisk />}
            value={media.name}
            ssrIcon={TagIconPath}
            iconPosition={"leading"}
            onChange={(event) => {
              onSave({
                ...media,
                name: event.target.value,
              });
            }}
          />
        </PaddedFixaFormField>

        <LabelText text={t("photosString")} />
        <MediaImagesListItem
          media={media}
          images={images}
          onAddStart={onImageAddStart}
          onAddEnd={onImageAddEnd}
          onRemove={onImageRemove}
          onUpdate={onImageUpdate}
        />

        {showUpdateFields && (
          <>
            <FixaSelect
              id={"main_article_id_1"}
              label={`${t("mainProductBasicString")} #1`}
              value={media.mainArticleId1}
              ssrIcon={StarFilledIconPath}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const articleId =
                  event.target.selectedIndex === 0 ? "" : event.target.value;
                onSave({
                  ...media,
                  mainArticleId1: articleId,
                  mainArticleName1: getArticleName(articles, articleId),
                });
              }}
            >
              {articles
                .filter(
                  (article) =>
                    ![
                      media.mainArticleId2,
                      media.backupArticleId1,
                      media.backupArticleId2,
                    ].includes(article.id)
                )
                .map((article) => (
                  <FixaOption
                    key={article.id}
                    value={article.id}
                    name={getFullArticleName(articles, article.id)}
                  />
                ))}
            </FixaSelect>
            <FlexSpaceBetween>
              <LabelText text={t("activeString")} />
              <FixaSwitch
                id="active"
                checked={media.active === "ACTIVE"}
                value={media.active || ""}
                onChange={() => {
                  onSave({
                    ...media,
                    active: media.active === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                  });
                }}
                disabled={!media.name}
              />
            </FlexSpaceBetween>
            {!media.name && (
              <WarningTextWithIcon text={t("activateRoomNameRequiredString")} />
            )}
            <FixaSelect
              id={"backup_article_id_1"}
              label={`${t("backupForProductString")} #1`}
              value={media.backupArticleId1 ?? ""}
              ssrIcon={StarIconPath}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const articleId =
                  event.target.selectedIndex === 0 ? "" : event.target.value;
                onSave({
                  ...media,
                  backupArticleId1: articleId,
                  backupArticleName1: getArticleName(articles ?? [], articleId),
                });
              }}
            >
              {articles
                .filter(
                  (article) =>
                    ![
                      media.mainArticleId1,
                      media.mainArticleId2,
                      media.backupArticleId2,
                    ].includes(article.id)
                )
                .map((article) => (
                  <FixaOption
                    key={article.id}
                    value={article.id}
                    name={getFullArticleName(articles, article.id)}
                  />
                ))}
            </FixaSelect>
            <FixaSelect
              id={"main_article_id_2"}
              label={`${t("mainProductBasicString")} #2`}
              ssrIcon={StarFilledIconPath}
              value={media.mainArticleId2}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const articleId =
                  event.target.selectedIndex === 0 ? "" : event.target.value;
                onSave({
                  ...media,
                  mainArticleId2: articleId,
                  mainArticleName2: getArticleName(articles, articleId),
                });
              }}
            >
              {articles
                .filter(
                  (article) =>
                    ![
                      media.mainArticleId1,
                      media.backupArticleId1,
                      media.backupArticleId2,
                    ].includes(article.id)
                )
                .map((article) => (
                  <FixaOption
                    key={article.id}
                    value={article.id}
                    name={getFullArticleName(articles, article.id)}
                  />
                ))}
            </FixaSelect>
            <FixaSelect
              id={"backup_article_id_2"}
              label={`${t("backupForProductString")} #2`}
              value={media.backupArticleId2 ?? ""}
              ssrIcon={StarIconPath}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const articleId =
                  event.target.selectedIndex === 0 ? "" : event.target.value;
                onSave({
                  ...media,
                  backupArticleId2: articleId,
                  backupArticleName2: getArticleName(articles, articleId),
                });
              }}
            >
              {articles
                .filter(
                  (article) =>
                    ![
                      media.mainArticleId1,
                      media.backupArticleId1,
                      media.mainArticleId2,
                    ].includes(article.id)
                )
                .map((article) => (
                  <FixaOption
                    key={article.id}
                    value={article.id}
                    name={getFullArticleName(articles, article.id)}
                  />
                ))}
            </FixaSelect>
          </>
        )}
        <FixaTextArea
          id={"media_story"}
          label={t("mediaStoryString")}
          style={{ height: "100px" }}
          value={media.mediaStory ?? ""}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            onSave({
              ...media,
              mediaStory: event.target.value,
            });
          }}
        />
        <FixaSelect
          id={"style_group"}
          label={t("styleGroupString")}
          value={media.styleGroup}
          ssrIcon={PaintbrushIconPath}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onSave({
              ...media,
              styleGroup:
                event.target.selectedIndex === 0 ? "" : event.target.value,
            });
          }}
        >
          {getStyleGroupOptions().map((styleGroup) => (
            <FixaOption {...styleGroup} key={styleGroup.value} />
          ))}
        </FixaSelect>
        <FixaSelect
          id={"living_situation"}
          label={t("livingSituationString")}
          ssrIcon={FamilyGenderMixedIconPath}
          value={media.livingSituation ?? ""}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onSave({
              ...media,
              livingSituation:
                event.target.selectedIndex === 0 ? "" : event.target.value,
            });
          }}
        >
          {getLivingSituationOptions().map((livingSituation) => (
            <FixaOption {...livingSituation} key={livingSituation.value} />
          ))}
        </FixaSelect>
        <FixaSelect
          id={"hf_ltp"}
          label={t("longtermPriorityString")}
          value={media.hfLtp}
          ssrIcon={FlagIconPath}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onSave({
              ...media,
              hfLtp: event.target.selectedIndex === 0 ? "" : event.target.value,
            });
          }}
        >
          {getLongTermPriorityOptions().map((hfltp) => (
            <FixaOption {...hfltp} key={hfltp.value} />
          ))}
        </FixaSelect>
        <FixaSelect
          id={"pricing"}
          label={t("priceLevelString")}
          value={media.pricing}
          ssrIcon={StairsIconPath}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onSave({
              ...media,
              pricing:
                event.target.selectedIndex === 0 ? "" : event.target.value,
            });
          }}
        >
          {getPricingDataOptions().map((pricing) => (
            <FixaOption {...pricing} key={pricing.value} />
          ))}
        </FixaSelect>
        <FixaSelect
          id={"hfb"}
          label={t("hfbLocationInStoreString")}
          value={media.hfb ?? ""}
          ssrIcon={LocationPinSmallIconPath}
          onChange={(event) => {
            onSave({
              ...media,
              hfb: event.target.value,
            });
          }}
        >
          {getDepartmentsOptions(t).map((department) => (
            <FixaOption {...department} key={department.value} />
          ))}
        </FixaSelect>
      </ModalItemWrapper>
    </PaddedHorizontalContainer>
  );
};
