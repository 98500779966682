import React from "react";
import type { Image } from "@coworker/types/lib/tasks/base";
import { MediaTask } from "@coworker/types/lib/tasks/media";

function isAnyLoading(images: Image[]) {
  try {
    return images.some((image) => "loading" in image);
  } catch (error) {
    return false;
  }
}

// TODO: The typing here looks wrong! This should be a TestbuyFollowupTask right?
export function useTaskStateUpdates(defaultTask: MediaTask) {
  const [task, setTask] = React.useState<MediaTask>(defaultTask);

  return React.useMemo(() => {
    function updateAssignedTo({ gid, uid }: { gid: string; uid: string }) {
      setTask({
        ...task,
        assigned_user_id: uid,
        assigned_team_id: gid,
        state: uid ? "ASSIGNED" : "UNASSIGNED",
        last_assigned_at: Date.now(),
      });
    }

    function isTaskValid() {
      if (!task.assigned_team_id && !task.assigned_user_id) return false;
      if (isAnyLoading(defaultTask.images)) return false;
      return true;
    }

    return {
      task,
      setTask: (taskUpdate: MediaTask) => setTask(taskUpdate),
      togglePriority: () =>
        setTask({ ...task, priority_flag: !task.priority_flag }),
      setIsNewArticleSelected: (isNewArticleSelected: boolean) =>
        setTask({
          ...task,
          is_new_article: isNewArticleSelected,
          is_ptag: !isNewArticleSelected,
        }),
      setIsPTagSelected: (isPTagSelected: boolean) =>
        setTask({
          ...task,
          is_ptag: isPTagSelected,
          is_new_article: !isPTagSelected,
        }),
      setDescription: (description: string) =>
        setTask({
          ...task,
          description: description,
        }),
      updateImages: (images: Image[]) => {
        if (!isAnyLoading(images)) setTask({ ...task, images });
      },
      updateAssignedTo,
      isTaskValid,
    };
  }, [task, defaultTask]);
}
