import { useQuery } from "@tanstack/react-query";
import { INTERVAL } from "@coworker/app/src/constants/clientTime";
import { fetchAPI } from "@coworker/app/src/hooks/API/tasks.service.helper";
import { getEnvironmentId, isChineseEnvironment } from "@coworker/reusable";
import { coreServiceBaseUrl } from "@coworker/app/src/core/cloud-services/cloud-services.helper";
import { User } from "../../types/user";

const envId = getEnvironmentId();
const CORE_SERVICE_URL = coreServiceBaseUrl(envId, isChineseEnvironment());

async function fetchUserByFixaUid(fixaUid: string) {
  const path = `users/${fixaUid}`;
  const url = `${CORE_SERVICE_URL}/${path}`;
  const { result } = await fetchAPI("GET", url);
  return result as User;
}

export function useUserByFixaUid(fixaUid: string) {
  return useQuery<User>({
    queryKey: ["user", fixaUid],
    queryFn: async () => await fetchUserByFixaUid(fixaUid),
    staleTime: 2 * INTERVAL.MINUTE,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
