import { hasOutgoingEDS, hasSalesStop } from "../tools";
import {
  Article,
  ArticleIdNbrArticles,
  EdsData,
  SalesStopData,
} from "../types/article";
import { TaskLinks } from "../types/taskLink";
import { sortArticlesByItemName } from "../../../helpers/sorters";

export const isMainArticle = (
  articleId: string,
  mediasMainArticleId: string,
  mediasSecondaryMainArticleId: string
): boolean => {
  return (
    articleId === mediasMainArticleId ||
    articleId === mediasSecondaryMainArticleId
  );
};

export const isBackupForProduct = (
  articleId: string,
  mediasBackupArticleId: string,
  mediasSecondaryBackupArticleId: string
): boolean => {
  return (
    articleId === mediasBackupArticleId ||
    articleId === mediasSecondaryBackupArticleId
  );
};

export const hasOngoingTask = (articleId: string, taskLinks: TaskLinks) =>
  taskLinks.some((task) => task.articleId === articleId);

const sortMarkedArticles = (
  a: Article,
  b: Article,
  mediasMainArticleId: string,
  mediasSecondaryMainArticleId: string,
  mediasBackupArticleId: string,
  mediasSecondaryBackupArticleId: string
): number => {
  if (a.id === mediasMainArticleId) return -1;
  if (b.id === mediasMainArticleId) return 1;
  if (a.id === mediasSecondaryMainArticleId) return -1;
  if (b.id === mediasSecondaryMainArticleId) return 1;
  if (a.id === mediasBackupArticleId) return -1;
  if (b.id === mediasBackupArticleId) return 1;
  if (a.id === mediasSecondaryBackupArticleId) return -1;
  if (b.id === mediasSecondaryBackupArticleId) return 1;
  return 0;
};

export const sortArticles = <A extends Article>(
  articles: A[],
  mediasMainArticleId: string,
  mediasSecondaryMainArticleId: string,
  mediasBackupArticleId: string,
  mediasSecondaryBackupArticleId: string,
  taskLinks: TaskLinks,
  edsData?: EdsData[],
  salesStopData?: SalesStopData[]
) => {
  return articles.sort((a, b) => {
    const upOrDown = sortMarkedArticles(
      a,
      b,
      mediasMainArticleId,
      mediasSecondaryMainArticleId,
      mediasBackupArticleId,
      mediasSecondaryBackupArticleId
    );
    if (upOrDown !== 0) return upOrDown;

    const aHasTask = hasOngoingTask(a.id, taskLinks);
    const bHasTask = hasOngoingTask(b.id, taskLinks);
    const aHasEDS = edsData ? hasOutgoingEDS(edsData, a) : false;
    const bHasEDS = edsData ? hasOutgoingEDS(edsData, b) : false;
    const aHasSalesStop = salesStopData
      ? hasSalesStop(salesStopData, a)
      : false;
    const bHasSalesStop = salesStopData
      ? hasSalesStop(salesStopData, b)
      : false;

    if (aHasSalesStop && !bHasSalesStop) return -1;
    if (!aHasSalesStop && bHasSalesStop) return 1;
    if (aHasEDS && !bHasEDS) return -1;
    if (!aHasEDS && bHasEDS) return 1;
    if (aHasTask && !bHasTask) return -1;
    if (!aHasTask && bHasTask) return 1;

    return sortArticlesByItemName(a, b);
  });
};

export const selectArticleIdNbrArticles = (
  articleIdNbrArticles: ArticleIdNbrArticles,
  selectedArticleIdsNbrArticles: ArticleIdNbrArticles[],
  setSelectedArticleIdsNbrArticles: (value: ArticleIdNbrArticles[]) => void
) => {
  const foundArticleIdNbrArticles = selectedArticleIdsNbrArticles.find(
    (item) => item.articleId === articleIdNbrArticles.articleId
  );
  if (
    !!foundArticleIdNbrArticles &&
    foundArticleIdNbrArticles?.nbrArticles !== articleIdNbrArticles.nbrArticles
  ) {
    foundArticleIdNbrArticles.nbrArticles = articleIdNbrArticles.nbrArticles;
    return;
  }

  let newSelectedArticleIds = [];
  if (!!foundArticleIdNbrArticles) {
    newSelectedArticleIds = selectedArticleIdsNbrArticles.filter(
      (item) => item.articleId !== articleIdNbrArticles.articleId
    );
  } else {
    newSelectedArticleIds = [
      ...selectedArticleIdsNbrArticles,
      articleIdNbrArticles,
    ];
  }

  setSelectedArticleIdsNbrArticles(newSelectedArticleIds);
};
