import React from "react";
import { AlertResponse, Media, Medias } from "../types/media";
import { TaskLinks } from "../types/taskLink";
import { MediasViewListDesktop } from "./MediasViewListDesktop";
import { MediasViewNoSearchResult } from "../Atoms/MediasViewNoSearchResult";
import { MediasViewArticleListDesktop } from "./MediasViewArticleListDesktop";
import { ArticleWithMediaCount } from "../types/article";
import { useTranslation } from "@coworker/locales";
import styled from "styled-components";

const SectionHeaderText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 0px;
`;

const ArticleSectionHeaderText = styled(SectionHeaderText)`
  padding: 32px 0px 16px 0px;
`;

interface MediasViewSearchListDesktopProps {
  medias: Medias | undefined;
  alerts: AlertResponse[] | undefined;
  taskLinks: TaskLinks;
  onSaveMedia: (media: Media) => void;
  onOngoingTasksClick: () => void;

  query: string;
  storeId: string;
  articles: ArticleWithMediaCount[] | undefined;
}

export function MediasViewSearchListDesktop({
  medias,
  alerts,
  taskLinks,
  onSaveMedia,
  onOngoingTasksClick,
  query,
  storeId,
  articles,
}: MediasViewSearchListDesktopProps) {
  const { t } = useTranslation();
  return (
    <>
      {!!medias &&
        medias.length === 0 &&
        !!articles &&
        articles.length === 0 && (
          <MediasViewNoSearchResult query={query ?? ""} />
        )}

      {!!medias && medias.length > 0 && (
        <SectionHeaderText>
          {t("countRoomSettingsString", { count: medias?.length ?? 0 })}
        </SectionHeaderText>
      )}
      <MediasViewListDesktop
        medias={medias}
        alerts={alerts}
        taskLinks={taskLinks}
        onSaveMedia={onSaveMedia}
        onOngoingTasksClick={onOngoingTasksClick}
        showOngoingTasks={false}
      />

      {!!articles && articles.length > 0 && (
        <ArticleSectionHeaderText>
          {t("countArticlesString", { count: articles?.length ?? 0 })}
        </ArticleSectionHeaderText>
      )}
      <MediasViewArticleListDesktop
        articles={articles}
        taskLinks={taskLinks}
        storeId={storeId}
      />
    </>
  );
}
