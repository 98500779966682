import { getMediaById, updateMedia } from "../services/medias.service";

export async function updateMainAndBackupArticles(
  mediaId: string,
  articleIds: string[]
) {
  const media = await getMediaById(mediaId);

  if (media) {
    const updatedMedia = { ...media };
    for (const articleId of articleIds) {
      if (articleId === media.mainArticleId1) {
        updatedMedia.mainArticleId1 = "";
        updatedMedia.mainArticleName1 = "";
      }
      if (articleId === media.mainArticleId2) {
        updatedMedia.mainArticleId2 = "";
        updatedMedia.mainArticleName2 = "";
      }
      if (articleId === media.backupArticleId1) {
        updatedMedia.backupArticleId1 = "";
        updatedMedia.backupArticleName1 = "";
      }
      if (articleId === media.backupArticleId2) {
        updatedMedia.backupArticleId2 = "";
        updatedMedia.backupArticleName2 = "";
      }
    }

    const mediaMainAndBackupArticles = [
      media.mainArticleId1,
      media.mainArticleId2,
      media.backupArticleId1,
      media.backupArticleId2,
    ];
    const hasChanged = mediaMainAndBackupArticles.some(
      (articleId) => articleId && articleIds.includes(articleId)
    );
    if (hasChanged) await updateMedia(media.id, updatedMedia);
  }
}
