export enum Reasons {
  DAILY_MAINTENANCE = "daily_maintenance",
  REPLACE_PRODUCT = "replace_product",
  REMOVE_PRODUCT = "remove_product",
  SALES_STOP = "sales_stop",
}

export enum TaskListingTypes {
  Ongoing = "ongoing",
  NotOngoing = "not_ongoing",
  All = "all",
}

export enum TaskOwner {
  Mine = "mine",
  Team = "team",
  All = "all",
}

export enum ArticleFilterTypes {
  ALL = "all_articles",
  OUTGOING_EDS = "outgoing_eds",
  ONGOING_TASK = "ongoing_task",
  SALES_STOP = "sales_stop",
  HFB = "hfb",
}

export enum ArticleSortingTypes {
  PRIORITY = "priority",
  NAME = "name",
  LATEST_ACTIVITY = "latest_activity",
}

export enum MediaTypes {
  HOME = "HOME",
  ROOM = "ROOM",
  COMPACT = "COMPACT",
  COORDINATION_MEDIA = "COORDINATION_MEDIA",
  VIGNETTE = "VIGNETTE",
  ROOM_SETTINGS = "ROOM_SETTINGS",
}
