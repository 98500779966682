import React from "react";
import { ArticleWithMediaCount } from "../types/article";
import { ArticleListItemMobile, ListWrapper } from "../styles/styles";
import { ArticleListItem } from "../Molecules/ArticleListItem";
import { MediasViewSearchListItemSkeleton } from "../Molecules/MediasViewSearchListItemSkeleton";
import { TaskLinks } from "../types/taskLink";
import { hasOngoingTask } from "../Molecules/MediaArticles.helper";
import { useEDSData } from "../hooks/useEDSData";
import { useCountryId } from "../../../core/auth/useLoggedInUser";
import { joinProductArticleIds } from "../tools";
import { useSalesStopData } from "../hooks/useSalesStopData";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";

interface MediasViewArticleListMobileProps {
  articles: ArticleWithMediaCount[] | undefined;
  taskLinks: TaskLinks;
  storeId: string;
}

export const MediasViewArticleListMobile = ({
  articles,
  taskLinks,
  storeId,
}: MediasViewArticleListMobileProps) => {
  const countryId = useCountryId();
  const articleNumbers = joinProductArticleIds(articles ?? []);
  const { data: edsData } = useEDSData(countryId, articleNumbers);
  const { data: salesStopData } = useSalesStopData(storeId, articleNumbers);

  if (!articles) {
    return (
      <FixaListView id="articles-list">
        <MediasViewSearchListItemSkeleton key="medias-view-article-0" />
        <MediasViewSearchListItemSkeleton key="medias-view-article-1" />
        <MediasViewSearchListItemSkeleton key="medias-view-article-2" />
        <MediasViewSearchListItemSkeleton key="medias-view-article-3" />
      </FixaListView>
    );
  } else {
    return (
      <ListWrapper>
        {articles &&
          articles.map((article, index) => (
            <ArticleListItemMobile key={`${article.id}-${index}`}>
              <ArticleListItem
                article={article}
                isMainProduct={() => false}
                isBackupForProduct={() => false}
                hasOngoingTask={() => hasOngoingTask(article.id, taskLinks)}
                edsData={
                  edsData?.find(
                    (eds: { productArticleId: string }) =>
                      eds.productArticleId === article.productArticleId
                  ) ?? null
                }
                salesStopData={
                  salesStopData?.find(
                    (salesStop) =>
                      salesStop.productArticleId === article.productArticleId
                  ) ?? null
                }
                selectArticleIdsMode={false}
                isSelected={false}
                showActionRow={false}
              />
            </ArticleListItemMobile>
          ))}
      </ListWrapper>
    );
  }
};
