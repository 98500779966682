import React from "react";
import styles from "./swipeable-task-card.module.css";
import { Trans } from "@coworker/locales";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import EllipsesHorizontalIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/EllipsesHorizontalIconPath";
import { useTaskOptionPopupMenu } from "@coworker/app/src/features/tasks/useTaskOptionPopupMenu";
import { UsableTask } from "@coworker/app/src/components/TaskDetail/actions/helpers";
import { FullTask } from "../../../activity/types/FullTask";

interface MoreButtonProps {
  swipeDirection: string;
  setSwipeDirection: React.Dispatch<
    React.SetStateAction<"" | "left" | "right">
  >;
  task: FullTask;
}

export const MoreButton = ({
  swipeDirection,
  setSwipeDirection,
  task,
}: MoreButtonProps) => {
  const { getTaskMenu } = useTaskOptionPopupMenu(task as UsableTask);
  const handleMore = React.useCallback(
    (event) => {
      event.stopPropagation();
      getTaskMenu();
      setSwipeDirection("");
    },
    [getTaskMenu, setSwipeDirection]
  );

  return (
    <div
      className={`${styles["button-more"]} ${
        swipeDirection === "left"
          ? styles["button-hidden"]
          : styles["button-more-visible"]
      }`}
      onClick={handleMore}
    >
      <FixaSSRIcon paths={EllipsesHorizontalIconPath} />
      <span className={styles["button-text"]}>
        <Trans>moreButton</Trans>
      </span>
    </div>
  );
};
