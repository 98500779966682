import React, { useEffect, useState } from "react";
import styled from "styled-components";
import profilePreferences from "@coworker/enums/profilePreferences";
import { useUserPreference } from "@coworker/app/src/hooks/useProfilePreferencesQuery";
import { useDelayedValue, useDebouncedValue } from "../../hooks/useDebounce";
import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../../hooks/useWorkspaces";
import useAutocompleteProducts from "../../hooks/useAutocompleteProducts";
import { useMixedSearchProducts } from "../../hooks/useSearchProducts";
import { SearchSuggestion, Button, SearchResult } from "@coworker/components";
import { parseProduct } from "../../services/products.service";
import { SearchBarContainer } from "../RoomSettings/styles/styles";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";
import { LoaderIcon } from "@coworker/reusable/Loader";
import { Trans, useTranslation } from "@coworker/locales";

const Container = styled.div<{ changeZIndex?: number }>`
  background: var(--white);
  width: 100%;
  top: 100px;
  left: 0;
  ${(props) =>
    props.changeZIndex ? `z-index: ${props.changeZIndex};` : `z-index: 3;`}
`;

const SearchResultsContainer = styled.div`
  min-height: calc(100% - 71px);
  display: flex;
  flex-direction: column;
`;

const Loader = styled(LoaderIcon)`
  width: 30px;
  margin: 20px auto;
  display: block;
`;

const NoResultsContainer = styled.div`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--grey700);
  padding: 20px 29px 9px 29px;
`;

const SPRArticlesExcludedWarning = styled.div`
  padding-top: 10px;
`;

const ResultWrap = styled.div``;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  font-size: 14px;
  text-align: center;
  padding: 20px 25px 20px 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const LoadMoreButton = styled(Button)`
  margin: 10px auto;
`;

const ErrorContainer = styled(LoadingContainer)`
  justify-content: flex-end;
  *:first-child {
    padding-bottom: 100%;
  }
`;

const SlowLoadingContainer = styled.div`
  margin: auto;
`;

const TryAgainContainer = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  padding: 20px 25px 20px 25px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1440px) {
    padding-bottom: 128px;
  }
`;

const SearchContainer = styled(SearchBarContainer)`
 ${(props) =>
    props.className ? `${props.className}` : ` padding: 0 24px 15px 24px;`}
 
`;

const History = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 0 28px;
  height: 60px;
  font-size: 13px;

  & > p {
    cursor: pointer;
  }
`;

const HistoryTitle = styled.div`
  color: var(--grey500);
`;

function truncatedList(items: string[], newItem: string) {
  const list = [newItem.trim()];
  items.forEach((item) => {
    const clean = item && item.trim();
    if (clean && list.length < 20 && !list.includes(clean)) list.push(clean);
  });
  return list;
}

export type FocusedElement = "none" | "Input" | "TextArea";

interface SearchProps {
  hideSPR: boolean;
  withHistory: boolean;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  focusedElement: FocusedElement;
  updateFocus: (isFocused: boolean) => void;
  className: string;
  placeHolderString: string;
}

export const SearchInput = ({
  hideSPR,
  withHistory,
  inputValue,
  setInputValue,
  focusedElement,
  updateFocus,
  className = "",
  placeHolderString,
}: SearchProps) => {
  const { t } = useTranslation();
  const { setNavigationState } = useWorkspacesAction();
  const { navigationState } = useWorkspacesState();
  const [query, setQuery] = useState("");
  const [modalZIndex, setModalZIndex] = useState(3);

  const debouncedQuery = useDebouncedValue(query, 100);

  const [searchHistory, setSearchHistory] = useUserPreference<string[]>(
    profilePreferences.SEARCH_HISTORY,
    []
  );

  const {
    data: proposals,
    loading: loadingSuggestions,
    dataIsFromAPI: finishedLoadingSuggestions,
    error: autocompleteError,
  }: any = useAutocompleteProducts(query, {
    blockRequest: !query,
    cacheTimeout: 0, // Add the missing cacheTimeout property
  });

  const {
    data,
    dupesMFS,
    dupesSIK,
    hiddenMFS,
    hiddenSIK,
    totalMFS,
    totalSIK,
    RESULT_LIMIT_PER_PAGE,
    error: searchProductsError,
    page,
    loading,
    nextPage,
  } = useMixedSearchProducts(debouncedQuery, {
    proposals,
    finishedLoadingSuggestions,
    hideSPR,
    blockRequest: !debouncedQuery,
    includeNumberSearch: true, // Here we should include product number search to cover all food products etc that aren't included in either of SIK/MFS search results.
  });

  const showDelayedWarning = useDelayedValue(loading && query, 4000);

  const changeZindex = () => {
    return { changeZIndex: modalZIndex };
  };

  function matchesStringCaseInsesitive(p: string, query: string) {
    return p?.toLowerCase() === query?.toLowerCase();
  }

  const update = (string: string) => {
    setQuery(string);
  };

  useEffect(() => {
    if (navigationState && navigationState.query && withHistory) {
      update(navigationState.query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = data.slice(0, page * RESULT_LIMIT_PER_PAGE);
  const amountMore =
    totalMFS -
    dupesMFS -
    hiddenMFS +
    (totalSIK - dupesSIK - hiddenSIK) -
    items.length;
  return (
    <Container data-testid="searchContainer" onClick={() => changeZindex()} >
      <SearchContainer className={className}>
        <FixaSearch
          id="search-articles-room-settings"
          value={query}
          onChange={(e) => update(e.target.value)}
          onClear={() => {
            setNavigationState({ query: "" });
            update("");
          }}
          placeholder={t(`${placeHolderString}`)}
          onClick={(event) => {
            updateFocus(true);
            event.stopPropagation();
          }}
        />
      </SearchContainer>
      <SearchResultsContainer data-testid="searchResults">
        {!autocompleteError && !searchProductsError && loadingSuggestions && (
          <Loader />
        )}
        {!loading &&
          focusedElement === "Input" &&
          !query &&
          !!searchHistory.length && (
            <History data-testid="searchHistoryHeader">
              <HistoryTitle>
                <Trans>searchHistoryString</Trans>
              </HistoryTitle>
              <p
                onClick={() => setSearchHistory([])}
                data-testid="clearHistory"
              >
                <Trans>clearHistoryString</Trans>
              </p>
            </History>
          )}
        {!loading &&
          focusedElement === "Input" &&
          !query &&
          !!searchHistory.length &&
          searchHistory.map((product) => (
            <SearchSuggestion
              clickable
              key={product}
              onClick={() => {
                setSearchHistory(
                  truncatedList(searchHistory, product.toLocaleLowerCase())
                );
                update(product);
                return null;
              }}
              text={product}
            />
          ))}
        {!loadingSuggestions && query && (
          <div data-testid="autocomplete-suggestions">
            {!searchProductsError &&
              proposals
                .filter(
                  (product: string) =>
                    !matchesStringCaseInsesitive(product, query)
                )
                .map((product: React.Key | null | undefined) => (
                  <SearchSuggestion
                    clickable
                    key={product}
                    onClick={() => {
                      if (product) {
                        setSearchHistory(
                          truncatedList(
                            searchHistory,
                            product.toString().toLocaleLowerCase()
                          )
                        );
                      }
                      update(product?.toString() || "");
                      return null;
                    }}
                    text={product?.toString() || ""}
                    uppercase
                  />
                ))}
          </div>
        )}
        {!loading &&
          query.length > 0 &&
          debouncedQuery &&
          !loadingSuggestions &&
          items.length === 0 && (
            <NoResultsContainer>
              <div>{t("noResultsForString", { query })}</div>
              <SPRArticlesExcludedWarning>
                {hideSPR ? t("sprArticlesExcluded") : ""}
              </SPRArticlesExcludedWarning>
            </NoResultsContainer>
          )}
        {(!loading || page > 1) && query && (
          <ResultWrap data-testid="searchResultWrap">
            {items &&
              items.map(
                (
                  item: {
                    key: string | undefined;
                    no: string;
                    name: string;
                    noFormatted: string;
                    description: string;
                    imageUrl: string;
                  },
                  index: number
                ) => {
                  const [short_id] = parseProduct(item.key);
                  return (
                    <SearchResult
                      testId={`searchResult${index}`}
                      key={item.no}
                      description={item.description}
                      itemName={item.name}
                      formattedNumber={item.noFormatted}
                      image={item.imageUrl}
                      onClick={() => {
                        if (query.length) {
                          setSearchHistory(
                            truncatedList(
                              searchHistory,
                              query.toLocaleLowerCase()
                            )
                          );
                        }

                        setModalZIndex(1);
                        update("");
                        updateFocus(false);
                        const addToInput = Array.isArray(inputValue)
                          ? inputValue
                          : inputValue.split("\n");
                        addToInput.push(short_id);
                        setInputValue(
                          addToInput.filter((value) => value !== "").join("\n")
                        );
                        setQuery("");
                        setNavigationState({ query: "" });
                      }}
                      className={undefined}
                    />
                  );
                }
              )}
          </ResultWrap>
        )}
        {(autocompleteError || searchProductsError) && (
          <ErrorContainer>
            <Trans>searchErrorString</Trans>
            <TryAgainContainer
              onClick={() => {
                setQuery("");
              }}
            >
              <Trans>tryAgainString</Trans>
            </TryAgainContainer>
          </ErrorContainer>
        )}
        {loading && (
          <LoadingContainer>
            <Loader />
            {!!showDelayedWarning && (
              <SlowLoadingContainer>
                <Trans>slowLoadingInfoString</Trans>
              </SlowLoadingContainer>
            )}
          </LoadingContainer>
        )}
        {!loading && amountMore > 0 && query && (
          <LoadMoreButton
            customMargin
            data-testid="searchResultMoreButton"
            dark={true}
            primary={true}
            onClick={() => nextPage()}
            text={t("amountMoreString", { count: amountMore })}
          />
        )}
      </SearchResultsContainer>
    </Container>
  );
};
