import React from "react";
import styles from "./task-card.module.css";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import ArrowPairSquarepathIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ArrowPairSquarepathIconPath";
import useFormatScheduled from "@coworker/app/src/hooks/useFormatScheduled";
import taskStateOptions from "@coworker/enums/taskState";
import { FullTask } from "../../../activity/types/FullTask";

export const Frequency = ({ task }: { task: FullTask }) => {
  const { previewHint: frequency } = useFormatScheduled(task.planned ?? {});
  const isTaskCompletedOrClosed =
    task.state === taskStateOptions.COMPLETED ||
    task.state === taskStateOptions.CLOSED;
  return (
    <>
      {frequency && (
        <div className={styles["frequency-container"]}>
          <FixaSSRIcon
            className={`${styles["closed-icon"]} ${styles["frequency-icon"]} ${
              isTaskCompletedOrClosed && styles["frequency-completed-or-closed"]
            }`}
            paths={ArrowPairSquarepathIconPath}
          />
          <div
            className={`${styles["frequency-text"]} ${
              isTaskCompletedOrClosed && styles["frequency-completed-or-closed"]
            }`}
          >
            {frequency}
          </div>
        </div>
      )}
    </>
  );
};
