import React, { useMemo } from "react";
import { useMediaById } from "../hooks/useMediaById";
import { useParams } from "react-router";
import { useTranslation } from "@coworker/locales";
import { MediaPlacement } from "../Molecules/MediaPlacement";
import { SupplierNumber } from "../Molecules/SupplierNumber";
import { DateStamp } from "../Molecules/DateStamp";
import { GreyStrip } from "../Atoms/GreyStrip";
import { CreateTasks } from "../Organisms/CreateTasks";
import { useTasksByArticle } from "../hooks/useTasksByArticle";
import { useMediasAndArticleCount } from "../hooks/useMediasAndArticleCount";
import {
  getPrimeTasks,
  getSortedMediasArticleCount,
  reduceMediasArticleCount,
} from "./ArticleView.helper";
import { ArticleViewSkeleton } from "../Skeletons/ArticleViewSkeleton";
import {
  isBackupForProduct,
  isMainArticle,
} from "../Molecules/MediaArticles.helper";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { ArticleStock } from "../Molecules/ArticleStock";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { HFBLocation } from "../Molecules/HFBLocation";
import { useArticlesByProductArticleId } from "../hooks/useArticlesByProductArticleId";
import { ArticleInGroups } from "../Molecules/ArticleInGroups";
import { AggregatedArticleListItem } from "../Molecules/AggregatedArticleListItem";
import { AggregatedArticle } from "../hooks/useAggregatedArticles";
import { Group } from "../types/groups";
import { ArticleListItemMobile } from "../styles/styles";
import LinkbarLoading from "@coworker/apprestructured/src/shared/components/Linkbar/LinkbarLoading";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";

export function AggregatedArticleView() {
  const { mediaId, productArticleId } = useParams();
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();

  const { data: articles, isLoading: articlesLoading } =
    useArticlesByProductArticleId(mediaId, productArticleId);
  const { data: media, isLoading: mediaLoading } = useMediaById(mediaId);
  const { tasks } = useTasksByArticle(media?.storeId, productArticleId);
  const { data: mediasArticleCount, isLoading: racLoading } =
    useMediasAndArticleCount(productArticleId, media?.storeId);

  const sortedMediasArticleCount = getSortedMediasArticleCount(
    mediasArticleCount || [],
    mediaId
  );

  const primeTasks = getPrimeTasks(mediasArticleCount || [], tasks);

  const article = articles && articles.length > 0 ? articles[0] : undefined;

  const linkbar = useMemo(() => {
    if (!media?.name || !media?.id) {
      return <LinkbarLoading />;
    }

    return (
      <Linkbar
        items={[
          {
            label: t("roomSettingsString"),
            to: "/mediamaintenance",
          },
          {
            label: media.name,
            to: `/mediamaintenance/tabs/${media.id}?tab=article-list-tab`,
          },
        ]}
        currentLabel={article?.itemName ?? ""}
        currentAsH1
      />
    );
  }, [article?.itemName, media?.id, media?.name, t]);

  if (articlesLoading || mediaLoading || racLoading)
    return (
      <FullScreenPopup noPadding linkbar={linkbar}>
        <ArticleViewSkeleton />
      </FullScreenPopup>
    );

  if (!article) push(`/mediamaintenance/tabs/${mediaId}`);
  return (
    <>
      {article && media && tasks && (
        <FullScreenPopup noPadding linkbar={linkbar}>
          <ArticleListItemMobile>
            <AggregatedArticleListItem
              article={
                {
                  ...article,
                  isFromArticleView: true,
                  aggregatedArticles: articles,
                } as AggregatedArticle
              }
              groups={
                (articles || [])
                  .filter((a) => !!a.groupId)
                  .map((a) => {
                    return { id: a.groupId, name: a.groupName } as Group;
                  }) ?? []
              }
              isMainProduct={() =>
                isMainArticle(
                  article.id,
                  media.mainArticleId1,
                  media.mainArticleId2
                )
              }
              isBackupForProduct={() =>
                isBackupForProduct(
                  article.id,
                  media.backupArticleId1 ?? "",
                  media.backupArticleId2 ?? ""
                )
              }
              showActionRow={true}
              hideChevron={true}
            />
          </ArticleListItemMobile>
          <FixaListView id="article-view-list" size={"small"}>
            {(articles?.filter((a) => !!a.groupId).length ?? 0) > 0 && (
              <GreyStrip text={t("inGroupsString")} />
            )}

            <ArticleInGroups articles={articles ?? []} />

            <GreyStrip text={t("inMediaString")} />

            <MediaPlacement
              mediasArticleCount={
                reduceMediasArticleCount(
                  sortedMediasArticleCount.map((mediaArticle) => {
                    return { ...mediaArticle };
                  })
                ) || []
              }
              currentArticleId={article.id}
            />

            <GreyStrip text={t("aboutArticleString")} />

            <HFBLocation hfb={article.hfb} />

            <ArticleStock
              productArticleId={article.productArticleId}
              productArticleType={article.productArticleType}
            />

            <SupplierNumber
              article={article}
              value={article.supplierNumber ?? ""}
            />

            <DateStamp article={article} value={article.dateStamp ?? ""} />
          </FixaListView>

          <GreyStrip text={t("foundInAllRoomSettingsString")} />

          <CreateTasks
            mediasArticleCount={sortedMediasArticleCount || []}
            tasks={primeTasks}
          />
        </FullScreenPopup>
      )}
    </>
  );
}
