import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { ReactComponent as FurnitureTrolleyLoaded } from "../../../assets/svg/reason2-furniture-trolley-loaded.svg";
import { RadioButton } from "@coworker/components";

const LocationSection = styled.div`
  white-space: normal;
  font-size: 14px;
  flex-direction: row;
`;

const LocationCode = styled.div`
  font-weight: normal;
  display: flex;
  align-items: center;
  margin-top: 5px;
  color: var(--grey718);
`;

const SectionName = styled.div`
  font-weight: bold;
`;

const HomeOrPrimaryBox = styled.div`
  background: var(--grey100);
  font-weight: bold;
  font-size: 14px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const LocationRowWrapper = styled.div`
  margin: 15px 24px 16px 24px;
  min-height: 32px;
  cursor: pointer;
  display: flex;
`;

const LocationRowOuterContainer = styled.div`
  min-height: 32px;
  border-bottom: 1px solid var(--grey200);
  background: var(--grey100)
  box-sizing: border-box;
`;

const LocationRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  flex-grow: 1;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;
const FurnitureTrolleyLoadedIcon = styled(FurnitureTrolleyLoaded)`
  height: 13px;
  width: 13px;
  margin-right: 6px;
`;

const Locationlabel = styled.div`
  font-size: 14px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
  svg path {
    fill: var(--grey718);
  }
  color: var(--grey718);
`;

const RelatedRefills = styled.div`
  color: var(--blue-medium);
  font-size: 12px;
  margin-top: 5px;
`;

type LocationRowProps = {
  quantity: number;
  code: string;
  section: string;
  onClick: () => void;
  selectedLocation: string;
  isHomeBase: boolean;
  isPrimary: boolean;
  relatedOnThisLocation: number;
  isCustomLocation: boolean;
  isProductQualityTask: boolean;
};

export function LocationRow({
  quantity,
  code,
  section,
  onClick,
  selectedLocation,
  isHomeBase,
  isPrimary,
  relatedOnThisLocation,
  isCustomLocation,
  isProductQualityTask = false,
}: LocationRowProps) {
  const isHome = isHomeBase ? "H" : isPrimary && "P";

  return (
    <LocationRowOuterContainer>
      <LocationRowWrapper>
        {code === selectedLocation && (
          <RadioButtonWrapper>
            <RadioButton readOnly checked={code === selectedLocation} />
          </RadioButtonWrapper>
        )}
        <Column>
          <LocationRowContainer
            onClick={onClick}
            data-testid={`location-${code}`}
          >
            <LocationSection>
              <SectionName>{section}</SectionName>
              {!isProductQualityTask && (
                <LocationCode>
                  {code}
                  {(isHomeBase || isPrimary) && (
                    <HomeOrPrimaryBox>{isHome}</HomeOrPrimaryBox>
                  )}
                </LocationCode>
              )}
            </LocationSection>
            {!isCustomLocation && !isProductQualityTask && (
              <Locationlabel>
                <FurnitureTrolleyLoadedIcon /> {quantity}
              </Locationlabel>
            )}
          </LocationRowContainer>
          {relatedOnThisLocation > 0 && (
            <RelatedRefills>
              <Trans count={relatedOnThisLocation}>
                relatedRefillsSumString
              </Trans>
            </RelatedRefills>
          )}
        </Column>
      </LocationRowWrapper>
    </LocationRowOuterContainer>
  );
}
