import React from "react";
import { useMyStore } from "../hooks/useMyStore";
import { useLanguage } from "../core/hooks/useLanguage";
import { getStoreUsers, getStoreContacts } from "./API/core.service";
import { useStoreId } from "../core/auth/useLoggedInUser";
import { useQuery } from "@tanstack/react-query";
import { INTERVAL } from "../constants/clientTime";

export function useStoreContactsEnabled() {
  const language = useLanguage();
  const myStore = useMyStore();
  const { country_code = "", country_id = "" } = myStore || {};
  const isCountry = (cc: string) =>
    country_code === cc ||
    country_id === cc ||
    (language?.includes && language.includes("_" + cc));

  return !isCountry("RU") && !isCountry("DE");
}

// const MINUTE = 60_000;

// export function useStoreUsers() {
//   // TODO: In the future this should call users-service
//   return useCachedList("users", 2 * MINUTE, "CORE_SERVICE") || [];
// }

export async function fetchStoreContacts(storeId: string) {
  const storeContacts = await getStoreContacts(storeId);
  return storeContacts;
}

// export function useStoreUsers() {
//   // TODO: In the future this should call users-service
//   return useCachedList("users", 2 * MINUTE, "CORE_SERVICE") || [];
// }

export async function fetchStoreUsers(storeId: string) {
  // TODO: In the future this should call users-service
  const users = await getStoreUsers(storeId);
  return users;
}

export function useStoreUsers() {
  const storeId = useStoreId();
  const result = useQuery({
    queryKey: ["storeUsers", storeId],
    queryFn: async () => await fetchStoreUsers(storeId),
    staleTime: 2 * INTERVAL.MINUTE,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  return result?.data ?? [];
}

export function useStoreContacts() {
  const storeId = useStoreId();
  const result = useQuery({
    queryKey: ["storeContacts", storeId],
    queryFn: async () => await fetchStoreContacts(storeId),
    staleTime: 2 * INTERVAL.MINUTE,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  return result?.data ?? [];
  // return useCachedList("contacts", 2 * MINUTE, "CORE_SERVICE") || [];
}

export function useAsMap(list: { id: string }[], isUsersMap?: boolean) {
  return React.useMemo(
    () =>
      list.reduce((map: any, item: any) => {
        if (isUsersMap) {
          map[item.fixa_uid] = item;
        } else {
          map[item.id] = item;
        }
        return map;
      }, {}),
    [list, isUsersMap]
  );
}
