import useFlag from "./useFlag";
import { useQuery } from "@tanstack/react-query";
import { callInternalApi } from "../hooks/API/useCallInternal";
import { useStoreId } from "../core/auth/useLoggedInUser";
import { INTERVAL } from "../constants/clientTime";

type Note = {
  creator_id: string;
  id: string;
  task_id: string;
  text: string;
};

type Response = {
  data: {
    list: Note[];
  };
};

export function useTaskNotes(taskId: string) {
  const storeId = useStoreId();

  const fetchTaskNotes = async () => {
    const response: Response = await callInternalApi(
      `task/notes?id=${taskId}`,
      {
        store_id: storeId,
      }
    );

    return response?.data?.list || [];
  };

  return useQuery({
    queryKey: ["task-notes", taskId],
    queryFn: fetchTaskNotes,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: INTERVAL.MINUTE * 2,
    enabled: !!taskId,
  });
}

export function useTaskHasNotes(taskId: string) {
  const enabled = useFlag("ENABLE_TASKCARD_ICONS");
  return !!useTaskNotes(enabled ? taskId : "").data?.length;
}
