import React from "react";
import AttachPhoto from "../AttachPhoto";
import Note from "./shared/NoteInput";
import { useStoreId, useUserId } from "../../core/auth/useLoggedInUser";
import { useCreateNote } from "../../hooks/useCreateNote";

const AddNote = ({
  onClose: onInputPopupClose,
  setCreatedNote,
  setLocalImages,
  localImages,
  taskId,
}) => {
  const uid = useUserId();
  const storeId = useStoreId();
  const [images, setImages] = React.useState([]);
  const [formData, setFormData] = React.useState({
    text: "",
  });
  const createNoteMutation = useCreateNote(taskId);

  function addNote(event) {
    const data = {
      ...formData,
      creator_id: uid,
    };

    setCreatedNote(data);
    createNoteMutation.mutateAsync({ data, images, storeId });

    event.stopPropagation();
    setImages([]);
    setFormData({ text: "" });
    onClose();
  }

  function onClose() {
    setImages([]);
    setFormData({ text: "" });
    onInputPopupClose();
  }

  function onTextValueChanged(e) {
    setFormData({ ...formData, text: e.target.value });
  }

  function isFormInvalid() {
    return (
      formData.text.trim() === "" || images?.some((image) => image.loading)
    );
  }

  return (
    <Note
      isFormInvalid={isFormInvalid}
      onAddNote={addNote}
      value={formData.text}
      onValueChanged={onTextValueChanged}
    >
      <AttachPhoto
        onImageListChange={setImages}
        images={images}
        folder="notes"
        setLocalImages={setLocalImages}
        localImages={localImages}
      />
    </Note>
  );
};

export default AddNote;
