import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import React from "react";
import { SizedText } from "../../styles/styles";
import { Article } from "../../types/article";
import { useTasksByArticle } from "../../hooks/useTasksByArticle";
import { useStoreId, useUserId } from "../../../../core/auth/useLoggedInUser";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaPrompt } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaPrompt";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { setOngoingTasksToClosed } from "../../tools";
import { Trans, useTranslation } from "@coworker/locales";
import { useDeleteArticlesByIds } from "../../hooks/useDeleteArticlesByIds";
import { useToastNotification } from "@coworker/components";
import { useArticlesByMediaId } from "../../hooks/useArticlesByMediaId";
import { MediumSkeletonBox } from "../../Molecules/skeletons/MediumSkeletonBox";
import { updateMainAndBackupArticles } from "../../hooks/useDeleteArticles.helper";
import { deleteArticleById } from "../../services/articles.service";
import { Reasons } from "../../constants";
import { createAutoGeneratedTaskAsCompleted } from "../../Molecules/ArticleListItemActionRow.helper";
import { useCreateTask } from "../../../../hooks/TaskActions/useCreateTask";
import { useMediaById } from "../../hooks/useMediaById";

interface DeleteArticleConfirmModalProps {
  article: Article;
  onModalOpenChange: (modalOpen: boolean) => void;
  postDeleteFunction?: () => Promise<void>;
  noInvalidateQueries?: boolean;
}

export const DeleteArticleConfirmModal = ({
  article,
  onModalOpenChange,
  postDeleteFunction,
  noInvalidateQueries = false,
}: DeleteArticleConfirmModalProps) => {
  const { showToast } = useToastNotification();
  const { t } = useTranslation();
  const userId = useUserId();
  const storeId = useStoreId();
  const { call: createTask } = useCreateTask();

  const tasks = useTasksByArticle(storeId, article.productArticleId);
  const deleteArticles = useDeleteArticlesByIds(article.mediaId);
  const { data: articlesInMedia } = useArticlesByMediaId(article.mediaId);
  const { data: media } = useMediaById(article?.mediaId || "");

  const createAutoGeneratedTaskWithReason = async (
    reason: Reasons,
    productArticleId: string
  ) => {
    await createAutoGeneratedTaskAsCompleted(
      userId,
      reason,
      article.mediaId,
      media?.name ?? "",
      storeId,
      article,
      productArticleId,
      t("taskIsAutoCompletedNoteString"),
      createTask
    );
  };

  const handleDeleteArticle = async () => {
    const tasksToBeRemoved = {
      ...tasks,
      taskLinks: tasks.taskLinks.filter((tl) => tl.articleId === article.id),
      tasks: tasks.tasks.filter((t) => t.article_id === article.id),
    };
    await setOngoingTasksToClosed(
      article.mediaId,
      t("taskClosedBecauseRemovedArticleString"),
      tasksToBeRemoved,
      userId,
      storeId
    );
    await createAutoGeneratedTaskWithReason(
      Reasons.REMOVE_PRODUCT,
      article.productArticleId
    );
    try {
      if (noInvalidateQueries) {
        await updateMainAndBackupArticles(article.mediaId, [article.id]);
        await deleteArticleById(article.id);
      } else {
        await deleteArticles.mutateAsync([article.id]);
      }
      if (postDeleteFunction) await postDeleteFunction();
    } catch (error) {
      showToast(t("searchErrorString"));
    }
    onModalOpenChange(false);
    showToast(t("articleDeletedString"));
  };

  if (!articlesInMedia)
    return (
      <FixaModal visible={true} handleCloseBtn={() => onModalOpenChange(false)}>
        <FixaPrompt
          title={""}
          titleId={`delete-${article.id}`}
          header={
            <FixaModalHeader
              title={t("deleteNameString", { name: article.itemName })}
              ariaCloseTxt="Close prompt."
            />
          }
          footer={<></>}
        >
          <MediumSkeletonBox />
        </FixaPrompt>
      </FixaModal>
    );

  let tasksWillBeClosed = <Trans>deleteRoomSettingTasksClosedString</Trans>;
  if (article.groupName) {
    tasksWillBeClosed = <Trans>deleteArticleInGroupSetTasksClosedString</Trans>;
  } else {
    tasksWillBeClosed = (
      <Trans values={{ mediaName: t("roomSettingsString") }}>
        deleteArticleSetTasksClosedString
      </Trans>
    );
  }

  return (
    <>
      <FixaModal visible={true} handleCloseBtn={() => onModalOpenChange(false)}>
        <FixaPrompt
          title={""}
          titleId={`delete-${article.id}`}
          header={
            <FixaModalHeader
              title={t("deleteNameString", { name: article.itemName })}
              ariaCloseTxt="Close prompt."
            />
          }
          footer={
            <FixaModalFooter>
              <FixaButton
                text={t("cancelString")}
                type="secondary"
                onClick={() => onModalOpenChange(false)}
              />
              <FixaButton
                text={t("deleteString")}
                type="danger"
                onClick={handleDeleteArticle}
              />
            </FixaModalFooter>
          }
        >
          <>
            <SizedText $fontSize={16} $bold={true}>
              <Trans values={{ item: article.itemName }}>
                surelyDeleteString
              </Trans>
            </SizedText>
            <SizedText $fontSize={14} $bold={false}>
              {tasksWillBeClosed}
              &nbsp;
              <Trans>canNotBeUndoneString</Trans>
            </SizedText>
          </>
        </FixaPrompt>
      </FixaModal>
    </>
  );
};
