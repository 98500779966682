import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createNote } from "../services/note.service";

type Data = {
  creator_id: string;
  text: string;
};

type Images = {
  created_at: number;
  creator_id: string;
  file_name: string;
};

interface CreateNoteProps {
  data: Data;
  images: Images[];
  storeId: string;
  folder: string;
  path: string;
  provider: string;
}

export const useCreateNote = (taskId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (note: CreateNoteProps) =>
      createNote(taskId, note.data, note.images, note.storeId),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ["task-notes", taskId],
      });
    },
  });
};
