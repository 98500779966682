import { useSortSelectFilter } from "@coworker/apprestructured/src/shared/filters/filters";
import {
  ApplyFilter,
  FilterValue,
  SelectFilter,
} from "@coworker/apprestructured/src/shared/filters/types";
import { Article, Combined } from "../types/article";
import { sortArticlesByItemName } from "../../../helpers/sorters";

function compareDate(item1: Combined<Article>, item2: Combined<Article>) {
  return (
    (item1.createdAtDate?.getTime() ?? 0) -
    (item2.createdAtDate?.getTime() ?? 0)
  );
}

function compareProductArticleIdy(
  item1: Combined<Article>,
  item2: Combined<Article>
) {
  return item1.productArticleId.localeCompare(item2.productArticleId);
}

function compareByName(item1: Combined<Article>, item2: Combined<Article>) {
  const alphabeticCompare = sortArticlesByItemName(item1, item2);
  if (alphabeticCompare !== 0) {
    return alphabeticCompare;
  }
  return compareProductArticleIdy(item1, item2);
}

function compareByDate(item1: Combined<Article>, item2: Combined<Article>) {
  const dateComapre = compareDate(item1, item2);
  if (dateComapre !== 0) {
    return dateComapre;
  }
  return compareProductArticleIdy(item1, item2);
}

function useSortSelectArticleFilter(filterValues: FilterValue[]) {
  const filter: ApplyFilter<SelectFilter, Combined<Article>> = {
    filter: useSortSelectFilter(filterValues),
    sortCompare: (
      item1: Combined<Article>,
      item2: Combined<Article>,
      { value }: FilterValue
    ) => {
      switch (value) {
        case "newestFirst":
          return compareByDate(item1, item2);
        case "oldestFirst":
          return -compareByDate(item1, item2);
        case "byName":
        default:
          return compareByName(item1, item2);
      }
    },
  };

  return filter;
}

export { useSortSelectArticleFilter };
