import React from "react";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaModalBody } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalBody";
import { Trans, useTranslation } from "@coworker/locales";
import styles from "./multi-select.module.css";
import SkapaTabs from "@coworker/app/src/components/InputPopup/SkapaTabs";
import { SelectedTasks } from "./SelectedTasks";
import { useActivityData } from "../../../../hooks/useActivityData";
import { DEFAULT_FILTER_VALUES } from "../../../../constants/defaultFilterValues";
import { TaskCard } from "../../../../../tasks/components/TaskCard/TaskCard";
import { useItemsInfo } from "../../../../../shared/hooks/item/useItemsInfo";
import { Item } from "../../../../../shared/types/item";
import { useFixaUID } from "@coworker/app/src/core/auth/useLoggedInUser";
import FixaButton from "../../../../../shared/wrappers/FixaButton/FixaButton";
import { LoadMore } from "../../../../../shared/components/LoadMore/LoadMore";
import { useMultiselectOverflowMenu } from "@coworker/app/src/features/tasks/useMultiselectOverflowMenu";
import { useMultiSelectForTab } from "@coworker/app/src/hooks/useMultiSelect";
import { useInputPopup } from "@coworker/app/src/components/InputPopup";
import { MultiSelectForTabType } from "../../../../types/MultiSelectForTabType";
import { SimpleLoadingBall } from "../../../../../shared/simple/SimpleLoading/SimpleLoading";
import { FullTask } from "../../../../types/FullTask";

interface MultiSelectPanelProps {
  isMultiSelectPanelOpen: boolean;
  setIsMultiSelectPanelOpen: (value: boolean) => void;
}

export const MultiSelectPanel = ({
  isMultiSelectPanelOpen,
  setIsMultiSelectPanelOpen,
}: MultiSelectPanelProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<"my" | "open">("my");
  const uid = useFixaUID();

  const myTasks =
    useActivityData({ ...DEFAULT_FILTER_VALUES, assignedTo: uid })
      ?.myTasksInProgress ?? [];
  const openTasks =
    useActivityData(DEFAULT_FILTER_VALUES)?.openWholeStoreTasks ?? [];

  const allTasks = {
    my: myTasks.data?.pages.flatMap((page) => page.list) ?? [],
    open: openTasks.data?.pages.flatMap((page) => page.list) ?? [],
  };

  const tabs = [
    {
      title: `${t("myString")} (${myTasks.data?.pages[0]?.filteredCount})`,
      key: "my",
    },
    {
      title: `${t("openTasksString")} (${
        openTasks.data?.pages[0]?.totalCount
      })`,
      key: "open",
    },
  ];

  const itemNos = (allTasks.open as FullTask[])
    .map((task) => task.product_article_id)
    .filter(Boolean);
  const { data: itemsInfo } = useItemsInfo(itemNos);

  const { selectedTasksCount, selectedTaskIds } = useMultiSelectForTab(
    activeTab
  ) as MultiSelectForTabType;

  const { getInput } = useInputPopup();
  const { getTaskMenu, loading } = useMultiselectOverflowMenu(
    selectedTasksCount,
    activeTab,
    selectedTaskIds
  );

  const warningPopup = React.useCallback(async () => {
    const response = await getInput("confirmation", {
      question: <Trans count={selectedTasksCount}>aboutToChangeString</Trans>,
      description: (
        <>
          <Trans>undoNotPossibleString</Trans>
          <span>
            <Trans>sureToContinueString</Trans>
          </span>
        </>
      ),
      positiveText: <Trans>yesString</Trans>,
      negativeText: <Trans>negativeString</Trans>,
    });
    if (response) await getTaskMenu();
  }, [getInput, selectedTasksCount, getTaskMenu]);

  const handleSelectedTasks = async (event: React.MouseEvent) => {
    event.stopPropagation();
    selectedTasksCount > 14 ? await warningPopup() : await getTaskMenu();
  };

  const renderTasks = (
    tasks: FullTask[],
    isFetching: boolean,
    fetchNextPage: () => void,
    totalCount: number
  ) => (
    <>
      {tasks.map((task) => {
        return (
          <TaskCard
            key={task.id}
            task={task}
            multiSelect
            type={activeTab}
            item={
              itemsInfo?.find(
                (item: Item) => item.no === task.product_article_id
              ) as Item
            }
          />
        );
      })}
      {tasks.length < totalCount && (
        <LoadMore
          handleClick={fetchNextPage}
          loadedItemsCount={tasks.length}
          totalCount={totalCount}
          isFetching={isFetching}
        />
      )}
    </>
  );

  return (
    <FixaModal
      visible={isMultiSelectPanelOpen}
      handleCloseBtn={() => setIsMultiSelectPanelOpen(false)}
      className={styles["filters-panel"] as string}
    >
      <FixaSheets
        alignment="right"
        preserveAlignment={false}
        size="small"
        fullSize
        header={
          <FixaModalHeader
            title={t("multiSelectTasksString")}
            className={styles["header"] as string}
            floating={false}
          />
        }
        footer={
          selectedTasksCount > 0 ? (
            <FixaModalFooter className={styles["footer"] as string}>
              <FixaButton
                text={t("continue2String")}
                type="primary"
                onClick={handleSelectedTasks}
              />
            </FixaModalFooter>
          ) : null
        }
      >
        <FixaModalBody>
          <SkapaTabs
            tabs={tabs}
            active={activeTab}
            onTabChanged={setActiveTab}
            noTabPanelPadding
          />
          <SelectedTasks type={activeTab} tasks={allTasks[activeTab]} />
          {loading ? (
            <div className={styles["loading"]}>
              <SimpleLoadingBall
                text={t("loadingEllipsisString")}
                size="medium"
              />
            </div>
          ) : (
            <>
              {activeTab === "my" &&
                renderTasks(
                  allTasks.my,
                  myTasks.isFetching,
                  myTasks.fetchNextPage,
                  myTasks.data?.pages[0]?.filteredCount || 0
                )}
              {activeTab === "open" &&
                renderTasks(
                  allTasks.open,
                  openTasks.isFetching,
                  openTasks.fetchNextPage,
                  openTasks.data?.pages[0]?.totalCount || 0
                )}
            </>
          )}
        </FixaModalBody>
      </FixaSheets>
    </FixaModal>
  );
};
