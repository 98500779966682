// This file will be removed in the next PR
import { MediaTypes } from "../constants";

export function transferMainAndAlternativeProduct(incomingMedia) {
  incomingMedia.mediaStory =
    incomingMedia.mediaStory ?? incomingMedia.mainMessage;

  incomingMedia.mainArticleId1 = !!incomingMedia.mainArticleId1
    ? incomingMedia.mainArticleId1
    : incomingMedia.mainProductId1;
  incomingMedia.mainArticleId2 =
    incomingMedia.mainArticleId2 ?? incomingMedia.mainProductId2;
  incomingMedia.mainArticleName1 =
    incomingMedia.mainArticleName1 ?? incomingMedia.mainProductName1;
  incomingMedia.mainArticleName2 =
    incomingMedia.mainArticleName2 ?? incomingMedia.mainProductName2;
  incomingMedia.backupArticleId1 =
    incomingMedia.backupArticleId1 ?? incomingMedia.alternativeProductId1;
  incomingMedia.backupArticleId2 =
    incomingMedia.backupArticleId2 ?? incomingMedia.alternativeProductId2;
  incomingMedia.backupArticleName1 =
    incomingMedia.backupArticleName1 ?? incomingMedia.alternativeProductName1;
  incomingMedia.backupArticleName2 =
    incomingMedia.backupArticleName2 ?? incomingMedia.alternativeProductName2;
}

export function transferMainAndBackupArticle(outgoingMedia) {
  outgoingMedia.mediaType = MediaTypes.ROOM_SETTINGS;

  outgoingMedia.mainMessage = outgoingMedia.mediaStory;

  outgoingMedia.mainProductId1 = outgoingMedia.mainArticleId1;
  outgoingMedia.mainProductId2 = outgoingMedia.mainArticleId2;
  outgoingMedia.mainProductName1 = outgoingMedia.mainArticleName1;
  outgoingMedia.mainProductName2 = outgoingMedia.mainArticleName2;
  outgoingMedia.alternativeProductId1 = outgoingMedia.backupArticleId1;
  outgoingMedia.alternativeProductId2 = outgoingMedia.backupArticleId2;
  outgoingMedia.alternativeProductName1 = outgoingMedia.backupArticleName1;
  outgoingMedia.alternativeProductName2 = outgoingMedia.backupArticleName2;
}
