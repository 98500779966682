// Types and interfaces connected to the React UI components to show filter.
// If the React UI components for filtering are used we will get a common look
// and behavior.
// There is a need to define filters and filter-values in some way since the
// same information is used in two or three different components. Representing
// filters as (dropdown) pills in the filter row. Show the same filters in
// the filter modal. And sometimes show pills for applied filters.

// What React component to use
export enum InputType {
  Select = "Select",
  MultiSelect = "MultiSelect",
  Search = "Search",
}

// Is the React component for filtering or sorting
export enum FilterType {
  Filter = "Filter",
  Sort = "Sort",
}

// Base information about the filter
interface BaseFilter {
  inputType: InputType;
  filterType: FilterType;
  id: string;
  title: string;
  enabled?: boolean;
  showSearch?: boolean;
  defaultValue: FilterValue;
}

// Different filters
export interface SelectFilter extends BaseFilter {
  inputType: InputType.Select;
  options: SelectOption[];
}

export interface MultiSelectFilter extends BaseFilter {
  inputType: InputType.MultiSelect;
  options: SelectOption[];
}

export interface SearchFilter extends BaseFilter {
  inputType: InputType.Search;
}

export type Filter = SelectFilter | SelectFilter | SearchFilter;

// Select option
export interface SelectOption {
  value: string;
  title: string;
}

// Filter values that can be used in filters
interface BaseFilterValue {
  filterId: string;
}

export interface StringFilterValue extends BaseFilterValue {
  value: string;
}

export interface NumberFilterValue extends BaseFilterValue {
  value: number;
}

export interface StringArrayFilterValue extends BaseFilterValue {
  value: string[];
}

export type FilterValue =
  | StringFilterValue
  | NumberFilterValue
  | StringArrayFilterValue;
