import React from "react";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import SkapaTabs from "../InputPopup/SkapaTabs";
import { nowAsISOWithOffsetInDays } from "../../helpers/dates";
import dayjs from "dayjs";
import styles from "./insights.module.css";
import { FocusedElement, SearchInput } from "../InputPopup/SearchInput";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";
import styled from "styled-components";
import { Trans } from "@coworker/locales";

const InsightsSearchWrapper = styled.div`
  margin-top: 20px;
`;

function tabToUrlParams(
  tabKey: string,
  urlParams: {
    "ds1.store": any;
    "ds1.start_date": string;
    "ds1.end_date": string;
    "ds1.article_id": any;
  }
) {
  let today = dayjs(Date.now()).format("YYYY-MM-DD");
  let yesterday = dayjs(nowAsISOWithOffsetInDays(String(-1))).format(
    "YYYY-MM-DD"
  );
  let beforeSevenDays = dayjs(nowAsISOWithOffsetInDays(String(-8))).format(
    "YYYY-MM-DD"
  );
  let beforeFourWeeks = dayjs(nowAsISOWithOffsetInDays(String(-29))).format(
    "YYYY-MM-DD"
  );
  switch (tabKey) {
    case "today":
      urlParams["ds1.start_date"] = today;
      urlParams["ds1.end_date"] = "9999-01-01";
      break;
    case "yesterday":
      urlParams["ds1.start_date"] = yesterday;
      urlParams["ds1.end_date"] = today;
      break;
    case "last_7_days":
      urlParams["ds1.start_date"] = beforeSevenDays;
      urlParams["ds1.end_date"] = today;
      break;
    case "last_4_weeks":
      urlParams["ds1.start_date"] = beforeFourWeeks;
      urlParams["ds1.end_date"] = today;
      break;
    default:
      urlParams["ds1.start_date"] = yesterday;
      urlParams["ds1.end_date"] = today;
  }
  return urlParams;
}

const ReportFilter = () => {
  let store_id = useStoreId();
  if (process.env["REACT_APP_ENV"] !== "prod") {
    store_id = "445";
  }
  let start_date;
  start_date = "2021-01-01";
  let end_date;
  end_date = "2021-12-31";

  const [selectedTab, setSelectedTab] = React.useState("yesterday");
  const [inputValue, setInputValue] = React.useState<string>("");
  const [focusedElement, setFocusedElement] =
    React.useState<FocusedElement>("none");
  const tabChangedHandler = (tabKey: string) => {
    setSelectedTab(tabKey);
  };
  const getLastNumber = (value: string): string => {
    const numbers = value.match(/\d+/g); // Extract all numbers
    return numbers ? numbers[numbers.length - 1] || "" : ""; // Return the last number or an empty string
  };

  const urlParams = {
    "ds1.store": store_id,
    "ds1.start_date": start_date,
    "ds1.end_date": end_date,
    "ds1.article_id": getLastNumber(inputValue),
  };
  const tabs = [
    {
      title: (
        <>
          <Trans>todayString</Trans>{" "}
        </>
      ),
      key: "today",
    },
    {
      title: (
        <>
          <Trans>yesterdayString</Trans>{" "}
        </>
      ),
      key: "yesterday",
    },
    {
      title: (
        <>
          <Trans>last7DaysString</Trans>
        </>
      ),
      key: "last_7_days",
    },
    {
      title: (
        <>
          <Trans>last4WeeksString</Trans>
        </>
      ),
      key: "last_4_weeks",
    },
  ];
  const clearInput = () => {
    setInputValue("");
  };
  
  function loadLookerReport(tabKey: string) {
    tabToUrlParams(tabKey, urlParams);
    console.log(urlParams);
    const reportUrl =
      "https://lookerstudio.google.com/embed/reporting/650b87cd-ab95-4497-acaf-fbb0a0ac56f3/page/I6iYE?params=" +
      encodeURIComponent(JSON.stringify(tabToUrlParams(tabKey, urlParams)));
    console.log(reportUrl);

    return (
      <iframe
        className={styles["insights-iframe"]}
        scrolling="no"
        title="Insights"
        width="360"
        height={window.outerHeight}
        src={reportUrl}
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
    );
  }
  return (
    <>
      {getLastNumber(inputValue) !== "" ? (
        <InsightsSearchWrapper>
          <FixaSearch
            id="1"
            onChange={() => {}}
            placeholder={getLastNumber(inputValue)}
            onClear={clearInput}
            value={getLastNumber(inputValue)}
            ariaLabel="Insights Search article"
            data-testid="insights-search-article"
            onClick={() => {}}
            style={{ width: '80%' }}
          />
        </InsightsSearchWrapper>
      ) : (
        <SearchInput
          placeHolderString={"searchArticleString"}
          hideSPR={true}
          withHistory={false}
          inputValue={inputValue}
          setInputValue={setInputValue}
          focusedElement={focusedElement}
          updateFocus={(isFocused) =>
            setFocusedElement(isFocused ? "Input" : "none")
          }
          className={styles["insights-search-input"] || ""}
        />
      )}
      <SkapaTabs
        tabs={tabs}
        active={selectedTab}
        onTabChanged={tabChangedHandler}
        noTabPanelPadding
      />
      {loadLookerReport(selectedTab)}
    </>
  );
};

export default ReportFilter;
