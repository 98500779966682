import React from "react";
import {
  Filter,
  FilterType,
  FilterValue,
  InputType,
  SelectFilter,
  StringFilterValue,
} from "../types";
import FixaPill from "../../wrappers/FixaPill/FixaPill";
import CrossIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CrossIconPath";
import { useTranslation } from "@coworker/locales";
import AppliedFilterRowCss from "./AppliedFilterRow.module.css";
import FilterRowCss from "./FilterRow.module.css";
import { useChangedFiltersCount } from "../utils";

interface AppliedFilterRowProps {
  filters: Filter[];
  filterValues: FilterValue[];
  onChange: (filterValues: FilterValue[]) => void;
}

export const AppliedFilterRow = ({
  filters,
  filterValues,
  onChange,
}: AppliedFilterRowProps) => {
  const { t } = useTranslation();
  const changedFilterCount = useChangedFiltersCount(filters, filterValues);

  const appliedSelectPill = (
    filter: SelectFilter,
    filterValue: StringFilterValue | undefined
  ) => {
    if (filterValue && filterValue.value !== filter.defaultValue.value) {
      const option = filter.options.find(
        ({ value }) => value === filterValue.value
      );
      return (
        <FixaPill
          key={filter.id}
          iconPosition="trailing"
          ssrIcon={CrossIconPath}
          selected
          label={filter.title + ": " + (option?.title ?? filterValue.value)}
          onClick={() => {
            onChange([
              ...filterValues.filter(
                (filterValue) => filter.id !== filterValue.filterId
              ),
            ]);
          }}
          size="small"
        />
      );
    } else {
      return null;
    }
  };

  const appliedFilterCss = [AppliedFilterRowCss["applied-filter-container"]];
  if (changedFilterCount === 0) {
    appliedFilterCss.push(AppliedFilterRowCss["applied-filter-display-none"]);
  }
  return (
    <div className={appliedFilterCss.join(" ")}>
      <span className={AppliedFilterRowCss["applied-filter-text"]}>
        {t("appliedFiltersString")}
      </span>
      <div className={FilterRowCss["filters-container"]}>
        {filters
          .filter(({ filterType }) => filterType !== FilterType.Sort)
          .map((filter) => {
            const filterValue = filterValues.find(
              ({ filterId }) => filterId === filter.id
            );

            switch (filter.inputType) {
              case InputType.Select: {
                return appliedSelectPill(
                  filter,
                  filterValue as StringFilterValue
                );
              }
              default:
                return null;
            }
          })}
      </div>
    </div>
  );
};
