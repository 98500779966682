import React from "react";
import Layout from "./Layout";
import routes from "../routes";
import { InputPopup, InputPopupProvider } from "./InputPopup";
import ScanResult, { ScanPopoverProvider } from "./ScanResult";
import {
  ToastNotification,
  ToastNotificationProvider,
} from "@coworker/components";
import {
  CurtainNotification,
  CurtainNotificationProvider,
} from "./CurtainNotification";
import { ChangeTeamWrapper } from "./ChangeTeamWrapper";
import { TooltipProvider } from "./Tooltip";
import { WorkspacesProvider } from "../hooks/useWorkspaces";
import { useInputPopup } from "./InputPopup";
import { useSetInitialLanguage } from "./LanguageSetter";
import {
  setStartedAtVersion,
  setUserId,
  setUserStore,
  setCountry,
} from "../helpers/tracker";
import versionJson from "@coworker/app/src/core/version.json";
import { useConfigureSentry } from "../hooks/useConfigureSentry";
import { SelectedTasksContext } from "./SelectedTasksProvider";
import { useMultiSelect } from "../hooks/useMultiSelect";
import {
  useStoreId,
  useCountryId,
  useStartedAtVersion,
  useHashedUid,
  useFixaUID,
  useUserPermissions,
} from "../core/auth/useLoggedInUser";
import { FLAGS } from "../hooks/useFlag";
import { useUserByFixaUid } from "@coworker/apprestructured/src/shared/hooks/users/useUserByFixaUid";
import { useUpdateUserLastActiveAt } from "@coworker/apprestructured/src/shared/hooks/users/useUpdateUserLastActiveAt";

function BrowserBackInputPopupWatcher() {
  const { closePopup } = useInputPopup();
  React.useEffect(() => {
    function listen() {
      closePopup();
    }
    window.addEventListener("popstate", listen);
    return () => window.removeEventListener("popstate", listen);
  }, [closePopup]);
  return null;
}

function useSetAnalyticsUserProperties() {
  const fixaUid = useFixaUID();
  const country_id = useCountryId();
  const store_id = useStoreId();
  const hashed_uid = useHashedUid();
  const started_at_version = useStartedAtVersion();

  React.useEffect(() => {
    if (store_id) {
      setUserStore(store_id);
      const version = started_at_version || versionJson.version;
      setStartedAtVersion(version);
    }
    if (fixaUid) setUserId(fixaUid, hashed_uid);
    if (country_id) setCountry(country_id);
  }, [store_id, started_at_version, fixaUid, country_id, hashed_uid]);
}

function useMarkUserActive() {
  const fixaUid = useFixaUID();
  const { data: user, isLoading: isLoadingUser } = useUserByFixaUid(fixaUid);

  const updateUserLastActiveAtMutation = useUpdateUserLastActiveAt(fixaUid);

  React.useEffect(() => {
    let date = new Date();
    date.setDate(date.getDate() - 1); // Used to trigger so we update last_activity daily
    if (!isLoadingUser && user) {
      const lastActiveAt = new Date(user?.lastActiveAt);
      if (
        !!lastActiveAt &&
        lastActiveAt?.toISOString() < date.toISOString() &&
        !updateUserLastActiveAtMutation?.isLoading
      ) {
        updateUserLastActiveAtMutation.mutate();
      }
    }
  }, [isLoadingUser, user, updateUserLastActiveAtMutation]);
}

function Setters() {
  useConfigureSentry();
  useSetAnalyticsUserProperties();
  useSetInitialLanguage();
  useMarkUserActive();
  return null;
}

function debugHelpMessageHandler(event) {
  const data = event?.data;
  const hide =
    `${data?.source}`.includes("react-") ||
    `${data?.type || data}`.includes("webpack");
  if (!hide && data) console.log("postMessage recieved!", data);
}

if (window.location.host.indexOf("localhost") !== -1) {
  window.addEventListener("message", debugHelpMessageHandler, false);
}

export default function Main() {
  const multiSelectState = { my: useMultiSelect(), open: useMultiSelect() };

  const userPermissions = useUserPermissions();

  window.finalInsightsFromTasksServiceFlag = userPermissions?.includes(
    FLAGS.CALL_TASKS_SERVICE_INSIGHTS
  );

  window.sprTestbuy = userPermissions?.includes(FLAGS.SPR_TESTBUY);
  window.hideForGermanyUsers = userPermissions?.includes(FLAGS.GERMANY_ROLLOUT);
  window.hideExcelExport = userPermissions?.includes(FLAGS.HIDE_EXCEL_EXPORT);
  window.acquireTokenPopup = userPermissions?.includes(
    FLAGS.ACQUIRE_TOKEN_POPUP
  );
  window.enableDiscoverNow = userPermissions?.includes(
    FLAGS.ENABLE_DISCOVER_NOW
  );
  window.enableNewMediaFilters = userPermissions?.includes(
    FLAGS.ENABLE_NEW_MEDIA_FILTERS
  );

  return (
    <React.Fragment>
      <Setters />
      <InputPopupProvider>
        <InputPopupProvider>
          <WorkspacesProvider>
            <TooltipProvider>
              <ScanPopoverProvider>
                <CurtainNotificationProvider>
                  <ToastNotificationProvider>
                    <ChangeTeamWrapper>
                      <SelectedTasksContext.Provider value={multiSelectState}>
                        <Layout routes={routes} />
                        <InputPopup />
                      </SelectedTasksContext.Provider>
                      <ScanResult />
                      <CurtainNotification />
                      <ToastNotification />
                      <BrowserBackInputPopupWatcher />
                    </ChangeTeamWrapper>
                  </ToastNotificationProvider>
                </CurtainNotificationProvider>
              </ScanPopoverProvider>
            </TooltipProvider>
          </WorkspacesProvider>
        </InputPopupProvider>
        <InputPopup />
      </InputPopupProvider>
    </React.Fragment>
  );
}
