import React from "react";
import styled, { css } from "styled-components";
import { Trans } from "@coworker/locales";
import { PriceBadge } from "@coworker/reusable/components/PriceBadge";

const ProductInfo = styled.div`
  padding: 36px 28px 32px 28px;
  border-top: 1px solid var(--grey150);
  border-bottom: 1px solid var(--grey150);
`;
const DistancedRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  height: 100%;
`;
const Title = styled.h1`
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -0.5px;
  color: var(--grey900);
`;
const Subtitle = styled.h2`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--grey700);
`;

const NumberBadge = styled.div`
  display: inline-box;
  margin-top: 14px;
  padding: 4px 14px;
  border-radius: 3px;
  background-color: var(--grey100);

  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--grey900);

  max-width: 90px;
  box-sizing: border-box;
`;

const PriceBanner = styled.div<{ family?: boolean; newLower?: boolean }>`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  margin-bottom: 10px;
  ${({ family }) =>
    family &&
    css`
      color: var(--blue-family);
    `}
  ${({ newLower }) =>
    newLower &&
    css`
      color: var(--error);
    `}
`;

const InformationWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PriceBadgesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ProductShowcaseProps {
  name: string;
  price: number;
  currency: string;
  priceUnit: string | undefined;
  originalPrice: number | undefined;
  isFamilyPrice: boolean;
  isBreathTakingItem: boolean;
  newLowerPrice: boolean;
  description: string;
  formattedNumber: string;
}

export function ProductShowcase({
  name,
  price,
  currency,
  priceUnit,
  originalPrice,
  isFamilyPrice,
  isBreathTakingItem,
  newLowerPrice,
  description,
  formattedNumber,
}: ProductShowcaseProps) {
  return (
    <ProductInfo>
      {newLowerPrice && (
        <PriceBanner newLower>
          <Trans>lowPricString</Trans>
        </PriceBanner>
      )}
      {isFamilyPrice && (
        <PriceBanner family>
          <Trans>ikeaPriceString</Trans>
        </PriceBanner>
      )}
      <DistancedRow>
        <InformationWrapper>
          <div>
            <Title>{name}</Title>
            <Subtitle>{description}</Subtitle>
          </div>
          <NumberBadge>{formattedNumber}</NumberBadge>
        </InformationWrapper>
        <PriceBadgesWrapper>
          <PriceBadge
            price={price}
            currency={currency}
            priceUnit={priceUnit}
            isBreathTakingItem={isBreathTakingItem}
            originalPrice={originalPrice}
          />
        </PriceBadgesWrapper>
      </DistancedRow>
    </ProductInfo>
  );
}
