import taskTypeOptions from "@coworker/enums/taskType";
import { formatProductIdWithDots } from "@coworker/reusable";
import { issueTypeName } from "@coworker/app/src/constants/issueTypeName";
import { describeTheProblemList } from "../constants/describeTheProblemList";
import { getTranslationKeyFromReason } from "@coworker/app/src/components/RoomSettings/tools";
import { Item } from "../../shared/types/item";
import { getClosedTaskReason } from "./getClosedTaskReason";
import { FullTask } from "../../activity/types/FullTask";

export type BannerInformation = {
  closeType?: string;
  requestedQuantity?: string;
  location?: string;
  pickupLocation?: string;
  failedCriteria?: string;
} | null;

export const getTaskInfo = (
  task: FullTask,
  t: (key?: string) => string,
  productUnitTitle: string,
  itemInfo: Item | undefined
) => {
  let type: string = "",
    title: string = "",
    subtitles: string[] = [],
    bannerInformation: BannerInformation = {};

  switch (task.task_type) {
    case taskTypeOptions.ADDON:
      const isItemNameMissing =
        !task?.item_name || task?.item_name === "MISSING_PRODUCT_NAME";
      const itemName = isItemNameMissing ? itemInfo?.name : task.item_name;
      type = task.picked ? t("refillString") : t("addonTitleString");
      title = itemName ?? "";
      subtitles = [
        formatProductIdWithDots(task.product_article_id),
        itemInfo?.description ?? "",
      ];
      bannerInformation = {
        closeType:
          (task.close_type && t(getClosedTaskReason(task.close_type))) || "",
        requestedQuantity: `${task.actual_requested_quantity} ${productUnitTitle}`,
        pickupLocation: task.pickup_location_custom
          ? task.pickup_location_custom
          : task.pickup_location_grid_code,
        location: task.location_custom
          ? task.location_custom
          : task.location_grid_code,
      };
      break;

    case taskTypeOptions.PRODUCT_QUALITY:
      type = t("productQualityReportString");
      title = t(
        describeTheProblemList.find(
          (problem) => problem.type === task.pq_describe_the_problem_type
        )?.transKey
      );
      subtitles = [
        task.item_name,
        formatProductIdWithDots(task.product_article_id),
      ];
      bannerInformation = task.close_type
        ? {
            closeType: t(getClosedTaskReason(task.close_type)),
          }
        : null;
      break;

    case taskTypeOptions.PRODUCT_ISSUE:
      type = t("taskTypeTitleProductIssue");
      title = t(issueTypeName[task.issue_type]?.props.children);
      subtitles = [
        task.item_name,
        formatProductIdWithDots(task.product_article_id),
      ];
      bannerInformation = task.close_type
        ? {
            closeType: t(getClosedTaskReason(task.close_type)),
          }
        : null;

      break;

    case taskTypeOptions.MFAQ_FOLLOW_UP:
      type = t("mfaqFollowUpTitle");
      title = task.question_text;
      subtitles = [task.description ?? ""];
      bannerInformation = {
        closeType:
          (task.close_type && t(getClosedTaskReason(task.close_type))) || "",
        location: task.question_area?.includes("depts")
          ? `HFB ${task.question_area?.split("=")[1]}`
          : task.question_area?.includes("whole")
          ? `${t("wholeStoreString")}`
          : `${task.question_area?.split("=")[1]}`,
      };
      break;

    case taskTypeOptions.TESTBUY_FOLLOW_UP:
      type = t("testBuyFollowUpString");
      title = task.item_name;
      subtitles = [
        formatProductIdWithDots(task.product_article_id),
        itemInfo?.description ?? "",
      ];
      bannerInformation = {
        closeType:
          (task.close_type && t(getClosedTaskReason(task.close_type))) || "",
        location: task.location ?? "",
        failedCriteria: `• ${task?.failed_criteria?.length} ${t(
          "criteraFailedString"
        )}`,
      };
      break;

    case taskTypeOptions.ROOM_SETTINGS:
      type = t("roomSettingsString");
      title = t(getTranslationKeyFromReason(task.reason));
      subtitles = [
        task.item_name,
        formatProductIdWithDots(task.product_article_id),
      ];
      bannerInformation = {
        closeType:
          (task.close_type && t(getClosedTaskReason(task.close_type))) || "",
        location: task.media_name,
      };
      break;

    case taskTypeOptions.CUSTOM:
      const isScheduledTask = task.planned || task.repeatable_task_id;
      type = isScheduledTask ? t("scheduledTaskString") : t("customString");
      title = task.custom_title;
      subtitles = [task.description ?? ""];
      bannerInformation = task.close_type
        ? {
            closeType: t(getClosedTaskReason(task.close_type)),
          }
        : null;
      break;

    case taskTypeOptions.PLANNED:
      type = t("mainScheduledTaskString");
      title = task.custom_title;
      subtitles = [task.description ?? ""];
      bannerInformation = task.close_type
        ? {
            closeType: t(getClosedTaskReason(task.close_type)),
          }
        : null;
      break;
  }

  return { type, title, subtitles, bannerInformation };
};
