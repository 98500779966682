import { useMemo } from "react";
import { Media } from "../components/RoomSettings/types/media";

const mediaCompare = (a: Media, b: Media) => {
  if (a.active !== b.active) {
    if (a.active === "ACTIVE") {
      return -1;
    } else {
      return 1;
    }
  } else {
    return a.name.localeCompare(b.name);
  }
};

export const useSortedMedias = (medias: Media[] | undefined) => {
  const sortedMedias = useMemo(() => {
    if (!medias) {
      return undefined;
    } else if (!medias.length) {
      return [];
    }
    return medias.sort(mediaCompare);
  }, [medias]);

  return sortedMedias;
};
