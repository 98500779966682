import React, { useState, ChangeEvent } from "react";
import styled from "styled-components";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { FixaFormField } from "@coworker/apprestructured/src/shared/wrappers/FixaFormField/FixaFormField";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaTextArea } from "@coworker/apprestructured/src/shared/wrappers/FixaTextArea/FixaTextArea";
import { useItemsInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemsInfo";
import { Article, Articles } from "../RoomSettings/types/article";
import { Item } from "@coworker/apprestructured/src/shared/types/item";
import { addArticlesBulk } from "../RoomSettings/services/articles.service";
import { useTranslation } from "@coworker/locales";
import { useInputPopup } from "./index";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "../RoomSettings/hooks/queryKeys";

const PaddedHorizontalContainer = styled.div`
  padding: 0 16px;
`;
const ModalItemWrapper = styled.div`
  > * + * {
    margin-top: 20px;
  }
  padding: 20px 0;
`;

type ProductArticleIdAndQuantity = {
  productArticleId: string;
  quantity: number;
};

interface AddMultipleArticlesDesktopType {
  isOpen: boolean;
  mediaId: string;
  groupId: string;
  countryId: string;
}

const cleanUpArticleId = (articleId: string) => {
  return articleId.replace(/\./g, "").replace(/-/g, "").trim();
};

const extractProductArticleIdAndQuantity = (
  inputValue: string
): ProductArticleIdAndQuantity[] => {
  return inputValue
    .trim()
    .replace(/\t/g, " ")
    .replace(/ +/g, " ")
    .split(/\n/)
    .map((line) => {
      const [productArticleId, quantity] = line.split(/\s/);
      const cleanedId = cleanUpArticleId(productArticleId ?? "");
      return {
        productArticleId: cleanedId,
        quantity: quantity ? parseInt(quantity, 10) : 1,
      };
    })
    .filter((article) => article !== null);
};

const isValidInput = (inputValue: string) => {
  if (!inputValue) return true;

  const lines = inputValue.trim().split(/\n/);
  const productArticleIdRegex = /^[0-9.-]+$/;
  const quantityRegex = /^[0-9]+$/;

  for (const line of lines) {
    const [productArticleId, quantity] = line.split(/\s/);

    if (!productArticleIdRegex.test(productArticleId ?? "")) {
      return true;
    }

    const cleanedProductArticleId = cleanUpArticleId(productArticleId ?? "");
    if (cleanedProductArticleId.length !== 8) {
      return true;
    }

    if (quantity && !quantityRegex.test(quantity)) {
      return true;
    }
  }

  return false;
};

const transformArticlesInfos = (
  mediaId: string,
  articlesToAdd: ProductArticleIdAndQuantity[],
  articlesInfos: Item[]
): Articles => {
  const mergedArticles: Article[] = [];
  for (const articleToAdd of articlesToAdd) {
    const articleInfo = articlesInfos.find(
      (articleInfo) => articleInfo.no === articleToAdd.productArticleId
    );
    mergedArticles.push({
      active: "ACTIVE",
      hfb: articleInfo?.hfbNo || "Unknown",
      nbrArticles: articleToAdd.quantity,
      itemName: articleInfo?.name || "Unknown",
      itemType: articleInfo?.description ?? "Unknown",
      itemColor: articleInfo?.description?.split(",")?.[1] || "Unknown",
      imageSmall:
        articleInfo?.smallImageUrl || articleInfo?.imageUrl || "Unknown",
      productArticleType: "ART",
      productArticleId: articleToAdd.productArticleId,
      mediaId: mediaId || "",
    } as Article);
  }
  return mergedArticles;
};

export const AddMultipleArticlesDesktop = ({
  mediaId,
  groupId,
}: AddMultipleArticlesDesktopType) => {
  const { t } = useTranslation();
  const { closePopup } = useInputPopup();
  const [inputValue, setInputValue] = useState("");
  const [uploading, setUploading] = useState(false);
  const [formattedInputValues, setFormattedInputValues] = useState<
    ProductArticleIdAndQuantity[]
  >([]);

  const productArticleIds = formattedInputValues.map((o) => o.productArticleId);
  const { data: articlesInfo } = useItemsInfo(productArticleIds);
  const queryClient = useQueryClient();

  React.useEffect(() => {
    const addArticles = async () => {
      if (!articlesInfo) return;
      const allArticles = transformArticlesInfos(
        mediaId,
        formattedInputValues,
        articlesInfo
      );
      if (allArticles.length > 0) {
        try {
          await addArticlesBulk(allArticles, groupId);
          await Promise.all([
            queryClient.invalidateQueries([QueryKeys.ArticlesByMedia]),
            queryClient.invalidateQueries([QueryKeys.ArticlesByGroup]),
          ]);
        } catch (error) {
          alert(t("searchErrorString"));
        }
      }
      closePopup();
      setInputValue("");
      setUploading(false);
    };
    addArticles().catch(async () => {
      closePopup();
    });
  }, [
    articlesInfo,
    closePopup,
    formattedInputValues,
    groupId,
    queryClient,
    mediaId,
    t,
  ]);

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    setUploading(true);
    const idAndQuantity = extractProductArticleIdAndQuantity(inputValue);
    setFormattedInputValues(idAndQuantity);
  };

  return (
    <>
      <FixaModal visible={true} handleCloseBtn={() => closePopup()}>
        <FixaSheets
          labelledById="add-articles"
          header={
            <FixaModalHeader
              ariaCloseTxt={t("closeActionString")}
              title={t("addArticlesString")}
            />
          }
          footer={
            <FixaModalFooter>
              <FixaButton
                type="primary"
                onClick={() => handleSubmit()}
                disabled={uploading || !inputValue || isValidInput(inputValue)}
              >
                Add articles
              </FixaButton>
            </FixaModalFooter>
          }
        >
          <PaddedHorizontalContainer>
            <ModalItemWrapper>
              <FixaFormField
                shouldValidate={
                  inputValue.length > 0 && isValidInput(inputValue)
                }
                validation={{
                  msg: t("addArticleNumberString"),
                  id: "article-number",
                }}
              >
                <FixaTextArea
                  label={t("addArticleAndQuantityInformationString")}
                  value={inputValue}
                  rows={25}
                  onChange={handleInputChange}
                />
              </FixaFormField>
            </ModalItemWrapper>
          </PaddedHorizontalContainer>
        </FixaSheets>
      </FixaModal>
    </>
  );
};
