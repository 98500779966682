import React from "react";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import styled from "styled-components";
import { logEvent } from "../../../helpers/tracker";
import { Trans, useTranslation } from "@coworker/locales";
import { useNameFormatter } from "../../../hooks/useActiveUsers";

import { FullWidthButton } from "../../SkapaButton";
import { Container, OverflowBackground } from "../CommonComponents";
import { ChosenTeamBox } from "./ConfirmationBox";

const Separator = styled.div`
  background-color: var(--grey100);
  height: 16px;
`;

const Margin = styled.div`
  margin: 24px;
`;

interface ConfirmAssignmentProps {
  teamId: string;
  onClose: () => void;
  onSubmit: (reassign: boolean) => void;
}

/**
 * Resolves (calls onSubmit) with true ONLY when reassign button is pressed
 * All other cases just closes the dialog and returns false or undefined.
 */
export function ConfirmAssignment({
  onClose,
  onSubmit,
  teamId,
}: ConfirmAssignmentProps) {
  const { t } = useTranslation();
  const { teamNameFor } = useNameFormatter();
  const teamName = teamNameFor(teamId);

  const handleChosenTeamBoxClick = () => {
    logEvent("ce:confirm_assignment:blind_click");
    onSubmit(true);
  };

  return (
    <>
      <OverflowBackground
        data-testid="background"
        onClick={() => {
          logEvent("ce:confirm_assignment:close_overflow");
          onClose();
        }}
      />
      <Container data-testid="confirmScreen">
        <FixaModalHeader
          title={t("assignString")}
          closeBtnClick={() => {
            logEvent("ce:confirm_assignment:x");
            onClose();
          }}
        />

        <Margin>
          <ChosenTeamBox text={teamName} onClick={handleChosenTeamBoxClick} />
        </Margin>

        <FullWidthButton
          style={{ margin: "-24px 24px 16px 24px" }}
          type="secondary"
          text={<Trans>changeString</Trans>}
          onClick={() => {
            logEvent("ce:confirm_assignment:change");
            onSubmit(true); // return true only when we want to reassign
          }}
        />
        <Separator />
        <FullWidthButton
          text={<Trans>confirmString</Trans>}
          onClick={() => {
            logEvent("ce:confirm_assignment:confirm");
            onClose();
          }}
        />
      </Container>
    </>
  );
}
