import React, { useMemo } from "react";
import { useParams } from "react-router";
import { useMediasAndArticleCount } from "../hooks/useMediasAndArticleCount";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useArticleById } from "../hooks/useArticleById";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { ArticleListItemMobile, SimpleContainer } from "../styles/styles";
import { ArticleListItem } from "../Molecules/ArticleListItem";
import { MediaPlacement } from "../Molecules/MediaPlacement";
import { GreyStrip } from "../Atoms/GreyStrip";
import { Trans, useTranslation } from "@coworker/locales";
import {
  getPrimeTasks,
  getSortedMediasArticleCount,
} from "./ArticleView.helper";
import { CreateTasks } from "../Organisms/CreateTasks";
import { useTasksByArticle } from "../hooks/useTasksByArticle";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { ArticleStock } from "../Molecules/ArticleStock";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";

export function SearchedArticleView() {
  const { push } = useWorkspacesAction();
  const { t } = useTranslation();
  const { productArticleId } = useParams();
  const storeId = useStoreId();

  const { data: mediasArticleCount, isFetching: racFetching } =
    useMediasAndArticleCount(productArticleId, storeId);
  const { data: searchedArticle, isFetching: saFetching } = useArticleById(
    mediasArticleCount?.[0]?.articleId
  );
  const { tasks } = useTasksByArticle(storeId, productArticleId);

  const fetching = saFetching || racFetching;

  const linkbar = useMemo(() => {
    return (
      <Linkbar
        items={[
          {
            label: t("roomSettingsString"),
            to: "/mediamaintenance",
          },
        ]}
        currentLabel={searchedArticle?.itemName ?? ""}
        currentAsH1
      />
    );
  }, [searchedArticle?.itemName, t]);

  if (!fetching && !searchedArticle)
    return (
      <>
        {linkbar}
        <SimpleContainer
          $direction={"column"}
          onClick={() => {
            push("/mediamaintenance");
          }}
        >
          <Trans values={{ article: productArticleId }}>
            articleDoesNotExistInRoomSettingString
          </Trans>
          <FixaButton
            type={"primary"}
            text={t("goBackToRoomListingString")}
            onClick={() => {
              push("/mediamaintenance");
            }}
          />
        </SimpleContainer>
      </>
    );

  if (racFetching || saFetching) return <></>;

  const sortedMediasArticleCount = getSortedMediasArticleCount(
    mediasArticleCount ?? []
  );
  const primeTasks = getPrimeTasks(mediasArticleCount ?? [], tasks);

  return (
    <>
      {searchedArticle && (
        <FullScreenPopup noPadding linkbar={linkbar}>
          <FixaListView id="searched-article-list-view" size="small">
            <ArticleListItemMobile>
              <ArticleListItem
                article={{ ...searchedArticle, nbrArticles: 0 }}
                hideChevron={true}
              />
            </ArticleListItemMobile>

            <MediaPlacement mediasArticleCount={sortedMediasArticleCount} />

            <ArticleStock
              productArticleId={searchedArticle.productArticleId}
              productArticleType={searchedArticle.productArticleType}
            />

            <GreyStrip text={t("foundInAllRoomSettingsString")} />

            <CreateTasks
              mediasArticleCount={sortedMediasArticleCount || []}
              tasks={primeTasks}
            />
          </FixaListView>
        </FullScreenPopup>
      )}
    </>
  );
}
