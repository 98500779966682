import { useTranslation } from "@coworker/locales";
import { FilterType, InputType } from "../types";
import { useAllSelectOption } from "../utils";

const TASKS_ID = "tasks";

export function useTasksSelectFilter() {
  const { t } = useTranslation();
  const defaultValue = { filterId: TASKS_ID, value: "" };

  return useAllSelectOption({
    inputType: InputType.Select,
    filterType: FilterType.Filter,
    id: TASKS_ID,
    title: t("ongoingTasksString"),
    options: [
      { value: "ongoingTasks", title: t("mediaWithOngoingTasksString") },
      {
        value: "noOngoingTasks",
        title: t("mediaWithNoOngoingTasksString"),
      },
    ],
    enabled: true,
    defaultValue,
  });
}
